import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MobileService } from '@service/mobile.service'
import { DialogType } from '@type/dialog-type'

@Component({
  selector: 'app-nag',
  templateUrl: './app-nag-dialog.component.html',
  styleUrls: ['./app-nag-dialog.component.scss'],
})
export class AppNagDialogComponent {

  public static cssClass: DialogType = 'black-bottom-dialog'

  constructor(
    public dialogRef: MatDialogRef<AppNagDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public MobileService: MobileService
  ) { }

  public close(): void {
    this.dialogRef.close()
  }
}
