import { Injectable } from '@angular/core'
import * as Sentry from "@sentry/browser"
import { appConfig } from '@configuration/app-config'
import { environment } from '@environments/environment'

@Injectable({
  providedIn: 'root'
})
export class SentryService {

  public silentCaptureException(e) {

    if (!environment.localDev)
      Sentry.captureException(e)

    if (!environment.production)
      console.error("sentry silent error capture", e)
  }

  public silentCaptureMessage(message: string) {

    if (!environment.localDev)
      Sentry.captureMessage(message)

    if (!environment.production)
      console.error("sentry silent message capture", message)
  }
}