import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public onLoadingChange: Subject<boolean> = new Subject()

  public isLoading:boolean = false

  constructor() { }

  public startLoad(){
    this.isLoading = true
    this.onLoadingChange.next(true)
  }

  public stopLoad(){
    this.isLoading = false
    this.onLoadingChange.next(false)
  }
}
