export class Url {

  // https://www.youtube.com/watch?v=_mECn-vC7GU
  // protocol = "https:"
  // host = www.youtube.com
  // port = 443
  // path = "/watch"
  // getParams = {v:_mECn-vC7GU}

  /* PROTOCOL */
  public get protocol(): string {
    return this._protocol
  }
  public set protocol(s: string) {
    this._protocol = s
    this.buildCached()
  }
  private _protocol: string = "https:"

  /* HOST */
  public get host(): string {
    return this._host
  }
  public set host(h: string) {
    this._host = h
    this.buildCached()
  }
  private _host: string

  /* PORT */
  public get port(): Number {
    return this._port
  }
  public set port(p: Number) {
    this._port = p
    this.buildCached()
  }
  private _port: Number = 443

  /* PATH */
  public get path(): string {
    return this._path
  }
  public set path(p: string) {
    this._path = p
    this.buildCached()
  }
  private _path: string

  /* GET PARAMS */
  public get getParams() {
    return this._getParams
  }
  public set getParams(gp: {}) {
    this._getParams = gp
    this.buildCached()
  }
  private _getParams = {}

  /* CACHED */
  public get cached(): string {
    return this._cached
  }
  private _cached: string = ""

  /*
    this constructor extracts all important atomar information of a url 
  */
  constructor(s: string) {
    /*
      if you dev this -> test against (at least) these:
      https://www.youtube.com/watch?v=Gu3mXY4UfGk&t=82s
      https://www.youtube.com
      youtube.com/watch?v=Gu3mXY4UfGk&t=82s
      https://www.youtube.com:999/watch?v=Gu3mXY4UfGk&t=82s
      https://www.youtube.com:999
      https://www.youtube.com/watch?v=_mECn-vC7GU
      https://youtu.be/za6HOKZakNU
      https://vimeo.com/197762063
      https://dev-zimmerer.de:2503/storage/uploads/images/_cached/ZWEiejxIhzWH6zvvUODy5tjk7bdlm_200.jpeg
      https://dev-zimmerer.de:2503/storage/uploads/images/_cached/eoF3aa7UE6xdegOOBnZ25tjk7caw5_1920.jpeg
      https://dev-zimmerer.de:2503/storage/uploads/images/37da16ca3f6155dd1b84c191ab16b109.jpg
      https://dev-zimmerer.de:2503/storage/uploads/images/aafcbefc74756ffd650ce3707b7913f6.jpg
      https://dev-zimmerer.de:2503/storage/uploads/images/7c3bb8c09ae15f789d557b016f2d97fb.jpg
      https://dev-zimmerer.de:2503/storage/uploads/images/445f07eeb17331d67f8353dad552629d.jpg
      https://dev-zimmerer.de:2503/storage/uploads/images/2f5963e627f782e85e001082c1ae6e98.jpg
      https://dev-zimmerer.de:2503/storage/uploads/images/_cached/arh06ACvpFH5DgwS6SSb5tjk0efzh_800.jpeg
      https://dev-zimmerer.de:2503/storage/uploads/images/_cached/arh06ACvpFH5DgwS6SSb5tjk0efzh_1920.jpeg
    */

    const a = document.createElement('a')
    a.href = s

    this._protocol = a["protocol"]
    if (this.protocol == "http:")
      this._port = 80

    this._host = a["hostname"]

    if (typeof a["port"] != "undefined" && a["port"] && a["port"] != "")
      this._port = parseInt(a["port"])

    if (typeof a["pathname"] != "undefined" && a["pathname"] && a["pathname"] != "")
      this._path = a["pathname"]

    if (typeof a["search"] != "undefined" && a["search"] && a["search"] != "") {
      for (let p of a["search"].substr(1).split("&")) {
        let paramDetails = p.split("=")
        this.getParams[paramDetails[0]] = paramDetails[1]
      }
    }

    this.buildCached()
  }

  /*
    this method is called every time a part of this "dynamic" url object changes
    it assembles a use ready version of this url object - so not every user has to do that by hand every time one changes the object 
  */
  private buildCached() {
    let s = this.protocol + "//" + this.host

    if (this.port != 80 && this.port != 443)
      s += ":" + this.port

    if (typeof this.path != "undefined" && this.path != null)
      s += this.path

    let c = 0
    for (let key of Object.keys(this.getParams)) {

      if (c == 0)
        s += "?"
      else
        s += "&"

      s += key + "=" + this.getParams[key]

      c++
    }

    this._cached = s
  }

  public toString = (): string => {
    return this.cached
  }

}