import { Injectable } from '@angular/core';
import { BonusLevel } from '@model/bonus-level';
import { SerializationService } from 'aautil';
import { RestService } from '@service/rest/rest.service';
// import { BonusRule } from '@model/bonus-rule';
import { SentryService } from '@service/sentry.service';

@Injectable({
  providedIn: 'root'
})
export class BonusService {

  constructor(
    private serializationService: SerializationService,
    private RestService: RestService,
    private sentryService: SentryService,
  ) { }

  public getNext(): Promise<BonusLevel> {
    return new Promise<BonusLevel>((resolve, reject) => {

      this.RestService.post('bonus/getNextBonusLevel').then((response: Object) => {

        // probably already reached last level
        if (response == null) {
          reject()
          return
        }

        this.serializationService.deserialize(BonusLevel, response)
          .then((deserialized: BonusLevel) => {
            resolve(deserialized)
          })
          .catch(err => {
            this.sentryService.silentCaptureException(err)
            reject(err)
          })
      })
        .catch(err => {
          this.sentryService.silentCaptureException(err)
          reject(err)
        })

    })
  }

  public getCurrent(): Promise<BonusLevel> {
    return new Promise<BonusLevel>((resolve, reject) => {

      this.RestService.post('bonus/getBonusLevelOfUser').then((response: Object) => {

        if (!response) {
          resolve(null)
          return
        }

        this.serializationService.deserialize(BonusLevel, response)
          .then((deserialized: BonusLevel) => {
            resolve(deserialized)
          })
          .catch(err => {
            this.sentryService.silentCaptureException(err)
            reject(err)
          })
      })
        .catch(err => {
          this.sentryService.silentCaptureException(err)
          reject(err)
        })

    })
  }

  public getAll(): Promise<BonusLevel[]> {
    return new Promise((resolve, reject) => {

      this.RestService.post('bonus/getAllBonusLevels', {})
        .then((response: any[]) => this.serializationService.deserializeMany(BonusLevel, response))
        .then(deserialized => resolve(deserialized))
        .catch(err => {
          this.sentryService.silentCaptureException(err)
          reject(err)
        })

    })
  }

  public redeemBonus(message, bonus_level_id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.RestService.post('bonus/redeemBonus', { message, bonus_level_id })
        .then(response => resolve(response))
        .catch(e => reject(e))
    })
  }

  public getExternalUserLevel(id: number): Promise<BonusLevel> {
    return new Promise((resolve, reject) => {
      this.RestService.post('bonus/getBonusLevelOfUser', { id })
        .then((unserializedBonusLevel: Object) => {

          if (!unserializedBonusLevel)
            reject()

          this.serializationService.deserialize(BonusLevel, unserializedBonusLevel)
            .then(level => {
              resolve(level)
            })
            .catch(err => {
              this.sentryService.silentCaptureMessage(JSON.stringify(unserializedBonusLevel))
              console.log(unserializedBonusLevel)
              this.sentryService.silentCaptureException(err)
              reject(err)
            })

        })
        .catch(e => reject(e))
    })
  }

  // public getBonusHistory():Promise<BonusLevel[]>{
  //   return new Promise<BonusLevel[]>((resolve,reject)=>{

  //     this.restService.post('bonus/getBonusHistory').then((response:any[]) => {
  //       this.serializationService.deserializeMany(BonusLevel, response)
  //       .then((deserialized:BonusLevel[]) => resolve(deserialized))
  //       .catch(err=>{
  //         this.sentryService.silentCaptureException(err)
  //         reject(err)
  //       })
  //     })
  //     .catch(err=>{
  //       this.sentryService.silentCaptureException(err)
  //       reject(err)
  //     })
  //   })
  // }

  // public getRulesHistory():Promise<BonusRule[]>{
  //   return new Promise<BonusRule[]>((resolve,reject)=>{

  //     this.restService.post('bonus/getRulesHistory').then((response:any[]) => {
  //       this.serializationService.deserializeMany(BonusRule, response)
  //       .then((deserialized:BonusRule[]) => resolve(deserialized))
  //       .catch(err=>{
  //         this.sentryService.silentCaptureException(err)
  //         reject(err)
  //       })
  //     })
  //     .catch(err=>{
  //       this.sentryService.silentCaptureException(err)
  //       reject(err)
  //     })
  //   })
  // }

  // public getRulesTodo():Promise<BonusRule[]>{
  //   return new Promise<BonusRule[]>((resolve,reject)=>{

  //     this.restService.post('bonus/getRulesTodo').then((response:any) => {

  //       /*
  //       backend returns something like {
  //         900:[rules..],
  //         1000:[rules..]
  //       } etc.
  //       (grouped by type id)
  //       merge them to one array first
  //       */

  //       let result = []
  //       for(const key of Object.keys(response)){
  //         result = result.concat(response[key])
  //       }

  //       this.serializationService.deserializeMany(BonusRule, result)
  //       .then((deserialized:BonusRule[]) => {
  //         resolve(deserialized)
  //       })
  //       .catch(err=>{
  //         this.sentryService.silentCaptureException(err)
  //         reject(err)
  //       })
  //     })
  //     .catch(err=>{
  //       this.sentryService.silentCaptureException(err)
  //       reject(err)
  //     })
  //   })
  // }
}