import { Serialize, InFormGroup } from 'aautil'
import { Validators } from '@angular/forms'
import { standardvalidators } from '@configuration/standard-validators';
import { AtomarDisplayUnit } from '@model/atomar-display-unit';

export class Contactperson extends AtomarDisplayUnit {

  @InFormGroup()
  @Serialize()
  area: string

  @InFormGroup()
  @Serialize()
  firstname: string

  @InFormGroup()
  @Serialize()
  lastname: string

  @InFormGroup(standardvalidators.tel)
  @Serialize()
  tel: string

  @InFormGroup([standardvalidators.validEmail])
  @Serialize()
  email: string

  empty():boolean{

    for (var key in this) {
      if (this.hasOwnProperty.call(this, key)) {
        let val = this[key]
        if(val){
          return false
        }
      }
    }

    return true
  }

}