import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { ImageConfig } from '@model/image-config/image-config'
import store from 'store'
import { SentryService } from '@service/sentry.service'

import { environment } from '../../../environments/environment'

class Response<T> {
  public data: T
  public errors: any[] | false
}

@Injectable({
  providedIn: 'root',
})
export class RestService {

  private readonly apiUrl = environment.backendUrl + '/api/'

  constructor(
    private http: HttpClient,
    private sentryService: SentryService,
  ) { }

  public getApiUrl(): String {
    return this.apiUrl
  }

  /**
   * Issue a `GET` HTTP request.
   *
   * @param {string} url The resource to `GET`
   *
   * @returns {Promise<object>} Unserialized object which needs to go through our serializer
   */
  public get<T>(url: string): Promise<T> {
    const options = { headers: this.getDefaultHeaders() }

    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + url, options).subscribe(
        (response: Response<T>) => {
          if (response.errors) {
            reject(response.errors)
          } else {
            resolve(response.data)
          }
        },
        (err: HttpErrorResponse) => {
          this.sentryService.silentCaptureException(err)
          reject(err)
        },
      )
    })
  }

  /**
   * Issue a `PUT` (update) HTTP request.
   *
   * @param {string} url The resource to PUT
   * @param {object} data The data to send to the server
   * @param {ImageConfig} imageConfig Set to request imagepaths back
   *
   * @returns {Promise<object>} Unserialized object which needs to go through our serializer
   */
  public put<T>(url: string, data: object, imageConfig?: ImageConfig): Promise<T> {
    const options = { headers: this.getDefaultHeaders() }

    if (imageConfig) {
      data = this.appendImageConfigKeysToData(data, imageConfig)
    }

    return new Promise((resolve, reject) => {
      this.http.put(this.apiUrl + url, data, options).subscribe(
        (response: Response<T>) => {
          if (response.errors) {
            reject(response.errors)
          } else {
            resolve(response.data)
          }
        },
        err => {
          this.sentryService.silentCaptureException(err)
          reject(err)
        },
      )
    })
  }

  /**
   * Issue a `DELETE` HTTP request. (`POST` if data needs to be sent)
   *
   * @param url The resource to `DELETE`
   * @param data The `id` and other infos to `DELETE`
   *
   * @returns Unserialized object which needs to go through our serializer
   */
  public delete<T>(url: string, data?: object): Promise<T> {
    const options = { headers: this.getDefaultHeaders() }

    return new Promise((resolve, reject) => {
      if (data) {
        /**
         * We do **not** delete according to the url `DELETE api/heroes/42`.
         * Therefore we need to use `POST` so we can send our data.
         */
        this.http.post(this.apiUrl + url, data, options).subscribe(
          (response: Response<T>) => {
            if (response.errors) {
              reject(response.errors)
            } else {
              resolve(response.data)
            }
          },
          err => {
            this.sentryService.silentCaptureException(err)
            reject(err)
          },
        )
      }
      else {
        this.http.delete(this.apiUrl + url, options).subscribe(
          (response: Response<T>) => {
            if (response.errors) {
              reject(response.errors)
            } else {
              resolve(response.data)
            }
          },
          err => {
            this.sentryService.silentCaptureException(err)
            reject(err)
          },
        )
      }
    })
  }

  /**
   * Issue a `POST` HTTP request.
   *
   * @param url The resource to `POST`
   * @param data The serialized object which needs to be POSTed
   * @param imageConfig Set to request imagepaths back
   * @param upload Whether to upload images
   *
   * @returns Unserialized object which needs to go through our serializer
   */
  public post<T>(
    url: string,
    data: object = {},
    imageConfig?: ImageConfig,
    upload: boolean = false
  ): Promise<T> {

    const options = { headers: this.getDefaultHeaders() }

    if (upload) {
      options.headers = options.headers.delete('Content-Type')
    }

    if (imageConfig) {
      data = this.appendImageConfigKeysToData(data, imageConfig)
    }

    return new Promise((resolve, reject) => {

      this.http.post(this.apiUrl + url, data, options).subscribe({
        next: (response: Response<T>) => {
          if (response.errors) {
            reject(response)
          } else {
            resolve(response.data)
          }
        },
        error: (err: any) => {
          console.log(err)
          this.sentryService.silentCaptureException(err)
          reject(err)
        },
      })
    })
  }

  /**
   * Returns `Observable` which allows canceling requests
   */
  public postWithSubscription(endpoint: string, data: object): Observable<object> {
    const options = { headers: this.getDefaultHeaders() }
    return this.http.post(this.apiUrl + endpoint, data, options)
  }

  /**
   * Returns default headers including:
   * - `'Content-Type': 'application/json',`
   * - `accept: 'application/json',`
   * - `Authorization: Bearer: [token]` (if loggen in)
   */
  private getDefaultHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      accept: 'application/json',
    })

    const token_object = store.get('access_token')

    if (token_object && token_object.token && token_object.token != "") {
      headers = headers.set('Authorization', 'Bearer ' + token_object.token)
    }

    return headers
  }

  /**
   * Returns data with imageConfigKeys appended
   *
   * @param {ImageConfig} imageConfig
   */
  private appendImageConfigKeysToData(data: any, imageConfig: ImageConfig): any {
    const image_definitions: { purpose_key: string; width: string }[] = []
    for (const imageDef of imageConfig.items) {
      image_definitions.push({
        purpose_key: imageDef.purpose_key,
        width: '' + imageDef.width,
      })
    }

    const imageConfigKeys = {
      // always add resolution
      // resolution: this.resolutionService.getPixelRatio(),
      resolution: 1,
      image_definitions,
    }

    if (data instanceof FormData) {
      data.append('resolution', '' + imageConfigKeys.resolution)
      for (let index = 0; index < imageConfigKeys.image_definitions.length; index++) {
        data.append('image_definitions[' + index + '][width]', imageConfigKeys.image_definitions[index].width)
        data.append('image_definitions[' + index + '][purpose_key]', imageConfigKeys.image_definitions[index].purpose_key)
      }
    } else {
      data.resolution = imageConfigKeys.resolution
      data.image_definitions = imageConfigKeys.image_definitions
    }

    return data
  }
}
