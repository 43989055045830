import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from '@service/dialog.service';

@Component({
  selector: 'app-sso-login-buttons',
  templateUrl: './sso-login-buttons.component.html',
  styleUrls: ['./sso-login-buttons.component.scss']
})
export class SsoLoginButtonsComponent {

  constructor(
    public DialogService: DialogService
  ) { }

  public loginWithApple() {
    window.location.href = (window as any).serverUrl + '/api/auth/apple/redirect'
  }

  public loginWithGoogle(): void {
    window.location.href = (window as any).serverUrl + '/api/auth/google/redirect'
  }

  public loginWithFacebook(): void {

    /*
      it was decided to disable facebook sso because they disabled our app without real reason and are unresponsive
    */
    // window.location.href = (window as any).serverUrl + '/api/auth/facebook/redirect'

    /*
      instead we show a dialog explaining it to the user and allowing them to convert their accounts to regular email/password
    */
    this.DialogService.closeAllDialogs()
    this.DialogService.showFacebookDialog()
  }
}
