export const environment = {

  production: true,
  localDev: false,

  // used for websocket server -> client communication
  "socketio_server_url": 'https://zimmerer-treff.com',

  backendUrl: 'https://zimmerer-treff.com',
  publicFolder: '/build/', // is used for code compatibility with web project

  SentryDSN: "https://686c0bcb0f5c24a0266502fd1c760710@o4507805721165824.ingest.de.sentry.io/4507805724508240",
}