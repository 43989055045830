import { Serialize, InFormGroup } from 'aautil'
import { Validators } from '@angular/forms'

export class AdvertProperty {
  
  @InFormGroup([Validators.required])
  @Serialize()
  public key: string

  @InFormGroup([Validators.required])
  @Serialize()
  public value: string

  constructor(key: string, value: string) {
    this.key = key
    this.value = value
  }
}
