import { Component, Output, EventEmitter } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { messages } from '@configuration/messages'
import { MobileService } from '@service/mobile.service'
import { environment } from '@environments/environment'
import { UserService } from '@service/user/user.service'
import { DrawerService } from '@service/drawer.service'
import { NavigationItem } from '@model/navigation-item/navigation-item'
import { MenuStructureService } from '@service/menu-structure/menu-structure.service'
import { ScrollService } from '@service/scroll.service'
import { DialogType } from '@type/dialog-type'

@Component({
  selector: 'burger-dialog',
  templateUrl: './burger-dialog.component.html',
  styleUrls: ['./burger-dialog.component.scss'],
})
export class BurgerDialogComponent {

  public environment = environment

  public messages = messages

  public loading: boolean = false

  public profileMenuItems: NavigationItem[]
  public secondMenuItems: NavigationItem[]
  public socialMenuItems: NavigationItem[]

  public static cssClass: DialogType = "black-fullscreen-dialog"

  @Output() public status = new EventEmitter()

  constructor(
    public dialogRef: MatDialogRef<BurgerDialogComponent>,
    public MatDialog: MatDialog,
    public MobileService: MobileService,
    public UserService: UserService,
    public DrawerService: DrawerService,
    public MenuStructureService: MenuStructureService,
    public ScrollService: ScrollService,
  ) {

    this.profileMenuItems = this.MenuStructureService.getProfileMenuStructure(this.UserService)

    this.secondMenuItems = this.MenuStructureService.getMainMenuLong()

    this.socialMenuItems = this.MenuStructureService.getSocialMenuStructure()
  }

  public goto() {
    this.DrawerService.close()
      .then(() => {
        this.ScrollService.scrollToTop()
      })
  }

  public switch(): void {
    this.status.emit()
  }

  public close() {
    this.dialogRef.close()
  }
}
