import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { NotificationsDialogComponent } from "@modal/notifications-dialog/notifications-dialog.component";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class NotificationsDialogService {
  public drawerStateObservable: BehaviorSubject<boolean> = new BehaviorSubject(null)

  // boolean - false = closed / true = opened
  public drawerState: boolean = false

  private dialog: null | MatDialogRef<NotificationsDialogComponent, any> = null

  constructor(
    private MatDialog: MatDialog,
  ) { }

  public open() {
    this.drawerState = true
    this.drawerStateObservable.next(this.drawerState)

    this.dialog = this.MatDialog.open(NotificationsDialogComponent, {
      data: {},
      autoFocus: false,
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      panelClass: NotificationsDialogComponent.cssClass
    })
  }

  /*
    The Promise stuff is necessary if one wants to be sure to do stuff after dialog close.
    E.g. scroll to top (dialog prevents our current method to do that)
  */
  public close(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.drawerState = false
      this.drawerStateObservable.next(this.drawerState)

      if (this.dialog) {
        this.dialog.afterClosed().subscribe(result => {
          resolve()
        })
        this.dialog.close()
      }
      else
        resolve()
    })
  }
}
