import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { ConfirmDialogComponent } from '@modal/confirm-dialog/confirm-dialog.component';

interface startBeforeLeaveHookParams {

  /* Text that is shown in the dialog */
  text?: string,

  /* action to execute before the route change i.e. save data */
  doBeforeRouteChange?: () => void,

  /* whether the route can be changed right now i.e. data can't be saved because of user input errors etc */
  isRouteChangeable?: () => boolean

  /* what to do when the route cant be changed i.e. inform the user that he made mistakes */
  handleRouteChangeableConditionNotMet?: () => void

  /*
    buttons that the dialog will show

    value = null (i.e. "OK")
      -> reminder only -> navigate

    value = true (i.e. "save edited data before navigate")
      -> doBeforeRouteChange if isRouteChangeable is given and met then navigate,
      if isRouteChangeable is not met -> execute handleRouteChangeableConditionNotMet and don't navigate

    value = false (i.e. "throw edited data")
      -> navigate 
  */
  buttons?: Array<{ text: string, value: boolean }>
}

@Injectable({
  providedIn: 'root'
})
export class TransitionHookService {

  /*
    This service offers an easy API to remind or ask the user something before a route change.
    I.E. user is editing a blog post and clicks on marketplace -> ask him if he really wants to leave.

    It is especially delicate because it also allows for some custom action to happen before the route change.
    I.E. asking user if he wants to save or throw edited data.
  */

  public hook = false

  public state: startBeforeLeaveHookParams

  constructor(
    private dialog: MatDialog,
    private Router: Router,
  ) {
    this.Router.events.forEach((event) => {
      if (this.hook) {
        if (event instanceof NavigationStart) {

          if (!this.state) {
            console.error("TransitionHookService hook was set but no config present.")
            return
          }

          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
              text: this.state.text,
              buttons: this.state.buttons
            },
            disableClose: false,
            panelClass: ConfirmDialogComponent.cssClass
          })

          dialogRef.afterClosed().subscribe(result => {

            // if its just a reminder a button like "OK" returns null
            if (result == null) {
              this.endBeforeLeaveHook()
              this.Router.navigateByUrl(event.url)
              return
            }

            // user decided i.e. save data before navigate
            if (result == true) {
              if (this.state.isRouteChangeable()) {
                this.state.doBeforeRouteChange()
                this.endBeforeLeaveHook()
                this.Router.navigateByUrl(event.url)
              }
              else {
                this.state.handleRouteChangeableConditionNotMet()
              }
            }

            // user decided i.e. to throw data
            else {
              this.endBeforeLeaveHook()
              this.Router.navigateByUrl(event.url)
            }
          })

          // HACK - cancel route change
          /*
            This is maybe error prone.
            This can and probably (currently 8.12.22) should be done with route guards.
            But one would have to add the guard to every route - pretty annoying.
            Also I'm still not sure how that would work with the asynchronicity of the users choice from the dialog.
          */
          this.hook = false
          this.Router.navigateByUrl(window.location.pathname)
        }
      }
    })
  }

  /*
    the named params look a little bit more complex than they are
    but they offer a nice api
    give nothing
    give something
    everything has standard values
    no param order needed
    no {} and null 0 stuff in 90% of calls
  */
  public startBeforeLeaveHook({

    text = "Der aktuelle Bearbeitungsstand des Entwurfs wird gespeichert. Beim nächsten Erstellen kannst Du diesen wieder verwenden.",

    doBeforeRouteChange = () => { },

    isRouteChangeable = () => { return true },

    handleRouteChangeableConditionNotMet = () => { console.error("handleRouteChangeableConditionNotMet was not defined") },

    buttons = [
      {
        text: 'OK',
        value: false,
      }
    ]
  }: startBeforeLeaveHookParams = {}) {
    this.hook = true
    this.state = {
      text,
      doBeforeRouteChange,
      isRouteChangeable,
      handleRouteChangeableConditionNotMet,
      buttons
    }
  }

  public endBeforeLeaveHook() {
    this.hook = false
  }
}