import { Component, Input } from '@angular/core'
import { Profile } from '@interface/profile'
import { ProfileTypeIdentifier } from '@enum/profile-type-identifier'

@Component({
  selector: 'user-image',
  templateUrl: './user-image.component.html',
  styleUrls: ['./user-image.component.scss'],
})
export class UserImageComponent {

  public is_cp:boolean = false

  @Input()
  public set profile(profile:Profile){

    if(!profile)
      return

    this._profile = profile
    this.is_cp = profile.profileType===ProfileTypeIdentifier.business
  }
  public get profile(){
    return this._profile
  }
  private _profile:Profile = null

}
