import { ImageConfigItem } from '@model/image-config-item/image-config-item'

export class ImageConfig {
  public items: ImageConfigItem[]

  constructor(...items: ImageConfigItem[]) {
    this.items = items
  }

  /**
   * Merge multiple ImageConfig instances into a single one. If multiple
   * configs contain deefinitions for the same key, the one with the bigger
   * width is chosen to satisfy all constraints.
   */
  static merge(configs: ImageConfig[]): ImageConfig {
      const keys: { [key: string]: number } = {};

      for (const config of configs) {
          for (const item of config.items) {
              const key = item.purpose_key;

              if (key in keys) {
                  const current = keys[key];
                  keys[key] = Math.max(current, item.width);
              } else {
                  keys[key] = item.width;
              }
          }
      }

      const items = Object.entries(keys).map(entry => {
          return new ImageConfigItem(entry[0], entry[1]);
      });

      return new ImageConfig(...items);
  }
}
