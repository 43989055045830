import { Component, EventEmitter, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DialogType } from '@type/dialog-type'

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {

  public text: string
  public buttons: any[]
  public decision = new EventEmitter()

  public static cssClass: DialogType = 'black-centered-dialog'

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {

    this.text = this.data.text
    this.buttons = this.data.buttons
  }

  public close(value: string): void {
    this.decision.next(value)
    this.dialogRef.close(value)
  }
}
