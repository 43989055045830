import { Serialize, InFormGroup, SerializationService } from 'aautil'
import { Validators, AbstractControl, AbstractControlOptions, FormGroup } from '@angular/forms'
import { User } from '@model/user/user'
import { Bookmark } from '@model/bookmark'
import { ComputedImage } from '@model/computed-image'
import { Entity } from '@model/entities/entity'
import { Category } from '@model/category/category'
import { OgraphEntity } from '@interface/ograph-entity';
import { OgraphData } from '@model/ograph-data';
import { Likeable } from '@interface/likeable';
import { HelperService } from '@service/helper.service'
import { EntityTypeIdentifier } from '@type/entity-type-identifier';
import { LikeableTypeIdentifier } from '@type/likeable-type-identifier';
import { standardvalidators } from '@configuration/standard-validators'
import { NgxGalleryImage } from 'ngx-gallery-9'
import { ProfileTypeIdentifier } from '@enum/profile-type-identifier'
import { UrlPrettifierService } from '@service/urlPrettifier/url-prettifier.service';
import { environment } from '@environments/environment'

// handles image and fallback image
function mainImageRequired() {
  return (group: FormGroup): { [key: string]: any } => {

    // if its a video -> no image necessary
    const category = group.controls.category
    if (group.controls.category.value == null || category.value.name.toLowerCase() == 'videos')
      return {}

    const mainImage = group.controls.mainImage
    const fallbackImage = group.controls.fallbackImage

    if (
      (typeof mainImage.value != 'undefined' && mainImage.value != null) ||
      (typeof fallbackImage.value != 'undefined' && fallbackImage.value != null)
    ) {
      // no error
      return {}
    }

    // invalid
    return { mainImageRequired: true }
  }
}

function videoUrlRequired() {
  return (group: FormGroup): { [key: string]: any } => {

    // if its not a video -> not necessary
    const category = group.controls.category
    if (group.controls.category.value == null || category.value.name.toLowerCase() != 'videos')
      return {}

    const videourl = group.controls.videourl.value

    if (videourl == null || videourl == '')
      return { videourlRequired: true }

    return {}
  }
}

export class Blog extends Entity implements OgraphEntity, Likeable {

  public likeable = true
  public likeableTypeIdentifier: LikeableTypeIdentifier = "blog"

  public entityTypeIdentifier: EntityTypeIdentifier = "blog"

  @InFormGroup()
  @Serialize()
  public id: number

  @Serialize()
  public userId?: number = -1

  @Serialize({
    from: {
      map: (frontend_user, svc: SerializationService) => svc.deserialize(User, frontend_user),
      deps: [SerializationService],
    },
    to: false
  })
  public frontend_user: User

  @Serialize({ to: false, from: 'frontend_user_id' })
  public frontend_user_id: number = -1

  @InFormGroup([Validators.required])
  @Serialize()
  public profile_type: ProfileTypeIdentifier = ProfileTypeIdentifier.private

  @InFormGroup([Validators.required])
  @Serialize({
    from: 'blog_category',
    to: 'blog_category',
  })
  public category: Category

  @InFormGroup([Validators.required, Validators.minLength(3), Validators.maxLength(254)])
  @Serialize()
  public title: string = ''

  @InFormGroup([Validators.required, Validators.minLength(3), Validators.maxLength(25000)])
  @Serialize()
  public teaser: string = ''

  @InFormGroup([Validators.required, Validators.minLength(3), Validators.maxLength(25000)])
  @Serialize()
  public content: string = ''

  @InFormGroup([
    standardvalidators.validURL,
    (control: AbstractControl) => {

      if (typeof control.value == "undefined" || control.value == null || control.value == "")
        return {}

      if (
        !control.value.startsWith('https://m.youtube.com/watch?v=') &&
        !control.value.startsWith('https://www.youtube.com/watch?v=') &&
        !control.value.startsWith('https://www.youtube.com/embed/') &&
        !control.value.startsWith('https://youtu.be/') &&
        !control.value.startsWith('https://vimeo.com/') &&
        !control.value.startsWith('https://player.vimeo.com/video/')
      ) return { domainNotAllowed: true }

      return {}
    }
  ])
  @Serialize({
    to: {
      map: (url: string, hs: HelperService) => {
        return hs.transformVideoUrl(url)
      },
      deps: [HelperService],
    },
    from: {
      map: (url: string, hs: HelperService, self: Blog) => {
        let newUrl = hs.transformVideoUrl(url)
        self.videourlSafe = hs.trustUrl(newUrl)
        return newUrl
      },
      deps: [HelperService],
    }
  })
  public videourl: string
  public videourlSafe = null

  @InFormGroup()
  @Serialize({
    from: {
      key: 'fallback_image',
      map: (cached: string, svc: SerializationService,) => {

        if (!cached)
          return undefined

        return svc.deserialize(ComputedImage, { cached, isFallback: true })
      },
      deps: [SerializationService],
    },
    to: {
      key: 'fallback_image',
      map: (fallbackImage: ComputedImage) => {
        if (fallbackImage && fallbackImage.cached)
          return fallbackImage.cached

        return undefined
      },
    }
  })
  public fallbackImage: ComputedImage = null

  @Serialize({
    from: {
      key: 'cached_images',
      map: (raw: any, svc: SerializationService, self: Blog) => {

        // deserialize images
        return svc.deserializeMany(ComputedImage, raw)
          .then((images: ComputedImage[]) => {

            /*
              loop fusion
              + find main image
              + sort into ngxgallery
            */
            for (let c of images) {

              const imageDef = {
                small: c.fullUrl.cached,
                medium: c.fullUrl.cached,
                big: c.fullUrl.cached,
                description: self.title,
              }

              // find main image
              if (c.purpose_key == 'MAIN') {
                self._mainImage = c
              }

              // sort into ngx gallery
              if (c.purpose_key == "SLIDE") {
                if (c.image.purpose_key == "MAIN,SLIDE")
                  self.galleryImages.unshift(imageDef)
                else if (c.image.purpose_key == "SLIDE")
                  self.galleryImages.push(imageDef)
              }
            }

            // if no user uploaded main image -> fill gallery with fallback image
            if (self.mainImage && self.mainImage.isFallback) {
              self.galleryImages.unshift({
                small: self.mainImage.fullUrl.cached,
                medium: self.mainImage.fullUrl.cached,
                big: self.mainImage.fullUrl.cached,
                description: '',
              })
            }

            return images
          })
      },
      deps: [SerializationService],
    },
    to: 'cached_images'
  })
  public images: ComputedImage[] = []

  @InFormGroup()
  public get mainImage() {
    if (this._mainImage)
      return this._mainImage
    if (this.fallbackImage)
      return this.fallbackImage
    return null
  }
  public _mainImage: ComputedImage = null

  public galleryImages: NgxGalleryImage[] = []

  @Serialize()
  public count_comments: number

  @Serialize()
  public bookmarks: Bookmark[]

  @Serialize()
  public is_edit: boolean

  // currently mostly used for validators that concern more than one formcontrol
  public formOptions: AbstractControlOptions = {
    validators: [mainImageRequired(), videoUrlRequired()],
  }

  public getOpgraphData() {

    let data = new OgraphData()

    data.url = environment.backendUrl + "/blog/detail/" + this.id
    if (this.title)
      data.url += "/" + UrlPrettifierService.prettyString(this.title)

    return data
  }
}
