import { Component, OnInit } from '@angular/core';
import { StateService } from '@service/state.service';
import { UserService } from '@service/user/user.service';
;

@Component({
  selector: 'app-dsgvo-banner',
  templateUrl: './dsgvo-banner.component.html',
  styleUrls: ['./dsgvo-banner.component.scss']
})
export class DsgvoBannerComponent implements OnInit {

  public consent: boolean = false

  constructor(
    public userService: UserService,
    public StateService: StateService,
  ) { }

  ngOnInit() {
    this.consent = this.userService.getCookieConsent()
  }

  public setCookieConsent() {
    this.consent = true
    this.userService.setCookieConsent()
  }

  public gotoPrivacy() {
    this.StateService.goto('/peripherals/privacy')
  }

}
