<div fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="10px"
  fxLayout.gt-md="row">

  <button (click)="loginWithGoogle()"
    class="mobileGrowBtn"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="10px">
    <i class="fab fa-google"></i>
    <div class="text">Login mit Google</div>
  </button>

  <button (click)="loginWithApple()"
    class="mobileGrowBtn"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="10px">
    <i class="fab fa-apple"></i>
    <div class="text">Sign in with Apple</div>
  </button>

</div>