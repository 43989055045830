import { Serialize, InFormGroup } from 'aautil'
import { Validators } from '@angular/forms'
import { User } from '@model/user/user'
import { Bookmark } from '@model/bookmark'
import { Like } from '@model/like'
import { Contentpart } from '@model/contentpart'
import { SerializationService } from 'aautil'
import { Entity } from '@model/entities/entity'
import { Category } from '@model/category/category'
import { UrlPrettifierService } from '@service/urlPrettifier/url-prettifier.service'
import { OgraphData } from '@model/ograph-data'
import { OgraphEntity } from '@interface/ograph-entity'
import { EntityTypeIdentifier } from '@type/entity-type-identifier';
import { ComputedImage } from '@model/computed-image';
import { LikeableTypeIdentifier } from '@type/likeable-type-identifier';
import { ProfileTypeIdentifier } from '@enum/profile-type-identifier'
import { environment } from '@environments/environment'

export class Wiki extends Entity implements OgraphEntity {

  public likeable: boolean = false
  public likeableTypeIdentifier: LikeableTypeIdentifier = "wiki"

  public entityTypeIdentifier: EntityTypeIdentifier = "wiki"

  @InFormGroup()
  @Serialize()
  public id: number

  @Serialize()
  public userId?: number = -1

  @Serialize({
    from: {
      map: (frontend_user, svc: SerializationService) => svc.deserialize(User, frontend_user),
      deps: [SerializationService],
    },
    to: false
  })
  public frontend_user: User

  @InFormGroup([Validators.required])
  @Serialize()
  public profile_type = ProfileTypeIdentifier.private

  @InFormGroup([])
  @Serialize({
    from: 'wiki_category',
    to: 'wiki_category'
  })
  public category: Category

  @InFormGroup([Validators.required], true)
  @Serialize({
    from: {
      map: (raw: any, svc: SerializationService) => svc.deserializeMany(Contentpart, raw),
      deps: [SerializationService],
    },
  })
  public wiki_contentparts: Contentpart[] = []

  public get mainImage(): ComputedImage {
    for (let cp of this.wiki_contentparts) {
      if (cp.cached_images && cp.cached_images.length > 0)
        return cp.cached_images[0]
    }
    return null
  }

  @Serialize()
  public count_comments: number

  @Serialize()
  public count_likes: number

  @Serialize()
  public bookmarks: Bookmark[]

  @Serialize()
  public likes: Like[]

  @Serialize({ to: false })
  public updated_at: string = ''

  @InFormGroup()
  @Serialize({
    from: {
      map: (tags: string) => {
        if (tags == null) return []
        return tags.split(" ")
      }
    },
    to: {
      map: (tags: string[]) => {
        return tags.join(" ")
      }
    }
  })
  public tags: string[] = []

  @InFormGroup([Validators.required])
  @Serialize()
  public sources: string

  @InFormGroup()
  @Serialize()
  public parent_model: number

  public get title(): string {
    return this.wiki_contentparts[0].title
  }

  public get content(): string {
    return this.wiki_contentparts[0].content
  }

  public getOpgraphData() {

    let data = new OgraphData()
    data.url = environment.backendUrl + "/wiki/detail/" + this.id + UrlPrettifierService.prettyString(this.wiki_contentparts[0].title)

    return data
  }

}
