<mat-dialog-content>

  <div *ngIf="!sent"
    fxLayout="column"
    fxLayoutAlign="center center">

    <p>
      Wir senden dir eine Email mit der du dein Passwort zurücksetzen kannst.<br>
      Mit welcher Email hast du dich registriert?
    </p>

    <form [formGroup]="form"
      fxLayout="column"
      fxLayoutAlign="center center">

      <!-- email -->
      <mat-form-field>
        <input type="text"
          placeholder="E-mail Adresse"
          formControlName="email"
          class="form-control"
          matInput />
      </mat-form-field>

      <mat-error *ngIf="form.controls.email.touched && form.controls.email.errors"
        class="invalid-feedback">
        <div *ngIf="form.controls.email.errors">{{messages.emailInvalid}}</div>
      </mat-error>

    </form>

    <p>
      <button class="orange-button"
        (click)="send()">senden</button>
    </p>

  </div>

  <div *ngIf="sent">

    <h2>Wenn ein Account mit dieser E-Mail existiert haben wir Dir eine E-Mail gesandt.</h2>

    <div style="padding-bottom: 20px;">
      Damit kannst Du dein Passwort zurücksetzen.<br>
      Wenn Du in ein paar Minuten noch keine E-Mail von uns erhalten hast, sieh bitte auch in Deinem Spam-Ordner nach.
    </div>

    <div fxLayout="row"
      fxLayoutAlign="end center">
      <button (click)="closeDialog()"
        class="orange-button"
        style="min-width: 80px;">OK</button>
    </div>

  </div>
</mat-dialog-content>