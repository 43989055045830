import { Validators, AbstractControl } from '@angular/forms'
import moment from 'moment'

export function validBirthday(control: AbstractControl) {

  if(typeof control.value=="undefined" || control.value==null || control.value=="")
    return {}

  let now = moment()

  let birthday = moment(control.value,'DD.MM.YYYY')
  if(!birthday.isValid())
    return {invalid_date:true}

  // let minyear = moment()
  // minyear = minyear.subtract(12, "years")
  // if(birthday.diff(minyear, 'years')<12)
  //   return { not_twelve: { value: 'must be 12' } }

  if(birthday.isAfter(now))
    return {not_in_future:true}

  let minyear = moment()
  minyear = minyear.subtract(120, "years")
  if(birthday.isBefore(minyear))
    return {too_old:true}

  return {}
}

export const standardvalidators = {
  
  tel: [
    Validators.minLength(3),
    Validators.maxLength(30),
    Validators.pattern(/^\+?([0-9]+(\ ?\/?\ ?)?[0-9]+)+[ ]*$/)
  ],

  birthday:[
    Validators.pattern(/^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4}$/),
    validBirthday,
  ],

  validURL: Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/),

  validEmail: Validators.pattern(/^[ ]*[\w+_\-\&!?|.~#]+@[\w+_\-\&!?|.~#]+.[a-zA-Z]{2,}[ ]*$/),

  get affiliateLink() {
    return [this.validURL].concat([
      Validators.minLength(1)
    ])
  }
}