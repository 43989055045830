import { EntityService } from '@interface/entity-service';

export class EntityTypeConfig {

  // internal identifier of a entity (set in model used in service etc)
  public identifier: string = null

  /*
  *   this is the backends internal model name
  *   i.e "Blog", "Advert", "Job", "Wiki"
  */
  public backendModelName: string = null

  /*
  *   this is how the backend api calls this entity
  *   e.g. https://dev-zimmerer.de:2503/api/job/getById -> "job"
  *   needed because backend api is blog/*, job/*, goods/*, wiki/*
  */
  public apiModelName: string = null

  /*
  *   this is the state in which this entity is listed
  *   i.e. https://dev-zimmerer.de:2503/blog
  *   -||- for the other ones
  */
  public liststate: string = null
  public detailstate: string = null
  public editstate: string = null
  public createstate: string = null

  public uploadimageendpoint: string = null

  public service: EntityService = null

  /*
  *   this is our (frontend) internal model class like Blog, Wiki, Job, Advert
  */
  public modelclass = null

  /*
  *   this is the backend internal model class namespace like App\Models\Blog, App\Models\Wiki, App\Models\Job, App\Models\Advert
  */
  public backendClassNamespace = null

  constructor(
    data: {
      identifier: string,
      backendModelName: string,
      apiModelName: string,
      liststate: string,
      detailstate: string,
      editstate: string,
      createstate: string,
      uploadimageendpoint: string,
      service: EntityService,
      modelclass,
      backendClassNamespace
    }
  ) {
    for (let k of Object.keys(data)) {
      this[k] = data[k]
    }
  }

}