<div class="dialog"
  fxLayout="column"
  fxLayoutGap="15px">

  <div class="text"
    [innerHTML]="text"></div>

  <div fxLayout="column"
    fxLayoutAlign="end center"
    fxLayoutGap="15px"
    fxLayout.gt-sm="row">
    <button class="mobileGrowBtn"
      [class.white-button]="odd"
      [class.orange-button]="even"
      *ngFor="let button of buttons; let even = even; let odd = odd"
      (click)="close(button.value)">
      {{ button.text }}
    </button>
  </div>

</div>