/*
  THIS IS A STATIC CLASS NOT A SERVICE because its easier to inject in the serialization of aautil
  just a time saver - not that it wouldnt work as a service  
*/

export class Money {
  
  private static currencySign:string = '€'
  private static decimalSign:string = ','
  private static thousandSign:string = '.'
  private static currencyBase:number = 100

  public static centNumberToEuroString(value:number,withThousandSigns:boolean=false):string{

    let integer = Math.trunc(value/this.currencyBase)
    let fraction = value-(integer*this.currencyBase)

    let integer_s = integer+""
    let fraction_s = fraction+""

    if(withThousandSigns && integer_s.length>4)
      integer_s = this.insertThousandSigns(integer_s)

    return integer_s+this.decimalSign+this.zerofill(fraction_s,2)//+" "+this.currencySign
  }

  private static zerofill(value:string, places:number):string{
    while(value.length<places)
      value+="0"
    return value
  }

  public static insertThousandSigns(value:string):string|null{
    
    if(value==null)
      return null

    // if the number already has fractions like 2500,00
    // cut them 2500,00 -> 2500
    let commaSplit = value.split(this.decimalSign)
    let naturalNumbers = commaSplit[0]

    let s = ""
    let c = 1
    for(let i=naturalNumbers.length;i>0;i--){
      s = naturalNumbers[i-1] + s
      if(c==3){
        if(i>1)
          s = this.thousandSign + s
        c=0
      }
      c++
    }

    // add fractions numbers at the end 
    if(commaSplit.length>1 && commaSplit[1]!="00")
      s+=","+commaSplit[1]

    return s
  }

}