import { Injectable } from '@angular/core'
import Echo from 'laravel-echo'
import * as socket from 'socket.io-client'
import { UserService } from '@service/user/user.service'
import { BehaviorSubject } from 'rxjs'
import { RestService } from '@service/rest/rest.service'
import { UserNotification } from '@model/user-notification/user-notification'
import { SerializationService } from 'aautil'
import { User } from '@model/user/user'
import { LoadingService } from '@service/loading.service';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {

  public broadcastObservable: BehaviorSubject<UserNotification | null> = new BehaviorSubject<UserNotification | null>(null)

  private echo: Echo | null = null

  constructor(
    private userService: UserService,
    private restService: RestService,
    private serializationService: SerializationService,
    private loadingService: LoadingService,
  ) {
    this.userService.userObservable.subscribe(user => {
      if (user) {
        if (!this.echo) {
          this.openWebsockets(user)
        }
      } else {
        this.closeWebsockets()
      }
    })
  }

  private openWebsockets(user: User): void {

    let rawdata = fetch(environment.backendUrl + "/env_config.json").then(
      res => res.json()
    ).then(res => {
      const serverUrl = environment.socketio_server_url + ':' + res.proxy_port

      /**
       * For some reason the socket.io event names that laravel emits are
       * prefixed with the redis key prefix...
       *
       * Relevant links:
       *
       *  1. https://github.com/laravel/framework/issues/28210
       *  2. https://stackoverflow.com/questions/57360229/cant-remove-laravel-database-prefix-from-channel
       *  3. https://github.com/laravel/echo/issues/229
       *
       */
      const eventPrefix = 'laravel_database_';

      this.echo = new Echo({
        client: socket,
        broadcaster: 'socket.io',
        host: serverUrl,
      })

      this.echo.connector.socket.on('connect', () => {
        console.log('broadcast connected', this.echo!.socketId())
      })

      /**
       * me@aceart.de - 13.11.2023
       * Commented out because the backend changed and this feature was never really used.
       */
      //      // listen for notifications that are intended for every user systemwide
      //     this.echo.connector.socket.on(`${eventPrefix}all:App\\Events\\BroadcastNotification`, (notification: any) => {

      //       this.restService
      //         .post('frontendUser/getNotificationById', { notification_id: notification.notification_id })
      //         .then((result: Object) => this.serializationService.deserialize(UserNotification, result))
      //         .then((notification: UserNotification) => {
      //           this.broadcastObservable.next(notification)
      //         })
      //     })

      // listen for notifications that are explicitly for you
      this.echo.connector.socket.on(`${eventPrefix}selected_user_${user.id}:App\\Events\\BroadcastNotification`, (notification: any) => {

        this.restService
          .post('frontendUser/getNotificationById', { notification_id: notification.notification_id })
          .then((result: Object) => this.serializationService.deserialize(UserNotification, result))
          .then((notification: UserNotification) => {
            this.broadcastObservable.next(notification)
          })
      })

      // listen for frontend commands like refetch user if a bonus rule is reached etc
      this.echo.connector.socket.on(`${eventPrefix}${user.id}:App\\Events\\BroadcastFrontendCommand`, response => {
        console.log("FRONTEND COMMAND RECEIVED ", response)
        if (response && typeof response.command != null && response.command) {
          if (response.command == "refreshUser")
            this.userService.refreshUser()
        }
      })
    })
  }

  private closeWebsockets(): void {
    if (this.echo) {
      this.echo.disconnect()
      this.echo = null
    }
  }
}
