import { Injectable } from '@angular/core'
import { RestService } from '@service/rest/rest.service'
import { Advert } from '@model/entities/advert'
import { PaginationSet } from '@model/paginationset/paginationset'
import { SerializationService, SerializationOptions } from 'aautil'
import { ToastService } from '@service/toast/toast.service'
import { StateParamFilter } from '@model/customrest/state-param-filter'
import { imageDefinitions } from '@configuration/image-definitions'
import { EntityService } from '@interface/entity-service'
import { SentryService } from '@service/sentry.service'
import { EntityTypeConfigService } from '@service/entity-type-config.service'
import { TypeService } from '@service/type.service'
import { EntityTypeIdentifier } from '@type/entity-type-identifier'
import { UserService } from '@service/user/user.service'
import { LoadingService } from '@service/loading.service'

@Injectable({
  providedIn: 'root',
})
export class AdvertService extends EntityService {
  public advertGridScrollTop: number

  protected entityTypeIdentifier: EntityTypeIdentifier = 'advert'

  constructor(
    protected sentryService: SentryService,
    protected restService: RestService,
    protected serializationService: SerializationService,
    protected typeService: TypeService,
    protected toastService: ToastService,
    protected entityTypeConfigService: EntityTypeConfigService,
    protected UserService: UserService,
    protected LoadingService: LoadingService,
  ) {
    super(
      restService,
      typeService,
      serializationService,
      sentryService,
      toastService,
      entityTypeConfigService,
      UserService,
      LoadingService
    )
  }

  public editDraft(id: number): Promise<Advert> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post('goods/editModel', { id }, imageDefinitions.advert.default_listview)
        .then((draft: Advert) => {
          // deserialize
          this.serializationService
            .deserialize(Advert, draft)
            .then(deserialized => {
              resolve(deserialized)
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public searchAdverts(stateParams): Promise<PaginationSet<Advert>> {
    const filter: StateParamFilter = new StateParamFilter()
    filter.fromStateParams(stateParams)

    return new Promise((resolve, reject) => {
      // serialize
      this.serializationService
        .serialize(filter, StateParamFilter, new SerializationOptions([{ "removeNull": true }]))
        .then(serializedFilters => {
          // kill nulls
          for (const key of Object.keys(serializedFilters)) {
            if (serializedFilters[key] == null) {
              delete serializedFilters[key]
            }
          }

          // post with pojo
          this.restService
            .post('goods/search', serializedFilters, imageDefinitions.advert.default_listview, false)
            .then((result: Object) => {
              // deserialize
              this.serializationService
                .deserialize(PaginationSet as new () => PaginationSet<Advert>, result, Advert)
                .then((data: PaginationSet<Advert>) => resolve(data))
                .catch(err => {
                  this.sentryService.silentCaptureException(err)
                  reject(err)
                })
            })
            .catch(err => reject(err))
        })
        .catch(err => {
          this.sentryService.silentCaptureException(err)
          reject(err)
        })
    })
  }

  public getDraft(): Promise<any> {
    return new Promise((resolve, reject) => {
      const data = {}

      // send
      this.restService
        .post('goods/draft/get', data, imageDefinitions.advert.default_listview)
        .then((result: Object) => {
          // deserialize
          this.serializationService
            .deserialize(Advert, result)
            .then(deserialized_advert => {
              resolve(deserialized_advert)
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  // fire and forget version
  public updateDraftOneShot(advert: Advert) {
    // serialize
    this.serializationService
      .serialize(advert, Advert)
      .then((serialized_advert: Advert) => {
        // send
        this.restService.post('goods/draft/update', serialized_advert)
      })
      .catch(err => {
        this.sentryService.silentCaptureException(err)
      })
  }

  public updateDraft(advert: Advert): Promise<Advert> {
    return new Promise((resolve, reject) => {
      // serialize
      this.serializationService
        .serialize(advert, Advert)
        .then((serialized_advert: Advert) => {
          // send
          this.restService
            .post('goods/draft/update', serialized_advert, imageDefinitions.advert.default_listview)
            .then((advert: Advert) => {
              // deserialize
              this.serializationService
                .deserialize(Advert, advert)
                .then((deserialized_advert: Advert) => {
                  resolve(deserialized_advert)
                })
                .catch(err => {
                  this.sentryService.silentCaptureException(err)
                  reject(err)
                })
            })
            .catch(err => reject(err))
        })
        .catch(err => {
          this.sentryService.silentCaptureException(err)
          reject(err)
        })
    })
  }

  public deleteImage(id: number, imageId: number): Promise<Advert> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post('goods/draft/deleteImages', { id, image_ids: [imageId] }, imageDefinitions.advert.default_listview)
        .then((advert: Advert) => {
          // deserialize
          this.serializationService
            .deserialize(Advert, advert)
            .then(deserialized_advert => resolve(deserialized_advert))
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post('goods/deleteModel', { id })
        .then(() => {
          resolve()
        })
        .catch(err => reject(err))
    })
  }

  public getFav(): Promise<Advert[]> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post('goods/getBookmarked', {}, imageDefinitions.advert.default_listview, false)
        .then((entities: any[]) => {
          // deserialize
          this.serializationService
            .deserializeMany(Advert, entities)
            .then(deserialized_entities => {
              resolve(deserialized_entities)
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public getMy(): Promise<Advert[]> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post('goods/getMy', {}, imageDefinitions.advert.default_listview, false)
        .then((adverts: any[]) => {
          // deserialize
          this.serializationService
            .deserializeMany(Advert, adverts)
            .then(deserialized_adverts => {
              resolve(deserialized_adverts)
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public getMostViewed(): Promise<Advert[]> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post('goods/getMostViewed', {}, imageDefinitions.advert.default_listview, false)
        .then((mostviewed: Advert[]) => {
          // deserialize
          this.serializationService
            .deserializeMany(Advert, mostviewed)
            .then(deserializedMostViewed => {
              resolve(deserializedMostViewed)
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }
}
