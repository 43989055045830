import { Serialize, SerializationService } from 'aautil'

export class PaginationSet<T> {
  @Serialize({
    from: {
      key: 'result',
      map: (raw, svc, _, Child) => svc.deserializeMany(Child, raw),
      deps: [SerializationService],
    },
  })
  values: T[]

  @Serialize()
  matched_categories: number[]

  @Serialize()
  count: number

  @Serialize()
  pagination_index: number

  @Serialize()
  pagination_size: number

  constructor(count: number, pagination_index: number, pagination_size: number, arr: T[]) {
    this.count = count
    this.pagination_index = pagination_index
    this.pagination_size = pagination_size
    this.values = arr
  }
}
