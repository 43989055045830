import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '@environments/environment'
import { NavigationItem } from '@model/navigation-item/navigation-item'
import { UserService } from '@service/user/user.service'

@Injectable({
  providedIn: 'root',
})
export class MenuStructureService {

  constructor(
    private Router: Router
  ) { }

  public getMainMenuLong(): Array<NavigationItem> {
    return [
      {
        caption: 'Blog',
        icon: 'fas fa-newspaper',
        icon_type: 'FA',
        target: '/blog',
      },
      {
        caption: 'Marktplatz',
        icon: 'fas fa-shopping-cart',
        icon_type: 'FA',
        target: '/market',
      },
      {
        caption: 'Jobbörse',
        icon: 'far fa-building',
        icon_type: 'FA',
        target: '/job',
      },
      {
        caption: 'Wiki',
        icon: 'fas fa-university',
        icon_type: 'FA',
        // target: '/wiki',
        target: '/grundwissen',
      },
      {
        caption: 'Partner',
        icon: 'far fa-handshake',
        icon_type: 'FA',
        target: '/partners',
      },
    ]
  }

  public getMainMenuShort(): Array<NavigationItem> {
    return [
      {
        caption: 'Blog',
        icon_type: 'FA',
        icon: 'fas fa-newspaper',
        target: '/blog',
      },
      {
        caption: 'Marktplatz',
        icon_type: 'FA',
        icon: 'fas fa-shopping-cart',
        target: '/market',
      },
      {
        caption: 'Job',
        icon_type: 'FA',
        // icon: 'fas fa-hammer',
        image: environment.publicFolder + 'assets/icons/haemmer.png',
        image_active: environment.publicFolder + 'assets/icons/haemmer_orange.png',
        target: '/job',
      },
      {
        caption: 'Wiki',
        icon_type: 'FA',
        icon: 'fas fa-university',
        // target: '/wiki',
        target: '/grundwissen',
      },
    ]
  }

  public getProfileMenuStructure(userService: UserService): Array<NavigationItem> {
    return [
      {
        caption: 'Über mich',
        icon: 'fas fa-user',
        icon_type: 'FA',
        target: '/profile/about',
      },
      {
        caption: 'Bonus',
        icon: environment.publicFolder + 'assets/images/woodcoin.svg',
        icon_type: 'IMG',
        target: '/profile/boni',
      },
      {
        caption: 'Meine Beiträge',
        icon: 'fas fa-edit',
        icon_type: 'FA',
        target: '/profile/mycontent',
      },
      // {
      //   caption: 'Merkliste',
      //   icon: 'fas fa-star',
      //   icon_type: 'FA',
      //   target: '/profile.bookmarks',
      // },
      {
        caption: 'Unternehmensprofil',
        icon: 'fas fa-hard-hat',
        icon_type: 'FA',
        target: '/profile/business',
      },
      // {
      //   caption: 'Mein Treffpunkt',
      //   icon: '/img/logo.png',
      //   icon_type: 'IMG',
      //   target: '/profile.meetingpoint',
      // },
      {
        caption: 'Einstellungen',
        icon: 'fas fa-cogs',
        icon_type: 'FA',
        target: '/profile/settings',
      },
      // {
      //   caption: 'Hilfe',
      //   icon: 'fal fa-question-square',
      //   icon_type: 'FA',
      //   target: '/profile.help',
      // },
      {
        caption: 'Abmelden',
        icon: 'fas fa-sign-out-alt',
        icon_type: 'FA',
        action: () => {
          this.Router.navigate(['/news']).then(() => {
            userService.logout()
          })
        },
      },
    ]
  }

  public getSocialMenuStructure(): Array<NavigationItem> {
    return [
      {
        caption: 'Facebook',
        icon: 'fab fa-facebook-square',
        icon_type: 'FA',
        externalTarget: 'https://www.facebook.com/ZimmererTreffpunkt/',
      },
      {
        caption: 'Instagram',
        icon: 'fab fa-instagram',
        icon_type: 'FA',
        externalTarget: 'https://www.instagram.com/zimmerertreffpunkt/',
      },
    ]
  }

  public peripheralMenuStructure = [
    {
      caption: 'Über uns',
      target: '/peripherals/about',
    },
    {
      caption: 'Kontakt',
      target: '/peripherals/contact',
    },
    {
      caption: 'Karriere / Job',
      target: '/peripherals/career',
    },
    {
      caption: 'AGB',
      target: '/peripherals/agb',
    },
    {
      caption: 'Datenschutz',
      target: '/peripherals/privacy',
    },
    {
      caption: 'Impressum',
      target: '/peripherals/impressum',
    },
  ]
}
