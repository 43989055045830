export class StaticHelper{

  public static prepareWebsite(w:string){
    if( w!=null && w!=""){
      if(!w.startsWith('http://') && !w.startsWith('https://'))
        return "https://"+w
    }
    return w
  }

}