import { Component, NgModule, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { ImageSliderComponentModule } from '@component/generic/image-slider/image-slider.component';
import { SliderComponentModule } from '@component/slider/slider.component';
import { environment } from '@environments/environment';
import { BonusLevel } from '@model/bonus-level';
import { BonusService } from '@service/bonus.service';
import { MobileService } from '@service/mobile.service';
import { SliderImagesService } from '@service/slider-images.service';
import { UserService } from '@service/user/user.service';
import { SharedModule } from 'src/app/shared.module';

@Component({
  selector: 'bonus-faq',
  templateUrl: './bonus-faq.component.html',
  styleUrls: ['./bonus-faq.component.scss']
})
export class BonusFaqComponent implements OnInit {

  public environment = environment

  public faq1 = [
    {
      question: "Warum gibt es ein Bonussystem?",
      answer: `Ziel des Zimmerer-Treffpunkts ist es, seine Mitglieder gut zu informieren und den Netzwerkgedanken zu fördern. Um diese Ziele zu erreichen, ist es wichtig, dass der Zimmerer-Treffpunkt lebt. Das schaffen wir aber nur durch Euch und Eure Kommentare, Beiträge, Einträge, etc.
      Eure Mühen sollen aber nicht umsonst sein. Deshalb haben wir ein Bonussystem entwickelt.
      Macht mit, beteiligt Euch und räumt tolle Preise ab!<br>
      <br>
      Und zu guter Letzt: Das Bonussystem gibt es auch, weil wir es toll finden, zu spielen und zu
      gewinnen!`
    }
  ]

  public faq2 = [
    {
      question: "Wie funktioniert die Verlosung",

      /*
      Zweimal jährlich (im Januar und im Juli) finden die Verlosungen statt. Es nehmen alle Nutzer
      automatisch an der Verlosung teil, die sich im Level „Zimmerer-King“ zum 30.06. bzw. 31.12. eines
      Jahres befinden. Erstmalig findet die Verlosung statt, sobald sich ein Nutzer im entsprechenden Level
      befindet.<br>
      <br>
      */

      answer: `
      Der Preis wird im Vorfeld bekannt gegeben und kann jährlich variieren. So viel sei aber verraten: Es
      handelt sich immer um einen hochwertigen Preis, den man als Zimmerer gut gebrauchen kann.
      Die Preise werden vom Zimmerer-Treffpunkt ausgewählt. Es besteht kein Anspruch auf Umtausch
      und die Auszahlung des Geldwertes ist ausgeschlossen. Das Gewinnspiel steht in keiner Verbindung
      zu Facebook und wird in keiner Weise von Facebook gesponsert, unterstützt oder organisiert.
      `
    },
    {
      question: "Wann und wie bekomme ich einen Sofortpreis?",
      answer: `
      Erreichst Du ein gewisses Level, z.B. „Erfahrener Geselle“ kannst Du dir deinen Preis direkt abholen.<br>
      Wichtig dabei ist: Nur wer seine Belohnung über den Button „Jetzt Preis erhalten“ anfordert,
      erhält auch den Preis. Außerdem muss deine Adresse im Profil hinterlegt sein oder Du musst sie uns beim Klicken auf "Jetzt Preis erhalten" mitteilen.
      `
    },
  ]

  // separated into two because design wants a peculiar separator - which I ignored for now - for budget reasons
  public woodcoinGetExplanations1 = [
    {
      action: "Bei der Registrierung",
      count: 5
    },
    {
      action: "Beim ersten Hochladen vom Profilbild",
      count: 25
    },
    {
      action: "Beim ersten Hochladen vom Titelbild",
      count: 25
    },
    {
      action: "Beim Befüllen der persönlichen Informationen",
      count: 25
    },
    {
      action: "Beim Befüllen der Netzwerkinformationen",
      count: 25
    },
    {
      action: "Beim Angeben der Adresse",
      count: 25
    },
    {
      action: "Beim Ausfüllen der Karriereinformationen",
      count: 25
    },
    {
      action: "Beim Hochladen des Zertifikats",
      count: 25
    },
  ]

  public woodcoinGetExplanations2 = [
    {
      action: "Beim ersten Blog-Beitrag",
      count: 20
    },
    {
      action: "Beim ersten Eintrag in der Jobbörse",
      count: 20
    },
    {
      action: "Bei jeder Marktplatz-Anzeige",
      count: 20
    },
    {
      action: "Bei jedem fachlich guten Kommentar",
      count: 10
    },
    // {
    //   action: "Beim Liken von anderen Beiträgen",
    //   count: 50
    // },
    // {
    //   action: "Zimmerer-App herunterladen und anmelden",
    //   count: 50
    // },
  ]

  public listitems1 = [
    {
      icon: "fas fa-check",
      text: "Vervollständige Dein Profil mit Profilbild und Titelbild."
    },
    {
      icon: "fas fa-check",
      text: "Dein erster Blog-Beitrag."
    },
    {
      icon: "fas fa-check",
      text: "jedes Inserat im Marktplatz."
    },
    {
      icon: "fas fa-check",
      text: "Deine erste Stellenanzeige in der Jobbörse."
    },
    {
      icon: "fas fa-check",
      text: "Fachlich gute Kommentare."
    },
    // {
    //   icon:"fas fa-check",
    //   text:"Das Liken von Beiträgen."
    // },
    // {
    //   icon:"fas fa-check",
    //   text:"Lade dir die Zimmerer-Treffpunkt-App im Appstore herunter und melde dich an. (App ist aktuell noch nicht im App-Store erhältlich)"
    // },
    {
      icon: "fas fa-arrow-right",
      text: "Die Punkte werden dir direkt auf deinem Profil gutgeschrieben."
    }
  ]

  public currentLevel: BonusLevel = null
  public levels: Array<BonusLevel> = null

  public hasSliderImages: boolean = false;

  constructor(
    private BonusService: BonusService,
    public mobileService: MobileService,
    private UserService: UserService,
    private sliderImagesService: SliderImagesService
  ) { }

  ngOnInit() {

    let promises = [
      this.BonusService.getAll()
        .then((levels: Array<BonusLevel>) => {
          this.levels = levels
        }),
    ]

    if (this.UserService.isLoggedIn) {
      promises.push(
        this.BonusService.getCurrent()
          .then((level: BonusLevel) => {
            this.currentLevel = level
          })
      )
    }

    Promise.all(promises);

    this.sliderImagesService.hasSliderImages$.subscribe((hasImages: boolean) => {
      this.hasSliderImages = hasImages;
    })

    this.sliderImagesService.checkHasSliderImages('faq');
  }

  public getLevelsPointRange(idx: number) {

    if ((idx + 1) >= this.levels.length)
      return "ab " + this.levels[this.levels.length - 1].needed_points + " Woodcoins"

    return this.levels[idx].needed_points + "-" + (this.levels[idx + 1].needed_points - 1) + " Woodcoins"
  }
}

// TODO move to separate module file
@NgModule({
  declarations: [
    BonusFaqComponent
  ],
  imports: [
    SharedModule,
    SliderComponentModule,
    ImageSliderComponentModule,
    MatExpansionModule,
  ],
  exports: [
    BonusFaqComponent,
    SharedModule,
    SliderComponentModule,
    ImageSliderComponentModule
  ]
})

export class BonusFaqModule { }