import { SerializationService } from 'aautil';
import { Advert } from './entities/advert';
import { Blog } from './entities/blog';
import { Job } from './entities/job';
import { AtomarDisplayUnit } from './atomar-display-unit';

export class BlogPost extends AtomarDisplayUnit {
    type: 'blog' = 'blog';

    constructor(public data: Blog) {
        super();
    }
}

export class AdvertPost extends AtomarDisplayUnit {
    type: 'advert' = 'advert';

    constructor(public data: Advert) {
        super();
    }
}

export class JobPost extends AtomarDisplayUnit {
    type: 'job' = 'job';

    constructor(public data: Job) {
        super();
    }
}

export type Post = BlogPost | AdvertPost | JobPost;

export async function deserializePostMapper(entry: any, svc: SerializationService): Promise<Post> {
    switch (entry.type) {
        case 'blog':
            const blog = await svc.deserialize(Blog, entry.data);
        return new BlogPost(blog);

        case 'advert':
            const advert = await svc.deserialize(Advert, entry.data);
        return new AdvertPost(advert);

        case 'job':
            const job = await svc.deserialize(Job, entry.data);
        return new JobPost(job);

        default:
            throw new Error(`Unknown user post type '${entry.type}'`);
    }
}
