<div class="line" fxLayout="row" fxLayoutGap="5px" style="width: 100%;">
    
    <!-- bright line -->
    <div fxLayout="column" fxFlex="grow">
      <div fxFlex="grow"></div>
      <div fxFlex="grow" style="border-top: 1px solid lightgray;"
      [style.border-color]="lines_color"></div>
    </div>

    <div [style.color]="text_color">{{word}}</div>

    <!-- bright line -->
    <div fxLayout="column" fxFlex="grow">
      <div fxFlex="grow"></div>
      <div fxFlex="grow" style="border-top: 1px solid lightgray;"
      [style.border-color]="lines_color"></div>
    </div>
</div>