import { appConfig } from '@configuration/app-config'
import { ToastService } from '@service/toast/toast.service';
import { allowedImageExtensions } from '@configuration/image-definitions'
import { messages } from '@configuration/messages'

export abstract class ImageUploadService {

  constructor(
    protected toastService: ToastService,
  ) { }

  public abstract uploadImage<T>(formData, purpose, shadow?): Promise<T>

  public onDropFile<T>(filelist: any, purpose: string, entityid: number, shadow: boolean = false): Promise<T> {
    return new Promise((resolve, reject) => {

      // check file count
      if (filelist.length > 10) {
        this.toastService.showError('Es können nicht mehr als 10 Bilder hochgeladen werden.')
        reject()
        return
      }

      // check image extensions
      for (let f of filelist) {
        const extension = this.getFileExtension(f.name).toLowerCase()

        if (!allowedImageExtensions.includes(extension)) {
          this.toastService.showError('Bilder mit dieser Dateiendung (' + extension + ') können nicht hochgeladen werden.')
          reject()
          return
        }
      }

      // check filesize
      if (!this.filesizeAllowed(filelist)) {
        this.toastService.showError('Die Bilder überschreiten die maximale Dateigröße von 10MB.')
        reject()
        return
      }

      // prepare
      const formData = new FormData()
      for (let i = 0; i < filelist.length; i++) {
        formData.append('images[' + i + '][image]', filelist[i], filelist[i].name)
        formData.append('images[' + i + '][purpose_key]', purpose)
        formData.append('id', entityid + '')
      }

      // upload
      this.uploadImage<T>(formData, purpose, shadow)
        .then((response: T) => resolve(response))
        .catch((e) => {
          console.log(e)
          this.toastService.showError(messages.imageuploaderror)
          reject()
        })

    })
  }

  private getFileExtension(filename: string): any {
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2)
  }

  public filesizeAllowed(imageInput: any): boolean {
    const largeImagesExists = Array.from(imageInput).find((file: any) => file.size > appConfig.maxSizeImage)

    if (largeImagesExists) {
      return false
    }
    return true
  }

}
