import { Injectable } from '@angular/core'
import { RestService } from '@service/rest/rest.service'
import { Blog } from '@model/entities/blog'
import { PaginationSet } from '@model/paginationset/paginationset'
import { ImageConfig } from '@model/image-config/image-config'
import { SerializationService, SerializationOptions } from 'aautil'
import { StateParamFilter } from '@model/customrest/state-param-filter'
import { imageDefinitions } from '@configuration/image-definitions'
import { EntityService } from '@interface/entity-service'
import { SentryService } from '@service/sentry.service'
import { TypeService } from '@service/type.service'
import { EntityTypeConfigService } from '@service/entity-type-config.service'
import { ToastService } from '@service/toast/toast.service'
import { EntityTypeIdentifier } from '@type/entity-type-identifier'
import { UserService } from '@service/user/user.service'
import { LoadingService } from '@service/loading.service'
import { ComputedImage } from '@model/computed-image'

@Injectable({
  providedIn: 'root',
})
export class BlogService extends EntityService {

  public advertGridScrollTop: number

  protected entityTypeIdentifier: EntityTypeIdentifier = 'blog'

  private stockimages: ComputedImage[] = []
  private stockimagesFetched: boolean = false

  constructor(
    protected sentryService: SentryService,
    protected restService: RestService,
    protected serializationService: SerializationService,
    protected typeService: TypeService,
    protected toastService: ToastService,
    protected entityTypeConfigService: EntityTypeConfigService,
    protected UserService: UserService,
    protected LoadingService: LoadingService,
  ) {
    super(
      restService,
      typeService,
      serializationService,
      sentryService,
      toastService,
      entityTypeConfigService,
      UserService,
      LoadingService
    )
  }

  public searchBlogs(stateParams: any, imageConfig?: ImageConfig): Promise<PaginationSet<Blog>> {
    const filter: StateParamFilter = new StateParamFilter()
    filter.fromStateParams(stateParams)

    return new Promise((resolve, reject) => {
      // serialize
      this.serializationService
        .serialize(filter, StateParamFilter, new SerializationOptions([{ "removeNull": true }]))
        .then(serializedFilters => {
          // send
          this.restService
            .post('blog/search', serializedFilters, imageDefinitions.blog.default_listview, false)
            .then((result: Object) => {
              // deserialize
              this.serializationService
                .deserialize(PaginationSet as new () => PaginationSet<Blog>, result, Blog)
                .then((data: PaginationSet<Blog>) => resolve(data))
                .catch(err => {
                  this.sentryService.silentCaptureException(err)
                  reject(err)
                })
            })
            .catch(err => reject(err))
        })
        .catch(err => {
          this.sentryService.silentCaptureException(err)
          reject(err)
        })
    })
  }

  public editDraft(id: number): Promise<Blog> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post('blog/editModel', { id }, imageDefinitions.blog.default_listview)
        .then((blog: Blog) => {
          // deserialize
          this.serializationService
            .deserialize(Blog, blog)
            .then(deserialized => {
              resolve(deserialized)
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public getDraft(): Promise<Blog> {
    return new Promise((resolve, reject) => {

      // send
      this.restService
        .post('blog/draft/get', {}, imageDefinitions.blog.default_listview)
        .then((result: Object) => {

          // deserialize
          this.serializationService
            .deserialize(Blog, result)
            .then(deserialized => resolve(deserialized))
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public updateDraft(draft: Blog): Promise<Blog> {
    return new Promise((resolve, reject) => {

      // serialize
      this.serializationService.serialize(draft, Blog)
        .then((serialized: Blog) => {

          // send
          this.restService
            .post<number>('blog/draft/update', serialized, imageDefinitions.blog.default_listview)
            .then((response: Object) => {

              // deserialize
              this.serializationService
                .deserialize(Blog, response)
                .then((deserialized: Blog) => {

                  // resolve
                  resolve(deserialized)
                })
                .catch(err => {
                  this.sentryService.silentCaptureException(err)
                  reject(err)
                })
            })
            .catch(err => reject(err))
        })
        .catch(err => {
          this.sentryService.silentCaptureException(err)
          reject(err)
        })
    })
  }

  public deleteImage(draftId: number, imageId: number): Promise<Blog> {
    return new Promise((resolve, reject) => {
      const data = {
        id: draftId,
        image_ids: [imageId],
      }

      // send
      this.restService
        .post('blog/draft/deleteImages', data, imageDefinitions.blog.default_listview)
        .then((blog: Blog) => {
          // deserialize
          this.serializationService
            .deserialize(Blog, blog)
            .then(deserialized_blog => resolve(deserialized_blog))
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post('blog/deleteModel', { id })
        .then(() => resolve())
        .catch(err => reject(err))
    })
  }

  public getStockImages(): Promise<ComputedImage[]> {
    return new Promise((resolve, reject) => {
      if (!this.stockimagesFetched) {
        // send
        this.restService
          .post<string[]>('imagearchive/getImagesByCategory', { category: 'Blog' })
          .then((stockimages: string[]) => {

            for (let cached of stockimages) {
              this.serializationService.deserialize(ComputedImage, { cached, isFallback: true })
                .then((stockimage: ComputedImage) => {
                  this.stockimages.push(stockimage)
                })
            }

            this.stockimagesFetched = true
            resolve(this.stockimages)
          })
          .catch(err => {
            reject(err)
          })
      } else resolve(this.stockimages)
    })
  }

  public getMostViewed(): Promise<Blog[]> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post<any[]>('blog/getMostViewed', {}, imageDefinitions.blog.default_listview, false)
        .then(mostviewed => {
          // deserialize
          this.serializationService
            .deserializeMany(Blog, mostviewed)
            .then(deserializedMostViewed => {
              resolve(deserializedMostViewed)
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public getFav(): Promise<Blog[]> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post('blog/getBookmarked', {}, imageDefinitions.advert.default_listview, false)
        .then((entities: any[]) => {
          // deserialize
          this.serializationService
            .deserializeMany(Blog, entities)
            .then(deserialized_entities => {
              resolve(deserialized_entities)
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public getMy(): Promise<Blog[]> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post('blog/getMy', {}, imageDefinitions.blog.default_listview, false)
        .then((blogs: any[]) => {
          // deserialize
          this.serializationService
            .deserializeMany(Blog, blogs)
            .then(deserialized_blogs => resolve(deserialized_blogs))
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public getOtherBlogsInCategory(blogId: number, categoryId: number): Promise<Blog[]> {
    return new Promise((resolve, reject) => {
      let data = {
        ignore_blog_id: blogId,
        blog_category_id: categoryId,
      }

      // send
      this.restService
        .post<any[]>('blog/getByCategory', data, imageDefinitions.blog.default_listview, false)
        .then(blogs => {
          this.serializationService
            .deserializeMany(Blog, blogs)
            .then(deserializedOther => resolve(deserializedOther))
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }
}
