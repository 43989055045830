<mat-dialog-content>
  <div class="dialog">

    <!-- close x -->
    <i (click)="close()"
      class="closex fas fa-times"></i>

    <div *ngIf="!sent"
      fxLayout="column"
      fxLayoutAlign="center center">

      <h2>Leider kannst Du dich mit Facebook bei uns nicht mehr anmelden!</h2>

      <p style="text-align: center;">
        Hast Du bereits ein Profil bei Zimmerer-Treffpunkt?<br />
        Dann gib hier die Email-Adresse ein, welche Du für deinen Facebook-Account nutzt!<br />
        (Das ist nötig um dein bereits von Dir erstelltes Profil zuordnen zu können)
      </p>

      <form [formGroup]="form"
        fxLayout="column"
        fxLayoutAlign="center center">

        <!-- email -->
        <mat-form-field>
          <input type="text"
            placeholder="E-mail Adresse"
            formControlName="email"
            class="form-control"
            matInput />
        </mat-form-field>

        <mat-error *ngIf="form.controls.email.touched && form.controls.email.errors"
          class="invalid-feedback">
          <div *ngIf="form.controls.email.errors">{{messages.emailInvalid}}</div>
        </mat-error>

        <p>
          <button class="orange-button"
            (click)="send()">bestätigen</button>
        </p>
      </form>

      <p>Hast Du noch kein Profil bei Zimmerer-Treffpunkt? Dann registriere Dich mit deiner Email-Adresse:</p>

      <button class="desktopGrowBtn mobileGrowBtn registerBtn"
        type="button"
        (click)="switch('registration')">
        Neues Konto erstellen
      </button>
    </div>

    <div *ngIf="sent">

      <h2>Wir haben Dir eine Email gesandt</h2>

      <p>
        Wenn ein über Facebook registrierter Account mit dieser Email-Adresse bei Zimmerer-Treffpunkt existiert, haben wir an diese Email eine Nachricht gesandt.<br />
        Damit kannst Du deinen Account bei Zimmerer-Treffpunkt wieder benutzen. Dein bereits erstelltes Profil ist hier gespeichert.<br />
        Wenn Du in ein paar Minuten keine Email von uns erhalten hast, schau bitte auch in Deinem Spam-Ordner nach.
      </p>

      <div fxLayout="row"
        fxLayoutAlign="end center">
        <button class="orange-button"
          (click)="DialogService.closeAllDialogs()"
          style="min-width: 80px;">OK</button>
      </div>

    </div>
  </div>
</mat-dialog-content>