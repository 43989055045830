import { Component, OnInit, Input, NgModule } from '@angular/core';
import { state, style, transition, animate, trigger } from '@angular/animations';
import { environment } from '@environments/environment';
import { SharedModule } from 'src/app/shared.module';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  animations: [
    trigger('openClose', [
      state('start', style({
        width: '0%'
      })),
      state('end', style({
        width: '100%'
      })),
      transition('start => end', [
        animate('10s')
      ]),
      transition('end => start', [
        animate('0s')
      ]),
    ])
  ]
})
export class SliderComponent implements OnInit {

  public environment = environment

  @Input()
  headline: string = null

  @Input()
  smallimage: boolean = false

  // determines if given image already has border and round crop
  @Input()
  rawImage: boolean = false

  @Input()
  public set sliderItems(s) {
    this._sliderItems = s

    // if values are correct -> choose random start element
    if (Array.isArray(s) && s.length > 0)
      this.selected_index = Math.floor(Math.random() * s.length)

  }
  public get sliderItems() {
    return this._sliderItems
  }
  private _sliderItems = null

  public selected_index = null

  // autoswitch
  public progressBarValue
  public intervalTime = 15 // in s
  public secs = 0
  private interval
  private timer

  ngOnInit() {
    this.start_interval()
  }

  public start_interval() {
    this.interval = setInterval(() => {
      this.next()
    }, this.intervalTime * 1000)

    this.secs = 1
    this.timer = setInterval(() => {
      this.secs++
    }, 1000)
  }

  private resetInterval() {
    clearInterval(this.interval)
    this.start_interval()
  }

  public next() {
    if (this.selected_index + 2 > this.sliderItems.length)
      this.selected_index = 0
    else
      this.selected_index++
    this.secs = 0
  }

  public previous() {

    this.selected_index--
    if (this.selected_index < 0)
      this.selected_index = this.sliderItems.length - 1

    this.secs = 0
  }

  public get current() {

    if (this.selected_index != null && this.selected_index > -1 && this.selected_index < this.sliderItems.length &&
      this.sliderItems[this.selected_index] != "undefined")
      return this.sliderItems[this.selected_index]

    else return null
  }

}

@NgModule({
  declarations: [
    SliderComponent,
  ],
  imports: [
    SharedModule,
  ],
  exports: [
    SharedModule,

    SliderComponent,
  ]
})
export class SliderComponentModule { }