<div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="start stretch" fxLayoutAlign.gt-md="center center" fxLayoutGap="10px" class="banner"
*ngIf="!consent && !userService.user">
  <div fxFlex.gt-md="80">
    Diese Webseite verwendet u.a. Cookies zur Analyse und Verbesserung der Webseite. Nähere Informationen dazu und zu Ihren Rechten als Benutzer finden Sie in unserer <span style="text-decoration: underline; cursor: pointer;"
    (click)="gotoPrivacy()">Datenschutzerklärung</span>. Klicken Sie bitte auf „OK“, um diese zu akzeptieren und direkt die Webseite besuchen zu können.
  </div>

  <div fxFlex.gt-md="20">
    <button (click)="setCookieConsent()" style="width: 100%;">OK</button>
  </div>
</div>