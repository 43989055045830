import { Component, Inject } from '@angular/core'
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DialogType } from '@type/dialog-type'

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-registration-dialog.component.html',
  styleUrls: ['./login-registration-dialog.component.scss'],
})
export class LoginRegistrationDialogComponent {

  public dialog_d: string
  public clickGuarded: boolean = false

  public static cssClass: DialogType = "black-fullscreen-dialog"

  constructor(
    public dialogRef: MatDialogRef<LoginRegistrationDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { dialog: string, clickGuarded: boolean },
  ) {
    this.dialog_d = data.dialog
    this.clickGuarded = data.clickGuarded
  }

  public statusChange(event) {
    this.dialog_d = event
  }

  public close() {
    this.dialogRef.close()
  }
}
