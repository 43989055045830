<mat-dialog-content>
  <div class="dialog" fxLayout="column" fxLayoutAlign="center center">
    <div class="dialog-content" fxLayout="column" fxLayoutAlign="start stretch">

      <div class="dialog-content-top">
        <div class="close-icon-container">
          <i (click)="close()" class="fas fa-times"></i>
        </div>

        <app-notifications-bell *ngIf="UserService.isLoggedIn"></app-notifications-bell>

        <div *ngIf="!UserService.isLoggedIn" class="profile">
          <app-login></app-login>
        </div>
      </div>


      <div *ngIf="UserService.isLoggedIn" class="profile-menu" fxLayout="column">
        <div class="menu-navigation-item" *ngFor="let item of profileMenuItems">
          <app-navigation-button [smallicon]="true" [navigationItem]="item"></app-navigation-button>
        </div>
        <div class="divider"></div>
      </div>

      <div class="second-menu" fxLayout="column">
        <div class="menu-navigation-item" *ngFor="let item of secondMenuItems">
          <app-navigation-button [smallicon]="true" [navigationItem]="item"></app-navigation-button>
        </div>
        <div class="divider"></div>
      </div>

      <div class="social-menu" fxLayout="column">
        <div class="menu-navigation-item" *ngFor="let item of socialMenuItems">
          <app-navigation-button [smallicon]="true" [navigationItem]="item"></app-navigation-button>
        </div>
        <div class="divider"></div>
      </div>

      <div class="menu-footer">
        <div *ngFor="let item of MenuStructureService.peripheralMenuStructure" [routerLink]="item.target"
          (click)="goto()" class="menu-footer-item">
          {{item.caption}}
        </div>
      </div>

    </div>
  </div>
</mat-dialog-content>