<div class="imageslider" *ngIf="current">

  <!-- left arrow -->
  <div class="arrow left" (click)="previous()">
    <img [src]="environment.publicFolder+'assets/icons/pfeil2.svg'">
  </div>

  <!-- slideritem -->
  <div class="slideritem" [style.background-image]="'url('+current.image+')'"></div>

  <!-- right arrow -->
  <div class="arrow right" (click)="next()">
    <img style="transform: rotate(180deg);" [src]="environment.publicFolder+'assets/icons/pfeil2.svg'">
  </div>

  <div class="headline_wrapper1">
    <div class="headline_wrapper2">
      <h2 [innerHTML]="current.headline"></h2>
    </div>
  </div>

  <!-- link plane overlay -->
  <a class="linkplane" [href]="current.link" target="_blank"></a>

  <image-slider-bar *ngIf="getHasImageSliderBar && !MobileService.isMobile"></image-slider-bar>

</div>