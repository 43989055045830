import { ImageConfig } from '@model/image-config/image-config'
import { ImageConfigItem } from '@model/image-config-item/image-config-item'

export const allowedImageExtensions = ['jpg','png','jpeg']

export const imageDefinitions = {
  news:{
    default_listview: new ImageConfig(
      new ImageConfigItem('MAIN', 400),
      new ImageConfigItem('SLIDE', 1920),
      new ImageConfigItem('PROFILE_IMAGE', 200),
      new ImageConfigItem('COMPANY_IMAGE', 200),
    ),
  },
  advert: {
    default_listview: new ImageConfig(
      new ImageConfigItem('MAIN', 400),
      new ImageConfigItem('SLIDE', 1920),
      new ImageConfigItem('PROFILE_IMAGE', 200),
      new ImageConfigItem('COMPANY_IMAGE', 200),
    ),
  },
  blog: {
    default_listview: new ImageConfig(
      new ImageConfigItem('MAIN', 800),
      new ImageConfigItem('SLIDE', 1920),
      new ImageConfigItem('PROFILE_IMAGE', 200),
      new ImageConfigItem('COMPANY_IMAGE', 200),
    )
  },
  job:{
    default_listview: new ImageConfig(
      new ImageConfigItem('MAIN', 800),
      new ImageConfigItem('SLIDE', 1920),
      new ImageConfigItem('PROFILE_IMAGE', 200),
      new ImageConfigItem('COMPANY_IMAGE', 200),
    ),
  },
  wiki: {
    default_listview: new ImageConfig(
      new ImageConfigItem('MAIN', 400),
      new ImageConfigItem('SLIDE', 1920),
      new ImageConfigItem('PROFILE_IMAGE', 200),
      new ImageConfigItem('COMPANY_IMAGE', 200),
    ),
  },
  user: {
    // ATTENTION: the size 70 and 200 is needed in user.ts again - if you change it here you need to change it in the user.ts too. Need to be refactored
    all: new ImageConfig(
      new ImageConfigItem('PROFILE_IMAGE', 200),
      new ImageConfigItem('PROFILE_WALL_IMAGE', 1920),

      new ImageConfigItem('COMPANY_IMAGE', 200),
      new ImageConfigItem('COMPANY_WALL_IMAGE', 1920),
      new ImageConfigItem('COMPANY_PRODUCT', 1024),
      new ImageConfigItem('COMPANY_CERTIFICATE', 1024),

      new ImageConfigItem('PROFILE_CERTIFICATE', 400),
    ),
    small_user_image: new ImageConfig(
      new ImageConfigItem('PROFILE_IMAGE', 200),
    ),
    high_res_profile_image: new ImageConfig(
        new ImageConfigItem('COMPANY_IMAGE', 800),
        new ImageConfigItem('PROFILE_IMAGE', 800),
    ),

    // This is set at the end of the file. It should never actually be null
    // when importing the file.
    all_with_high_res_profile: null as ImageConfig,
  },
  companyProfile: {

    default: new ImageConfig(
      new ImageConfigItem('COMPANY_IMAGE', 200),
      new ImageConfigItem('COMPANY_WALL_IMAGE', 1920),
      new ImageConfigItem('COMPANY_PRODUCT', 1024),
      new ImageConfigItem('COMPANY_CERTIFICATE', 1024),
    ),

    small_user_image: new ImageConfig(
      new ImageConfigItem('COMPANY_IMAGE', 70)
    ),

    high_res_profile_image: new ImageConfig(
        new ImageConfigItem('COMPANY_IMAGE', 800),
    ),

  },
}

imageDefinitions.user.all_with_high_res_profile = ImageConfig.merge([
    imageDefinitions.user.all,
    imageDefinitions.user.high_res_profile_image,
]);
