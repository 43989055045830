<zimmerer-route-ion-wrapper class="ion-page">

  <image-slider [fxHide]="!hasSliderImages" [page]="'faq'" fxHide.gt-md></image-slider>

  <div class="threecol" fxLayout="column" fxLayoutAlign.gt-md="center center">
    <div class="main-col" [class.noSlider]="!hasSliderImages" [class.withTopPadding]="mobileService.isMobile"
      fxLayout="column" fxLayoutGap="46px">

      <image-slider [fxHide]="!hasSliderImages" [page]="'faq'" fxHide.lt-lg></image-slider>

      <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="start center" fxLayout.gt-md="row">
        <img class="woodcoin" [src]="environment.publicFolder+'assets/images/woodcoin.svg'">
        <h1 class="mainheadline">{{mobileService.isMobile ? 'Bonus FAQ' : 'Bonussystem FAQ'}}</h1>
      </div>

      <mat-expansion-panel class="orange-caret-uppercase-panel" *ngFor="let f of faq1">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div [innerHTML]="f.question"></div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p [innerHTML]="f.answer"></p>
      </mat-expansion-panel>

      <mat-expansion-panel class="orange-caret-uppercase-panel" *ngFor="let f of faq1">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div style="display:flex; align-items: center; gap: 10px;">
              <div>Was sind Woodcoins?</div>
              <img style="height: 40px;" [src]="environment.publicFolder+'assets/images/woodcoin.svg'">
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Ein Woodcoin ist ein Bonuspunkt (eine Art Währung), den Du durch Aktivität beim Zimmerer-Treffpunkt verdienen
          kannst. Wie das geht, liest Du unter „Wie verdiene ich mir Woodcoins?“.<br>
          <br>
          Das Beste an dieser besonderen Zimmerer-Währung – sie wird nicht weniger, sondern nur mehr.
          Hast Du eine gewisse Punkteanzahl erreicht, bleibst Du auf diesem Level, auch wenn Du deinen Preis
          abholst. Bestimme selbst, wie schnell Du vom „Einsteiger“ über den „Zünftigen Gesellen“ zum
          „Zimmerer-King“ wirst.<br>
          Jede Stufe beinhaltet entweder Sofortgewinne oder ist an eine Verlosung gekoppelt.
          Mehr dazu liest Du unter „Welche Level gibt es und was kann ich gewinnen?“
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel class="orange-caret-uppercase-panel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div style="display:flex; align-items: center; gap: 10px;">
              <div>Wie verdiene ich Woodcoins?</div>
              <img style="height: 40px;" [src]="environment.publicFolder+'assets/images/woodcoin.svg'">
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Umso aktiver Du beim Zimmerer-Treffpunkt bist, desto mehr Woodcoins kannst Du verdienen.<br>
          Wichtig ist: Du musst mit deinem Profil eingeloggt sein. Wenn Du das nicht bist, erhältst Du leider
          keine Woodcoins. Woodcoins können nicht rückwirkend erfasst werden.
        </p>
        <div style="color: #ff8d00;">Also aufgepasst: immer zuerst einloggen und dann loslegen!</div>
        <div>Diese Aktionen werden von uns belohnt!</div>
        <div *ngFor="let li of this.listitems1" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div><i [class]="li.icon" style="color:#ff8d00;"></i></div>
          <div>{{li.text}}</div>
        </div>
      </mat-expansion-panel>

      <!-- benefits -->
      <h2 class="customheadline">Wie viele Woodcoins bekomme ich für welche Aktion?</h2>
      <table class="orange-table">
        <tr>
          <th>Aktion</th>
          <th class="centered">Anzahl Woodcoins</th>
        </tr>
        <tr *ngFor="let wge of woodcoinGetExplanations1">
          <td>{{wge.action}}</td>
          <td class="centered">{{wge.count}}</td>
        </tr>

        <!-- separator line -->
        <tr>
          <td style="padding:0px">
            <div style="border-top: 1px solid lightgray; margin-top: 10px; margin-bottom: 15px;"
              [style.margin-right]="mobileService.isMobile ? '15px' : '90px'"></div>
          </td>
          <td style="padding:0px;"></td>
        </tr>

        <tr *ngFor="let wge of woodcoinGetExplanations2">
          <td>{{wge.action}}</td>
          <td class="centered">{{wge.count}}</td>
        </tr>
      </table>

      <div>

        <h2 id="leveloverview" class="customheadline">Welche Levels gibt es und was kann ich gewinnen?</h2>

        <!-- grid -->
        <div class="grid">

          <!-- levelcard -->
          <div class="levelcard" *ngFor="let level of levels;let i = index"
            [class.current]="currentLevel && level.level == currentLevel.level" fxLayout="column" fxLayoutGap="10px">

            <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
              <img class="lvlimg" [src]="level.image">
              <div class="lvlidx">Level {{level.level}}</div>
              <div class="lvltitle">{{level.title}}</div>
            </div>

            <div class="lvldesc" fxFlex="grow">
              {{level.description}}
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <div fxFlex="grow" fxLayout="column" fxLayoutGap="15px">
                <div><b>Dein Preis:</b></div>
                <div>{{level.bonus_present}}</div>
              </div>
              <div fxFlex="noshrink" class="firstcircle">
                <div class="rangetxt" [innerHTML]="getLevelsPointRange(i)"></div>
                <div class="secondcircle" [style.background-image]="level.prize_image_safe"></div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <mat-expansion-panel class="orange-caret-uppercase-panel" *ngFor="let f of faq2">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{f.question}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p [innerHTML]="f.answer"></p>
      </mat-expansion-panel>

    </div>
  </div>

  <app-footer></app-footer>

</zimmerer-route-ion-wrapper>