import { Serialize } from 'aautil'
import moment from 'moment'
import { SentryService } from '@service/sentry.service';
import { MomentService } from '@service/moment.service';

export class BonusPivot {

  @Serialize()
  public bonus_level_id:number

  @Serialize()
  public created_at:string

  @Serialize()
  public frontend_user_id:number

  @Serialize({
    from:{
      map:(from:string, momentService: MomentService) =>
        momentService.stringToMoment(from,'bonus-pivot.ts_1'),
      deps: [MomentService],
    },
    to: false
  })
  public redeemed_at: moment.Moment = null

  @Serialize()
  public redeemed:number

  @Serialize()
  public updated_at:string

}