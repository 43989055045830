import { Category } from '@model/category/category'
import { Like } from '@model/like'
import { Moment } from 'moment';
import { Serialize } from 'aautil';
import { MomentService } from '@service/moment.service';
import { User } from '@model/user/user';
import { EntityTypeIdentifier } from '@type/entity-type-identifier';
import { ComputedImage } from '@model/computed-image';
import { ProfileTypeIdentifier } from '@enum/profile-type-identifier';
import { OgraphData } from '@model/ograph-data';

export abstract class Entity {

  public abstract id: number
  public abstract entityTypeIdentifier: EntityTypeIdentifier
  public abstract category: Category
  public abstract frontend_user: User
  public abstract profile_type: ProfileTypeIdentifier
  public abstract title: string
  public abstract content: string

  public abstract mainImage: ComputedImage

  public abstract likeable: boolean
  public liked: boolean = false

  @Serialize()
  public likes: Like[]

  @Serialize()
  public count_likes: number

  public count_comments: number = null

  public price: string = null
  public price_mode: string = null

  public videourlSafe = null

  @Serialize()
  public accepted_by_admin: boolean = null

  @Serialize()
  public is_edit: boolean = null

  @Serialize({
    to: false
  })
  public count_views: number = null

  @Serialize({
    to: false
  })
  public count_impressions: number = null

  @Serialize({
    from: {
      map: (s: string, momentService: MomentService) =>
        momentService.stringToMoment(s, 'entity.ts_1'),
      deps: [MomentService],
    },
    to: false
  })
  public created_at: Moment = null

  public abstract getOpgraphData(): OgraphData
}
