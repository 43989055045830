import { Injectable } from '@angular/core';
import { Sliderimage } from '@model/sliderimage';
import { SerializationService } from 'aautil';
import { RestService } from './rest/rest.service';
import { SentryService } from './sentry.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SliderImagesService {

  public hasSliderImages: boolean = false;

  private hasSliderImagesSubject = new BehaviorSubject<boolean>(false);

  hasSliderImages$ = this.hasSliderImagesSubject.asObservable();

  constructor(
    private restService: RestService,
    private serializationService: SerializationService,
    private sentryService: SentryService
  ) { }

  public getByPage(page: String): Promise<{ hasSliderImages: boolean, images: Sliderimage[] }> {
    return new Promise((resolve, reject) => {
      // fetch
      this.restService.post('sliderimages/getByPage', { page })
        .then((response: Sliderimage[]) => {
          // deserialize
          this.serializationService.deserializeMany(Sliderimage, response)
            .then((deserialized: Sliderimage[]) => {
              const hasSliderImages = deserialized.length > 0;

              resolve({ hasSliderImages, images: deserialized });
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => {
          this.sentryService.silentCaptureException(err)
          reject(err)
        })
    })
  }

  public async checkHasSliderImages(page: string): Promise<void> {
    const response = await this.getByPage(page);

    this.hasSliderImagesSubject.next(response.hasSliderImages);
  }
}
