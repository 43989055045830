import { Component, OnInit } from '@angular/core'
import { UserService } from '@service/user/user.service'
import { messages } from '@configuration/messages'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { DialogType } from '@type/dialog-type'

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss']
})
export class ForgotPasswordDialogComponent implements OnInit {

  public static cssClass: DialogType = 'black-centered-dialog'

  public loading = false

  public messages = messages

  public form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  })

  public sent: boolean = false

  constructor(
    private UserService: UserService,
    private dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
  ) { }

  ngOnInit() {
  }

  public send() {

    if (this.form.invalid)
      return

    this.loading = true
    this.UserService.sendPasswordForgotMail(this.form.controls.email.value)
      .then(() => {
        this.sent = true
      })
      .finally(() => {
        this.loading = false
      })
  }

  public closeDialog() {
    this.dialogRef.close()
  }

}
