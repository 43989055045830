import { Directive, HostListener, Output, EventEmitter } from '@angular/core'
import { UserService } from '@service/user/user.service'
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '@service/dialog.service';

@Directive({
  selector: '[appLoginClickGuard]',
})
export class LoginClickGuardDirective {

  @Output('appLoginClickGuard') guarded: EventEmitter<void> = new EventEmitter()

  constructor(
    private userService: UserService,
    public matDialog: MatDialog,
    public DialogService: DialogService
  ) { }

  @HostListener('click', ['$event']) onClick(event: Event) {

    if (!this.userService.isLoggedIn) {

      event.stopPropagation()
      this.DialogService.loginRegistrationDialog("registration", true)
    } else {
      this.guarded.emit()
    }
  }
}
