import { Injectable } from '@angular/core'
import { RestService } from '@service/rest/rest.service'
import { SerializationService, SerializationOptions } from 'aautil'
import { imageDefinitions } from '@configuration/image-definitions'
import { StateParamFilter } from '@model/customrest/state-param-filter'
import { Job } from '@model/entities/job'
import { PaginationSet } from '@model/paginationset/paginationset'
import { SentryService } from '@service/sentry.service'
import { EntityService } from '@interface/entity-service'
import { EntityTypeConfigService } from '@service/entity-type-config.service'
import { TypeService } from '@service/type.service'
import { ToastService } from '@service/toast/toast.service'
import { EntityTypeIdentifier } from '@type/entity-type-identifier'
import { UserService } from '@service/user/user.service'
import { LoadingService } from '@service/loading.service'

@Injectable({
  providedIn: 'root',
})
export class JobService extends EntityService {
  public advertGridScrollTop: number

  protected entityTypeIdentifier: EntityTypeIdentifier = 'job'

  private stockimages: string[] = []
  private stockimagesFetched: boolean = false

  constructor(
    protected sentryService: SentryService,
    protected restService: RestService,
    protected typeService: TypeService,
    protected serializationService: SerializationService,
    protected toastService: ToastService,
    protected entityTypeConfigService: EntityTypeConfigService,
    protected UserService: UserService,
    protected LoadingService: LoadingService,
  ) {
    super(
      restService,
      typeService,
      serializationService,
      sentryService,
      toastService,
      entityTypeConfigService,
      UserService,
      LoadingService
    )
  }

  public searchJobs(stateParams: any): Promise<PaginationSet<Job>> {
    return new Promise((resolve, reject) => {
      const filter: StateParamFilter = new StateParamFilter()
      filter.fromStateParams(stateParams)

      // serialize
      this.serializationService
        .serialize(filter, StateParamFilter, new SerializationOptions([{ "removeNull": true }]))
        .then(serializedFilters => {
          // send
          this.restService
            .post('job/search', serializedFilters, imageDefinitions.job.default_listview, false)
            .then((result: Object) => {
              // deserialize
              this.serializationService
                .deserialize(PaginationSet as new () => PaginationSet<Job>, result, Job)
                .then((data: PaginationSet<Job>) => resolve(data))
                .catch(err => {
                  this.sentryService.silentCaptureException(err)
                  reject(err)
                })
            })
            .catch(err => reject(err))
        })
        .catch(err => {
          this.sentryService.silentCaptureException(err)
          reject(err)
        })
    })
  }

  public editDraft(id: number): Promise<Job> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post('job/editModel', { id }, imageDefinitions.job.default_listview)
        .then((job: Job) => {
          // deserialize
          this.serializationService
            .deserialize(Job, job)
            .then(deserialized_job => resolve(deserialized_job))
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public getDraft(): Promise<Job> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post('job/draft/get', {}, imageDefinitions.job.default_listview)
        .then((result: Object) => {
          // deserialize
          this.serializationService
            .deserialize(Job, result)
            .then(deserialized_job => resolve(deserialized_job))
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public updateDraft(draft: Job): Promise<Job> {
    return new Promise((resolve, reject) => {
      // serialize
      this.serializationService
        .serialize(draft, Job)
        .then((serialized: Job) => {
          // send
          this.restService
            .post<number>('job/draft/update', serialized, imageDefinitions.job.default_listview)
            .then((response: Object) => {
              // deserialize
              this.serializationService
                .deserialize(Job, response)
                .then((deserialized: Job) => resolve(deserialized))
                .catch(err => {
                  this.sentryService.silentCaptureException(err)
                  reject(err)
                })
            })
            .catch(err => reject(err))
        })
        .catch(err => {
          this.sentryService.silentCaptureException(err)
          reject(err)
        })
    })
  }

  public deleteImage(draftId: number, imageId: number): Promise<Job> {
    return new Promise((resolve, reject) => {
      const data = {
        id: draftId,
        image_ids: [imageId],
      }

      // send
      this.restService
        .post('job/draft/deleteImages', data, imageDefinitions.job.default_listview)
        .then((job: Job) => {
          // deserialize
          this.serializationService
            .deserialize(Job, job)
            .then(deserialized_job => resolve(deserialized_job))
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post('job/deleteModel', { id })
        .then(() => resolve())
        .catch(err => reject(err))
    })
  }

  public getMostViewed(): Promise<Job[]> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post<any[]>('job/getMostViewed', {}, imageDefinitions.job.default_listview, false)
        .then(mostviewed => {
          // deserialize
          this.serializationService
            .deserializeMany(Job, mostviewed)
            .then(deserializedMostViewed => resolve(deserializedMostViewed))
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public getFav(): Promise<Job[]> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post('job/getBookmarked', {}, imageDefinitions.advert.default_listview, false)
        .then((entities: any[]) => {
          // deserialize
          this.serializationService
            .deserializeMany(Job, entities)
            .then(deserialized_entities => resolve(deserialized_entities))
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public getMy(): Promise<Job[]> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post('job/getMy', {}, imageDefinitions.job.default_listview, false)
        .then((jobs: any[]) => {
          // deserialize
          this.serializationService
            .deserializeMany(Job, jobs)
            .then(deserialized_jobs => resolve(deserialized_jobs))
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public getOtherJobsInCategory(jobId: number, categoryId: number): Promise<Job[]> {
    return new Promise((resolve, reject) => {
      const data = {
        ignore_job_id: jobId,
        job_category_id: categoryId,
      }

      // send
      this.restService
        .post<any[]>('job/getByCategory', data, imageDefinitions.job.default_listview, false)
        .then(jobs => {
          // deserialize
          this.serializationService
            .deserializeMany(Job, jobs)
            .then(deserializedOther => resolve(deserializedOther))
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }
}
