import { Injectable } from '@angular/core';
import moment from 'moment'
import { SentryService } from '@service/sentry.service';

@Injectable({
  providedIn: 'root'
})
export class MomentService {
 
  constructor(
    private sentryService: SentryService,
  ) { }

  public stringToMoment(s:string, source:string="undefined"):moment.Moment | null{

    if(!s)
      return null

    let m:moment.Moment = moment(s)

    if(!moment.isMoment(m)){
      this.sentryService.silentCaptureMessage(
        source+": "+s+" could not get converted to a valid moment"
      )
      return null
    }

    return m
  }

}
