import { Serialize, InFormGroup, CheckboxGroupModel, SerializationService } from 'aautil'
import { Validators, AbstractControl, AbstractControlOptions, FormGroup } from '@angular/forms'
import { Bookmark } from '@model/bookmark'
import { Like } from '@model/like'
import { ComputedImage } from '@model/computed-image'
import { User } from '@model/user/user'
import { Entity } from '@model/entities/entity'
import { AdvertProperty } from '@model/advert-property'
import { Category } from '@model/category/category'
import { Money } from '@service/money.static'
import { UrlPrettifierService } from '@service/urlPrettifier/url-prettifier.service';
import { OgraphData } from '@model/ograph-data';
import { OgraphEntity } from '@interface/ograph-entity';
import { standardvalidators } from '@configuration/standard-validators';
import { TypeService } from '@service/type.service'
import { Likeable } from '@interface/likeable';
import { EntityTypeIdentifier } from '@type/entity-type-identifier';
import { LikeableTypeIdentifier } from '@type/likeable-type-identifier';
import { ProfileTypeIdentifier } from '@enum/profile-type-identifier'
import { NgxGalleryImage } from 'ngx-gallery-9'
import { environment } from '@environments/environment'

// at least one delivery method must be set
function deliveryRequiredValidator(control: AbstractControl) {
  for (const k of Object.keys(control.value)) {
    if (control.value[k].model) {
      return null
    }
  }
  return { required: { value: 'at least one must be set' } }
}

// if delivery_pick_up is selected - zip and city need to be set as well
function locationRequired() {
  return (group: FormGroup): { [key: string]: any } => {
    const city = group.controls.city
    const zip = group.controls.zip
    const delivery = group.controls.delivery

    // if delivery_pick_up is set
    if (
      typeof delivery.value != 'undefined' &&
      delivery.value != null &&
      typeof delivery.value.delivery_pick_up != 'undefined' &&
      delivery.value.delivery_pick_up.model
    ) {
      // and zip or city is not valid
      if (
        typeof city.value == 'undefined' ||
        city.value == null ||
        city.value == '' ||
        typeof zip.value == 'undefined' ||
        zip.value == null ||
        zip.value == ''
      ) {
        // return invalid
        return { locationRequired: true }
      }
    }

    // return valid
    return {}
  }
}

// if advert is a search and no offer, price should not be required
function priceValidator() {
  return (group: FormGroup): { [key: string]: any } => {

    let errors = {}

    const advertisement_type = group.controls.advertisement_type
    const price_mode = group.controls.price_mode
    const price = group.controls.price

    // if offer price is required
    if (advertisement_type.value == "OFFER" && price_mode.value != "PRICE_ON_REQUEST") {
      if (price.value == null || typeof price.value == 'undefined')
        errors["price_required"] = true
    }

    // pattern for valid money
    if (price && price.value) {

      if (price.value.match(/^[0-9]+(,[0-9]{1,2})?$/) == null)
        errors['price_pattern'] = true

      // max price
      let price_f = price.value.replace(/,/g, '.')
      price_f = parseFloat(price_f) * 100
      if (price_f > 99999900)
        errors['price_max'] = true
    }

    return errors
  }
}

// if search -> no main image required
function mainImageValidator() {
  return (group: FormGroup): { [key: string]: any } => {

    let errors = {}

    const advertisement_type = group.controls.advertisement_type
    const mainImage = group.controls.mainImage

    // if offer price is required
    if (advertisement_type.value == "OFFER" && !mainImage.value) {
      errors["mainImage_required"] = true
    }

    return errors
  }
}

export class Advert extends Entity implements OgraphEntity, Likeable {

  public likeable = true
  public likeableTypeIdentifier: LikeableTypeIdentifier = "advert"

  public entityTypeIdentifier: EntityTypeIdentifier = "advert"

  @InFormGroup([])
  @Serialize()
  public id: number

  @Serialize()
  public adverts_id?: number

  @Serialize({ to: 'user_id', from: 'user_id' })
  public userId: number = -1

  @Serialize({
    from: {
      map: (frontend_user, svc: SerializationService) => svc.deserialize(User, frontend_user),
      deps: [SerializationService],
    },
    to: false
  })
  public frontend_user: User

  @InFormGroup([Validators.required])
  @Serialize()
  public profile_type = ProfileTypeIdentifier.private

  @InFormGroup([Validators.required])
  @Serialize({
    from: 'marketplace_category',
    to: 'marketplace_category'
  })
  public category: Category

  @InFormGroup([Validators.required, Validators.minLength(5), Validators.maxLength(80)])
  @Serialize()
  public title: string = ''

  @InFormGroup([Validators.required])
  @Serialize()
  public content: string = ''

  // HAS A CUSTOM VALIDATOR
  @InFormGroup()
  @Serialize({
    from: {
      map: (price: number, self: Advert) => {
        if (price != null)
          return Money.centNumberToEuroString(price)
        else
          return null
      }
    },
    to: {
      map: (price: string, typeService: TypeService, self: Advert) => {
        if (price != null && typeService.isString(price)) {
          price = price.replace(/,/g, '.')
          return (parseFloat(price) * 100)
        }
      },
      deps: [TypeService],
    }
  })
  public price: string = null

  @InFormGroup([Validators.required])
  @Serialize({
    to: {
      map: (price_mode: string, self: Advert) => {
        if (price_mode == 'PRICE_ON_REQUEST')
          self.price = null

        return price_mode
      }
    }
  })
  public price_mode: string = 'FIXED_PRICE'

  @InFormGroup()
  @Serialize({
    from: {
      map: (raw) => {
        if(raw){
          return parseInt(raw)
        }else {
          return null
        }
      }
    }
  })
  public zip: number = null

  @InFormGroup([Validators.maxLength(254)])
  @Serialize()
  public city: string = ''

  // IMAGES
  //////////////////////////////////////////////////////////////////////////////////////////////

  @InFormGroup()
  @Serialize({
    from: {
      key: 'cached_images',
      map: (raw: any, svc: SerializationService, self: Advert) => {

        // deserialize images
        return svc.deserializeMany(ComputedImage, raw)
          .then((images: ComputedImage[]) => {

            /*
              loop fusion
              + find main image
              + sort into ngxgallery
            */
            for (let c of images) {

              const imageDef = {
                small: c.fullUrl.cached,
                medium: c.fullUrl.cached,
                big: c.fullUrl.cached,
                description: self.title,
              }

              // find main image
              if (c.purpose_key == 'MAIN') {
                self.mainImage = c
              }

              // sort into ngx gallery
              if (c.purpose_key == "SLIDE") {
                if (c.image.purpose_key == "MAIN,SLIDE")
                  self.galleryImages.unshift(imageDef)
                else if (c.image.purpose_key == "SLIDE")
                  self.galleryImages.push(imageDef)
              }
            }

            return images
          })
      },
      deps: [SerializationService],
    },
    to: 'cached_images'
  })
  public images: ComputedImage[] = []

  @InFormGroup()
  public mainImage: ComputedImage = null

  public galleryImages: NgxGalleryImage[] = []

  //////////////////////////////////////////////////////////////////////////////////////////////

  @InFormGroup([Validators.required])
  @Serialize()
  public condition: string = 'NEW'

  @InFormGroup([Validators.required])
  @Serialize()
  public advertisement_type: 'OFFER' | 'SEARCH' = 'OFFER'

  @InFormGroup([Validators.required])
  @Serialize()
  public sale_type: string = 'PRIVATE'

  @InFormGroup(
    standardvalidators.affiliateLink
  )
  @Serialize()
  public affiliate_link: string

  @InFormGroup([Validators.maxLength(254)])
  @Serialize()
  public affiliate_link_button_text: string

  @Serialize()
  public affiliate_link_tracking: string

  @Serialize()
  public is_edit: boolean

  // DELIVERY
  //////////////////////////////////////////////////////////////////////////////////////////////

  // dont touch! this will be overridden by delivery when serialized
  @Serialize({
    from: {
      map: (delivery_shipping: boolean, self: Advert) => {
        self.delivery.delivery_shipping = new CheckboxGroupModel({
          name: 'Versand',
          model: delivery_shipping,
        })
        return delivery_shipping
      },
    },
  })
  public delivery_shipping: boolean = false

  // dont touch! this will be overridden by delivery when serialized
  @Serialize({
    from: {
      map: (delivery_pick_up: boolean, self: Advert) => {
        self.delivery.delivery_pick_up = new CheckboxGroupModel({
          name: 'Selbstabholung',
          model: delivery_pick_up,
        })
        return delivery_pick_up
      },
    },
  })
  public delivery_pick_up: boolean = true

  // meta object used so one formcontrol model can be passed to checkboxgroup
  // will not be sent or received from server
  @Serialize({
    to: {
      map: (delivery: { [name: string]: CheckboxGroupModel }, self: Advert) => {
        self.delivery_shipping = delivery.delivery_shipping.model
        self.delivery_pick_up = delivery.delivery_pick_up.model
        return {}
      },
    },
  })
  @InFormGroup([deliveryRequiredValidator])
  public delivery: { [name: string]: CheckboxGroupModel } = {
    delivery_shipping: new CheckboxGroupModel({
      name: 'Versand',
      model: false,
    }),
    delivery_pick_up: new CheckboxGroupModel({
      name: 'Selbstabholung',
      model: true,
    }),
  }

  //////////////////////////////////////////////////////////////////////////////////////////////

  @InFormGroup([], true)
  @Serialize({
    from: {
      map: (json: string, svc: SerializationService) => {
        let arr = JSON.parse(json)

        if (!arr)
          arr = []

        return svc.deserializeMany(AdvertProperty, arr)
      },
      deps: [SerializationService],
    },
    to: {
      map: (characteristics: AdvertProperty[], svc: SerializationService) => {
        if (Array.isArray(characteristics) && characteristics.length > 0)
          return svc.serializeMany(characteristics, AdvertProperty).then(arr => JSON.stringify(arr))
        else
          return "[]"
      },
      deps: [SerializationService],
    },
  })
  public characteristics: AdvertProperty[] = []

  @Serialize()
  public contact_telephone: string = ''

  @Serialize()
  public count_likes: number

  @Serialize()
  public bookmarks?: Bookmark[]

  @Serialize()
  public likes: Like[]

  // currently mostly used for validators that concern more than one formcontrol
  public formOptions: AbstractControlOptions = {
    validators: [
      locationRequired(),
      priceValidator(),
      mainImageValidator()
    ]
  }

  public getOpgraphData() {

    let data = new OgraphData()
    data.url = environment.backendUrl + "/market/detail/" + this.id + "/" + UrlPrettifierService.prettyString(this.title)

    return data
  }
}
