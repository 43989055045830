export class GMapAddress {

  street:string
  street_additional:string
  city:string
  zip:string
  state:string
  country:string

  constructor(data){
    Object.assign(this, data)
  }

  public getAsSearchString(){
    return this.street+" "+this.street_additional+" "+this.city+" "+this.zip+" "+this.state+" "+this.country
  }
}