import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlPrettifierService {

  constructor() { }

  public static prettifyGermanCharacters(str) {
    let search = ["ä", "ö", "ü", "Ä", "Ö", "Ü", "ß"];
    let replace = ["ae", "oe", "ue", "Ae", "Oe", "Ue", "ss"];

    for (var i = 0; i < search.length; i++) {
      str = str.replace(new RegExp(search[i], 'g'), replace[i]);
    }
    return str;
  }

  public static removeSpecialChars(str) {
    return str.replace(/[^A-Za-z0-9]/g, " ")
  }

  public static replaceSpaces(str) {
    return str.replace(/\s/g, "-")
  }

  /*
    combines multiple spaces like '   ' to one like ' '
  */
  public static mergeSpaces(str) {
    return str.replace(/\s+/g, ' ')
  }

  public static prettyString(str: string): string {
    str = str.toLowerCase()
    str = this.prettifyGermanCharacters(str)
    str = this.removeSpecialChars(str)
    str = this.mergeSpaces(str)
    str = this.replaceSpaces(str)
    return str
  }

  /*
    creates a prettified url.
    The function replaces german characters, replaces spaces, make the string lowercase and return it url encoded.
  */
  public static prettyUrl(str: string) {
    let result = str.toLowerCase()
    result = this.prettifyGermanCharacters(result)
    result = this.removeSpecialChars(result)
    result = this.mergeSpaces(result)
    result = this.replaceSpaces(result)
    result = encodeURIComponent(result)
    return result
  }
}