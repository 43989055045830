import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { messages } from '@configuration/messages'
import { DialogService } from '@service/dialog.service'
import { LoadingService } from '@service/loading.service'
import { UserService } from '@service/user/user.service'
import { DialogType } from '@type/dialog-type'

/*
  For documentation please look at SsoLoginButtonsComponent.loginWithFacebook
*/

@Component({
  selector: 'app-facebook-dialog',
  templateUrl: './facebook-dialog.component.html',
  styleUrls: ['./facebook-dialog.component.scss']
})
export class FacebookDialogComponent implements OnInit {

  @Input() public statusChange: (state: "login" | "registration" | "facebook") => void

  public static cssClass: DialogType = 'black-centered-dialog'

  public messages = messages

  public form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  })

  public sent: boolean = false

  constructor(
    private UserService: UserService,
    public DialogService: DialogService,
    private LoadingService: LoadingService,
    public dialogRef: MatDialogRef<FacebookDialogComponent>,
  ) { }

  ngOnInit() {
  }

  public send() {

    if (this.form.invalid)
      return

    this.LoadingService.startLoad()
    this.UserService.sendFacebookAccountConvertCreateToken(this.form.controls.email.value)
      .then(() => {
        this.sent = true
      })
      .finally(() => {
        this.LoadingService.stopLoad()
      })
  }

  public switch(e) {
    this.DialogService.loginRegistrationDialog(e);
  }

  public close() {
    this.dialogRef.close()
  }
}
