<!-- DESKTOP -->
<div class="navbar desktop" fxHide.lt-lg>

  <div style="width: 1335px;">

    <!-- logo -->
    <div class="logospacer" fxFlex="noshrink">
      <div class="logo unselectable" [class.shrink-logo-scroll]="isShrink" [class.active]="StateService.currentRouteData?.name == 'news'" routerLink="/news"
        (click)="ScrollService.scrollToTop()"></div>
    </div>

    <!-- nav btns -->
    <div class="navbtns" fxLayout="row" fxLayoutAlign="start center">

      <!-- nav btn -->
      <div *ngFor="let item of navigationItemsDesktop" class="navbtn" fxLayout="column" fxLayoutAlign="center center"
        fxLayoutGap="5px" [routerLink]="item.target" routerLinkActive="active" (click)="ScrollService.scrollToTop()">
        <!-- <i class="{{ item.icon }} navbtngraphic" style="text-align: center;"></i> -->
        <div class="navbtntext">{{ item.caption }}</div>
      </div>
    </div>

    <!-- create & notifications-bell & login buttons -->
    <div fxLayout="row" fxFlex="grow" fxLayoutAlign="end center" fxLayoutGap="10px">

      <!-- TODO use create entry button component here -->
      <!-- create button and create tooltip -->
      <div style="position: relative;">

        <!-- global create button -->
        <button class="orange-button create-btn"
          (click)="isCreateTooltipVisible ? HeaderService.hideCreateTooltipNow() : HeaderService.showCreateTooltipNow()"
          (clickOutside)="HeaderService.hideCreateTooltipNow()">
          Beitrag erstellen
        </button>

        <!-- global create button tooltip -->
        <div *ngIf="isCreateTooltipVisible" class="createTooltip">

          <div class="entry" (appLoginClickGuard)="StateService.goto('/blog/create',{},true,true)">
            <i class="fas fa-list-alt"></i>
            <div>Blog-Beitrag erstellen</div>
          </div>

          <div class="entry" (appLoginClickGuard)="StateService.goto(
              '/blog/create',
              {preselected:{category:CategoriesService.eventcat}},
              true,
              true
            )">
            <i class="far fa-calendar-check"></i>
            <div>Veranstaltung erstellen</div>
          </div>

          <div class="entry" (appLoginClickGuard)="StateService.goto(
              '/blog/create',
              {preselected:{category:CategoriesService.videocat}},
              true,
              true
            )">
            <i class="fas fa-video"></i>
            <div>Video veröffentlichen</div>
          </div>

          <div class="entry" (appLoginClickGuard)="StateService.goto('/market/create',{},true,true)">
            <i class="fa fa-euro-sign"></i>
            <div>Zimmereibedarf verkaufen</div>
          </div>

          <div class="entry" (appLoginClickGuard)="StateService.goto(
              '/market/create',
              {preselected:{advertisement_type:'SEARCH'}},
              true,
              true
            )">
            <i class="fas fa-shopping-cart"></i>
            <div>Zimmereibedarf suchen</div>
          </div>

          <div class="entry" (appLoginClickGuard)="StateService.goto('/job/create',{},true,true)">
            <i class="fas fa-user-plus"></i>
            <div>Stellenanzeige posten</div>
          </div>

          <div class="entry" (appLoginClickGuard)="StateService.goto('/job/create',{},true,true)">
            <i class="fas fa-search-plus"></i>
            <div>Stellengesuch posten</div>
          </div>

        </div>

      </div>

      <!-- notifications-bell -->
      <app-notifications-bell *ngIf="userService.isLoggedIn"></app-notifications-bell>

      <!-- login -->
      <app-login></app-login>

    </div>

  </div>

</div>

<!-- MOBILE -->
<div class="spacer" fxHide.gt-md></div>
<div class="navbar mobile" fxLayout="row" fxLayoutAlign="space-around stretch" fxHide.gt-md>

  <div fxFlex="grow" fxLayout="row" fxLayoutAlign="center center">
    <div class="logo" (click)="ScrollService.scrollToTop()" routerLink="/news" routerLinkActive="active"></div>
  </div>

  <!-- navigation buttons -->
  <div *ngFor="let item of navigationItemsMobile" class="navbtn" [routerLink]="item.target" routerLinkActive="active"
    (click)="ScrollService.scrollToTop()" fxFlex="grow" fxLayout="row" fxLayoutAlign="center center">
    <div>
      <i *ngIf="item.icon" [class]="item.icon"></i>
      <img style="width:42px;" class="image" *ngIf="item.image" [src]="item.image">
      <img style="width:42px;" class="image_active" *ngIf="item.image_active" [src]="item.image_active">
    </div>
  </div>

  <div class="burger" (click)="drawerService.open()" fxFlex="grow" fxLayout="row" fxLayoutAlign="center center">
    <i class="fas fa-bars fa-3x"></i>
  </div>
</div>