import { Component, EventEmitter, Output } from '@angular/core'
import { UserService } from '@service/user/user.service'
import { NavigationItem } from '@model/navigation-item/navigation-item'
import { MenuStructureService } from '@service/menu-structure/menu-structure.service'
import { MatDialog } from '@angular/material/dialog';
import { DrawerService } from '@service/drawer.service'
import { DialogService } from '@service/dialog.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  public navpoints: NavigationItem[]

  public showMenu = false

  @Output() public dialogClose = new EventEmitter()

  constructor(
    public userService: UserService,
    private menuStructureService: MenuStructureService,
    public dialog: MatDialog,
    private DrawerService: DrawerService,
    public DialogService: DialogService
  ) {
    this.navpoints = this.menuStructureService.getProfileMenuStructure(userService)
  }

  public openDialog(dialog: string): void {
    this.DrawerService.close()
    this.DialogService.loginRegistrationDialog(dialog)
  }

  public onClickedOutside(): void {
    this.showMenu = false
  }

  public clickedOnMenu(): void {
    this.showMenu = !this.showMenu
  }

  public select(index: number): void {
    this.DrawerService.close()
    const action = this.navpoints[index].action
    if (typeof action == 'function') {
      action()
    }
  }
}