import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  public showCreateTooltip: boolean = false
  private showCreateTooltipChange: Subject<boolean> = new Subject<boolean>()

  constructor() {
    this.showCreateTooltipChange.subscribe((value) => {
      this.showCreateTooltip = value
      // console.log("changed to:",value)
    })
  }

  public showCreateTooltipNow() {
    this.showCreateTooltipChange.next(true)
  }

  public hideCreateTooltipNow() {
    this.showCreateTooltipChange.next(false)
  }
}