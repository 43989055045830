import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  public showCreateTooltip:Boolean = false
  private showCreateTooltipChange: Subject<boolean> = new Subject<boolean>()

  constructor() {
    this.showCreateTooltipChange.subscribe((value) => {
      this.showCreateTooltip = value
    })
  }

  public showCreateTooltipNow() {
    this.showCreateTooltipChange.next(true)
  }
  
  public hideCreateTooltipNow(){
    this.showCreateTooltipChange.next(false)
  }
}