import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lines-word',
  templateUrl: './lines-word.component.html',
  styleUrls: ['./lines-word.component.scss']
})
export class LinesWordComponent implements OnInit {

  @Input()
  public word:String = ""

  @Input()
  public text_color:String = "black"

  @Input()
  public lines_color:String = "lightgray"

  constructor() { }

  ngOnInit(): void {
  }

}