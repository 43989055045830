import { Serialize, InFormGroup } from 'aautil'
import { Validators } from '@angular/forms'
import { ComputedImage } from '@model/computed-image'
import { HelperService } from '@service/helper.service';

export class Contentpart {

  @InFormGroup()
  @Serialize()
  id: number = -1

  @InFormGroup([Validators.required, Validators.minLength(3), Validators.maxLength(254)])
  @Serialize()
  title: string = ''

  @InFormGroup([Validators.required, Validators.minLength(30),])
  @Serialize()  
  content: string = ''

  @InFormGroup([Validators.required])
  @Serialize()
  wiki_id: number = null
  
  @Serialize({
    from: {
      map: (cached_images: ComputedImage[], hs:HelperService, self: Contentpart ) => {

        // sanitize images
        for( let i of cached_images )
          i.safe = hs.trustStyleURL(i.cached)

        return cached_images
      },
      deps: [HelperService],
    },
    to: false
  })
  public cached_images: ComputedImage[] = []

}