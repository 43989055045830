import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class MobileService {

  public mobileObservable: BehaviorSubject<boolean> = new BehaviorSubject(null)

  public isMobile: boolean = false

  public appversion: string = ""

  public get isDesktop(): boolean {
    return !this.isMobile
  }

  public isIos: boolean = false
  public isAndroid: boolean = false

  public isSocialMediaInAppBrowser: boolean = window.navigator.userAgent.toLowerCase().includes("fb_iab") || window.navigator.userAgent.toLowerCase().includes("instagram")

  public getStoreLink() {
    if (this.isIos)
      return "https://apps.apple.com/de/app/zimmerer-treffpunkt/id6444530498"

    return "https://play.google.com/store/apps/details?id=com.zimmerer.app"
  }

  public checkMobile() {
    let viewportWidth = window.innerWidth
    if (viewportWidth < 1280) {
      this.isMobile = true
      this.mobileObservable.next(true)
    }
    else {
      this.isMobile = false
      this.mobileObservable.next(false)
    }
  }
}
