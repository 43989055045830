import { Serialize, SerializationService } from 'aautil';
import { BonusPivot } from '@model/bonus-pivot';
import { environment } from '@environments/environment';
import { SafeStyle } from '@angular/platform-browser';
import { HelperService } from '@service/helper.service';

export class BonusLevel {
  
  @Serialize()
  public title: string

  @Serialize()
  public description: string

  @Serialize()
  public level: number

  @Serialize()
  public needed_points: number

  @Serialize()
  public bonus_present: string

  @Serialize({
    from:{
      map: ( image: string ) => {
        return environment.backendUrl+'/storage/'+image
      },
    }
  })
  public image: string

  @Serialize({
    from:{
      map: ( image: string ,hs: HelperService, self: BonusLevel) => {
        self.prize_image_safe = hs.trustStyleURL(environment.backendUrl+'/storage/'+image)
        return environment.backendUrl+'/storage/'+image
      },
      deps:[HelperService]
    }
  })
  public prize_image: string
  public prize_image_safe: SafeStyle

  @Serialize({
    from: {
      map: (raw: any, svc: SerializationService) => (raw ? svc.deserialize(BonusPivot, raw) : raw),
      deps: [SerializationService],
    },
    to: {
      map: (e: BonusPivot, svc: SerializationService) => (
        e ? svc.serialize(e, BonusPivot) : e
      ),
      deps: [SerializationService],
    },
  })
  public pivot:BonusPivot
}
