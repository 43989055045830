import { Injectable } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { BurgerDialogComponent } from '@modal/burger-dialog/burger-dialog.component'
import { BehaviorSubject } from 'rxjs'

/*
  THIS SERVICE SHOULD NOT INJECT OTHER SERVICES AND ITSELF BE INJECTED
  https://gitea.aceart.de/aceArtGmbH/zimmerer-treffpunkt/src/branch/master/README.md
  chapter "How to avoid Angular Service Circular Dependency"
*/
@Injectable()
export class DrawerService {

  public drawerStateObservable: BehaviorSubject<boolean> = new BehaviorSubject(null)

  // boolean - false = closed / true = opened
  public drawerState: boolean = false

  private dialog: null | MatDialogRef<BurgerDialogComponent, any> = null

  constructor(
    private MatDialog: MatDialog,
  ) { }

  public open() {
    this.drawerState = true
    this.drawerStateObservable.next(this.drawerState)

    this.dialog = this.MatDialog.open(BurgerDialogComponent, {
      data: {},
      autoFocus: false,
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      panelClass: BurgerDialogComponent.cssClass
    })
  }

  /*
    The Promise stuff is necessary if one wants to be sure to do stuff after dialog close.
    E.g. scroll to top (dialog prevents our current method to do that)
  */
  public close(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.drawerState = false
      this.drawerStateObservable.next(this.drawerState)

      if (this.dialog) {
        this.dialog.afterClosed().subscribe(result => {
          resolve()
        })
        this.dialog.close()
      }
      else
        resolve()
    })
  }
}
