import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zimmerer-route-ion-wrapper',
  templateUrl: './route-ion-wrapper.component.html',
  styleUrls: ['./route-ion-wrapper.component.scss']
})
export class RouteIonWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
