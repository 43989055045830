import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared.module";
import { CreateEntryButtonComponent } from "./create-entry-button.component";

@NgModule({
    declarations: [
        CreateEntryButtonComponent
    ],
    providers: [],
    imports: [
        SharedModule
    ],
    exports: [
        CreateEntryButtonComponent,
    ]
})
export class CreateEntryButtonComponentModule {
}
