import { Injectable } from '@angular/core'
import { RestService } from '@service/rest/rest.service'
import { UserService } from '@service/user/user.service'
import { BehaviorSubject } from 'rxjs'
import { SerializationService } from 'aautil'
import { BroadcastService } from '@service/broadcast/broadcast.service'
import { imageDefinitions } from '@configuration/image-definitions'
import { Action, UserNotification } from '@model/user-notification/user-notification'
import { ToastService } from '@service/toast/toast.service'
import $ from 'jquery'
import { DrawerService } from './drawer.service'
import { EntityTypeConfig } from '@model/entity-type-config'
import { EntityTypeConfigService } from './entity-type-config.service'
import { StateService } from './state.service'
import { TypeService } from './type.service'
import { SentryService } from './sentry.service'
import { UrlPrettifierService } from './urlPrettifier/url-prettifier.service'
import { Entity } from '@model/entities/entity'

/*
  THIS SERVICE SHOULD INJECT OTHER SERVICES
  https://gitea.aceart.de/aceArtGmbH/zimmerer-treffpunkt/src/branch/master/README.md
  chapter "How to avoid Angular Service Circular Dependency"
*/
@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  public notificationObservable: BehaviorSubject<UserNotification[] | null> = new BehaviorSubject<UserNotification[] | null>(null)

  public notifications: UserNotification[] = []

  public areNotificationsOpen: boolean = false

  constructor(
    private ToastService: ToastService,
    private restService: RestService,
    private userService: UserService,
    private broadcastService: BroadcastService,
    private serializationService: SerializationService,
    private DrawerService: DrawerService,
    private EntityTypeConfigService: EntityTypeConfigService,
    private StateService: StateService,
    private TypeService: TypeService,
    private SentryService: SentryService
  ) {
    this.userService.userObservable.subscribe(user => {
      // logged in
      if (user) {
        // user is now logged in -> fetch notifications
        this.restService
          .post<object[]>('frontendUser/getNotifications', {}, imageDefinitions.user.small_user_image)
          .then(result => this.serializationService.deserializeMany(UserNotification, result))
          .then(notifications => {
            this.notifications = notifications
          })
          .finally(() => {
            // publish notifications
            this.notificationObservable.next(this.notifications)
          })
      }
      // logout
      else {
        this.notifications = []
      }
    })

    this.broadcastService.broadcastObservable.subscribe(notification => {
      if (notification) {
        this.ToastService.showSuccess(notification.headline)
        this.notifications.unshift(notification)
        this.notificationObservable.next(this.notifications)
      }
    })

    // if the mobile menu / burger / drawer closes -> close bell notification overlay as well
    this.DrawerService.drawerStateObservable.subscribe((drawerState: boolean) => {
      if (!drawerState)
        this.closeNotifications()
    })
  }

  public markOneAsRead(id: number) {
    this.restService.post(
      'frontendUser/setNotificationsViewed',
      { notification_ids: [id] }
    ).then(() => {
      this.notifications = this.notifications.filter((n: UserNotification) => n.id != id);
    })
  }

  public markAllAsRead() {
    this.restService.post(
      'frontendUser/setNotificationsViewed',
      { notification_ids: this.notifications.map(noti => noti.id) }
    ).then(() => {
      this.notifications = []
    })
  }

  public openNotifications() {

    // the jquery stuff is neccessary because the drawer has overflow-y: scroll because it needs to scroll itself

    // make drawer not scrollable so overflow does not cut notifications tooltip
    $('#maindrawer').first().css('overflow-y', 'unset')

    this.areNotificationsOpen = true
  }

  public closeNotifications() {
    // make drawer scrollable again
    $('#maindrawer').first().css('overflow-y', 'scroll')

    this.areNotificationsOpen = false
  }

  public toggleNotificationsOpen() {
    this.areNotificationsOpen ? this.closeNotifications() : this.openNotifications()
  }

  /*
    This is called for clicks in the notification bell.
    And for clicks on a push message.
  */
  public execNotificationAction(action: string) {

    this.DrawerService.close()

    /*
      In a bright future action might be an Instance of Action class...
      Preparations for that are made.
      But rn its not - for time / backend reasons.
    */
    let split = action.split("||")
    let actionObject = new Action();

    actionObject.action = split[0]

    if (typeof split[1] != "undefined") {
      actionObject.modelName = split[1]
    }

    if (typeof split[2] != "undefined") {
      actionObject.id = parseInt(split[2])
    }

    if (actionObject.action == 'gotobonus') {
      this.StateService.goto('/profile/boni')
    }

    else if (actionObject.action == 'gotocomments') {
      this.getCurrentModelAndGoThere({ "scrollToComments": true }, actionObject)
    }

    else if (actionObject.action == 'goto') {
      this.getCurrentModelAndGoThere({}, actionObject)
    }
  }

  private getCurrentModelAndGoThere(extras, actionObject: Action) {

    let ao = actionObject

    let entityTypeConfig: EntityTypeConfig = this.EntityTypeConfigService.getConfigByName(ao.modelName)

    entityTypeConfig.service.getById(ao.id)
      .then(response => {

        let target = {
          id: ao.id,
          title: ''
        }

        // if it returns a number (id a of a newer version) its a redirect to a newer version of the entity
        if (this.TypeService.isNumber(response)) {
          entityTypeConfig.service.getById(<number>response)
            .then((entity: Entity) => {
              target.id = entity.id
              target.title = entity.title
            })
            .catch(err => {
              this.SentryService.silentCaptureException(err)
            })
        }

        this.StateService.goto(
          entityTypeConfig.detailstate + "/" + target.id + "/" + UrlPrettifierService.prettyUrl(target.title),
          extras
        )
      })
      .catch(() => {
        this.ToastService.showError('Der Beitrag konnte nicht abgerufen werden.')
      })
  }

  public click(notification: UserNotification): void {
    this.markOneAsRead(notification.id)
    this.execNotificationAction(notification.action)
  }
}
