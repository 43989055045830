import { Injectable } from '@angular/core'
import { imageDefinitions } from '@configuration/image-definitions'
import { EntityService } from '@interface/entity-service'
import { Contentpart } from '@model/contentpart'
import { StateParamFilter } from '@model/customrest/state-param-filter'
import { PaginationSet } from '@model/paginationset/paginationset'
import { Wiki } from '@model/entities/wiki'
import { EntityTypeConfigService } from '@service/entity-type-config.service'
import { LoadingService } from '@service/loading.service'
import { RestService } from '@service/rest/rest.service'
import { SentryService } from '@service/sentry.service'
import { ToastService } from '@service/toast/toast.service'
import { TypeService } from '@service/type.service'
import { UserService } from '@service/user/user.service'
import { EntityTypeIdentifier } from '@type/entity-type-identifier'
import { SerializationOptions, SerializationService } from 'aautil'

@Injectable({
  providedIn: 'root',
})
export class WikiService extends EntityService {

  public advertGridScrollTop: number

  protected entityTypeIdentifier: EntityTypeIdentifier = 'wiki'

  private stockimages: string[] = []
  private stockimagesFetched: boolean = false

  constructor(
    protected sentryService: SentryService,
    protected restService: RestService,
    protected typeService: TypeService,
    protected serializationService: SerializationService,
    protected toastService: ToastService,
    protected entityTypeConfigService: EntityTypeConfigService,
    protected UserService: UserService,
    protected LoadingService: LoadingService,
  ) {
    super(
      restService,
      typeService,
      serializationService,
      sentryService,
      toastService,
      entityTypeConfigService,
      UserService,
      LoadingService
    )
  }

  public search(stateParams): Promise<PaginationSet<Wiki>> {
    const filter: StateParamFilter = new StateParamFilter()
    filter.fromStateParams(stateParams)

    return new Promise((resolve, reject) => {
      // serialize
      this.serializationService
        .serialize(
          filter,
          StateParamFilter,
          new SerializationOptions([{ "removeNull": true }])
        )
        .then(serializedFilters => {
          // post with pojo and caching
          this.restService
            .post('wiki/search', serializedFilters, imageDefinitions.wiki.default_listview, false)
            .then((result: Object) => {
              // deserialize
              this.serializationService
                .deserialize(PaginationSet as new () => PaginationSet<Wiki>, result, Wiki)
                .then((data: PaginationSet<Wiki>) => resolve(data))
                .catch(err => {
                  this.sentryService.silentCaptureException(err)
                  reject(err)
                })
            })
            .catch(err => reject(err))
        })
        .catch(err => {
          this.sentryService.silentCaptureException(err)
          reject(err)
        })
    })
  }

  public getDraft(): Promise<any> {
    return new Promise((resolve, reject) => {
      const data = {}
      this.restService
        .post('wiki/draft/get', data, imageDefinitions.wiki.default_listview)
        .then((w: Object) => {
          this.serializationService
            .deserialize(Wiki, w)
            .then(deserialized_Wiki => {
              resolve(deserialized_Wiki)
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }


  public updateDraft(draft: Wiki): Promise<Wiki> {
    return new Promise((resolve, reject) => {
      // serialize
      this.serializationService
        .serialize(draft, Wiki)
        .then(serialized_Wiki => {
          // send
          this.restService
            .post('wiki/draft/update', serialized_Wiki, imageDefinitions.wiki.default_listview)
            .then((draft: Wiki) => {
              // deserialize
              this.serializationService
                .deserialize(Wiki, draft)
                .then((deserialized_Wiki: Wiki) => {
                  resolve(deserialized_Wiki)
                })
                .catch(err => {
                  this.sentryService.silentCaptureException(err)
                  reject(err)
                })
            })
            .catch(err => reject(err))
        })
        .catch(err => {
          this.sentryService.silentCaptureException(err)
          reject(err)
        })
    })
  }

  public createContentpart(): Promise<Contentpart> {
    return new Promise((resolve, reject) => {
      this.restService
        .post('wiki/draft/contentpart/create', {})
        .then((contentpart: Object) => {
          this.serializationService
            .deserialize(Contentpart, contentpart)
            .then(deserialized_contentpart => {
              resolve(deserialized_contentpart)
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public updateContentpart(contentpartDraft: Contentpart): Promise<void> {
    return new Promise((resolve, reject) => {
      this.serializationService
        .serialize(contentpartDraft, Contentpart)
        .then(serialized_contentpart => {
          this.restService
            .post<number>('wiki/draft/contentpart/update', serialized_contentpart)

            // silent update -> no feedback necessary -> nobodie's waiting :)
            .then(response => resolve())
            .catch(err => reject(err))
        })
        .catch(err => {
          this.sentryService.silentCaptureException(err)
          reject(err)
        })
    })
  }

  public deleteContentpart(contentpartId: number): Promise<number> {
    return new Promise((resolve, reject) => {
      const data = {
        id: contentpartId,
      }
      this.restService
        .post<number>('wiki/draft/contentpart/delete', data)
        .then(result => resolve(result))
        .catch(err => reject(err))
    })
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.restService
        .post('wiki/deleteModel', { id })
        .then(() => resolve())
        .catch(err => reject(err))
    })
  }

  public deleteImage(id: number, imageId: number): Promise<Wiki> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post('wiki/draft/deleteImages', { id, image_ids: [imageId] }, imageDefinitions.advert.default_listview)
        .then((w: Wiki) => {
          // deserialize
          this.serializationService
            .deserialize(Wiki, w)
            .then(deserialized_Wiki => resolve(deserialized_Wiki))
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public getStockImages(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      if (!this.stockimagesFetched) {
        this.restService
          .post<string[]>('imagearchive/getImagesByCategory', { category: 'Wiki' })
          .then(stockimages => {
            this.stockimages = stockimages
            this.stockimagesFetched = true
            resolve(this.stockimages)
          })
          .catch(err => reject(err))
      } else {
        resolve(this.stockimages)
      }
    })
  }

  public getMostViewed(): Promise<Wiki[]> {
    return new Promise((resolve, reject) => {
      this.restService
        .post<any[]>('wiki/getMostViewed', {}, imageDefinitions.wiki.default_listview, false)
        .then(mostviewed => {
          this.serializationService
            .deserializeMany(Wiki, mostviewed)
            .then(deserializedMostViewed => {
              resolve(deserializedMostViewed)
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public getFav(): Promise<Wiki[]> {
    return new Promise((resolve, reject) => {
      this.restService
        .post('wiki/getBookmarked', {}, imageDefinitions.advert.default_listview, false)
        .then((entities: any[]) => {
          this.serializationService
            .deserializeMany(Wiki, entities)
            .then(deserialized_entities => {
              resolve(deserialized_entities)
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public getMy(): Promise<Wiki[]> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post('wiki/getMy', {}, imageDefinitions.wiki.default_listview, false)
        .then((wiki_posts: any[]) => {
          // deserialize
          this.serializationService
            .deserializeMany(Wiki, wiki_posts)
            .then(deserialized_wiki_posts => {
              resolve(deserialized_wiki_posts)
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public getArticleOfTheDay(): Promise<Wiki> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post('wiki/getOfTheDay', {}, imageDefinitions.wiki.default_listview)
        .then((result: Object) => {
          // deserialize
          this.serializationService
            .deserialize(Wiki, result)
            .then(deserialized => {
              resolve(deserialized)
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public editDraft(id: number): Promise<Wiki> {
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post('wiki/editModel', { id }, imageDefinitions.wiki.default_listview)
        .then((draft: Wiki) => {
          // deserialize
          this.serializationService
            .deserialize(Wiki, draft)
            .then(deserialized => {
              resolve(deserialized)
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public titleExists(title: string, parent_model_id?: number): Promise<any> {
    return new Promise((resolve, reject) => {
      let data = {
        title,
      }

      if (parent_model_id) {
        data['parent_model_id'] = parent_model_id
      }

      // send
      this.restService
        .post('wiki/titleExists', data)
        .then(response => resolve(response))
        .catch(err => reject(err))
    })
  }
}
