<div class="dialog"
  fxLayout="column"
  fxLayoutAlign="center center">

  <!-- close x -->
  <i (click)="close()"
    class="closex fas fa-times"></i>

  <div class="dialog-content"
    fxLayout="column"
    fxLayoutAlign="start stretch">

    <div class="logo"
      fxLayout="column"
      fxLayoutAlign="center center">
      <img [src]="environment.publicFolder+'assets/images/logo.svg'"
        alt="Zimmerer-Treffpunkt Logo">
    </div>

    <div class="headline desktop"
      *ngIf="!MobileService.isMobile">
      <div style="color: white;">Starte jetzt mit</div>
      <div style="color: #ff8d00;">Zimmerer-Treffpunkt</div>
    </div>

    <div class="headline mobile"
      *ngIf="MobileService.isMobile">
      Starte jetzt
    </div>

    <div class="switch"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
      fxLayout.gt-md="row">
      <div>Du hast bereits ein Konto?</div>
      <div class="switchbtn"
        (click)="switch('login')">
        Jetzt einloggen
      </div>
    </div>

    <app-sso-login-buttons></app-sso-login-buttons>

    <lines-word [word]="'oder'"
      [lines_color]="'white'"
      [text_color]="'white'"></lines-word>

    <form [formGroup]="form"
      (ngSubmit)="onSubmit()"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px">

      <div fxLayout="column"
        fxLayoutGap="10px">

        <input fxFlex.gt-md="grow"
          matInput
          type="text"
          placeholder="Name"
          formControlName="firstname" />
        <mat-error *ngIf="(form.controls.firstname.touched || submitted) && form.controls.firstname.errors">
          *Vorname ist ein Pflichtfeld (Min. zwei Zeichen).
        </mat-error>

        <input fxFlex.gt-md="grow"
          matInput
          type="text"
          placeholder="Nachname"
          formControlName="lastname" />
        <mat-error *ngIf="(form.controls.lastname.touched || submitted) && form.controls.lastname.errors">
          *Nachname ist ein Pflichtfeld (Min. zwei Zeichen).
        </mat-error>

        <input fxFlex.gt-md="grow"
          matInput
          type="text"
          placeholder="E-mail Adresse"
          formControlName="email" />
        <mat-error *ngIf="form.hasError('mail_unique')">
          *Die E-Mail ist bereits vergeben
        </mat-error>
        <mat-error *ngIf="(form.controls.email.touched || submitted) && form.controls.email.errors">
          <div *ngIf="form.controls.email.errors">{{messages.emailInvalid}}</div>
        </mat-error>

        <input fxFlex.gt-md="grow"
          matInput
          type="password"
          placeholder="Passwort"
          formControlName="password" />
        <mat-error *ngIf="(form.controls.password.touched || submitted) && form.controls.password.errors">
          <div *ngIf="form.controls.password.errors.required">*Passwort ist ein Pflichtfeld</div>
          <div *ngIf="form.controls.password.errors.minlength">*Mindestlänge: 6 Zeichen</div>
        </mat-error>

        <input fxFlex.gt-md="grow"
          matInput
          type="password"
          placeholder="Passwort wiederholen"
          formControlName="password_confirmation" />
        <mat-error *ngIf="((form.controls.password_confirmation.touched&&form.controls.password.touched) || submitted) && form.errors">
          <div *ngIf="form.errors.passwordsDontMatch">Die Passwörter stimmen nicht überein</div>
        </mat-error>

        <agb-newsletter [(form)]="form"
          [submitted]="submitted"></agb-newsletter>

      </div>

      <!-- login button -->
      <button class="desktopGrowBtn mobileGrowBtn orange-button"
        [disabled]="form.invalid">
        Jetzt registrieren
      </button>

      <button class="desktopGrowBtn mobileGrowBtn transparent-orange-button"
        (click)="forgotPw()">
        Passwort zurücksetzen
      </button>
    </form>

  </div>
</div>