<!-- gray square -->
<div class="slider"
  style="background-color: lightgray;"
  *ngIf="current">

  <div class="padding-content"
    style="padding-top: 0px; padding-bottom: 0px;">

    <div *ngIf="headline"
      class="contentHeadline"
      style="margin-bottom: 10px; padding-top: 40px;">{{headline}}</div>

    <div style="position: relative;">

      <!-- left arrow -->
      <div style="position: absolute; left: 0px; top: calc(50% - 25px); cursor: pointer;"
        (click)="previous()">
        <img class="arrow"
          [src]="environment.publicFolder+'assets/icons/zt_pfeil.svg'">
      </div>

      <!-- slideritems -->
      <div class="slideritem"
        fxLayout="column"
        fxLayout.gt-xs="row"
        fxLayoutGap="20px"
        fxLayoutAlign="start center">
        <!-- [style.background-color]="selected.bgcolor" -->

        <!-- image -->
        <div>
          <img *ngIf="!rawImage"
            [src]="current.img"
            [class.smallimage]="smallimage">

          <div *ngIf="rawImage"
            class="smallRawImage"
            [style.background-image]="'url('+current.img+')'"></div>
        </div>

        <!-- text -->
        <div>
          <div style="margin-bottom: 10px;">{{current.text}}</div>
          <div style="font-style: italic;">
            <div>{{current.name}}</div>
            <div>{{current.title}}</div>
          </div>
        </div>
      </div>

      <!-- right arrow -->
      <div style="position: absolute; right: 0px; top: calc(50% - 25px); cursor: pointer;"
        (click)="next()">
        <img class="arrow"
          style="transform: rotate(180deg);"
          [src]="environment.publicFolder+'assets/icons/zt_pfeil.svg'">
      </div>

    </div>

  </div>

</div>
<!-- <mat-progress-bar mode="determinate" [value]="((intervaltime*secs)/100)*100"></mat-progress-bar> -->
<!-- <div style="height: 2px; position: relative; background-color: darkgray;">
  <div class="progress" style="position: absolute; left: 0px; height: 2px; top: 0px; background-color: black;"></div>
</div> -->