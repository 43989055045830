import { ImageUploadService } from '@interface/image-upload-service'
import { RestService } from '@service/rest/rest.service'
import { EntityTypeConfig } from '@model/entity-type-config'
import { imageDefinitions } from '@configuration/image-definitions'
import { TypeService } from '@service/type.service'
import { SerializationService } from 'aautil'
import { SentryService } from '@service/sentry.service'
import { ToastService } from '@service/toast/toast.service'
import { EntityTypeIdentifier } from '@type/entity-type-identifier'
import { EntityTypeConfigService } from '@service/entity-type-config.service'
import { Entity } from '@model/entities/entity'
import { UserService } from '@service/user/user.service'
import { LoadingService } from '@service/loading.service'

export abstract class EntityService extends ImageUploadService {

  protected abstract entityTypeIdentifier: EntityTypeIdentifier
  protected entityTypeConfig: EntityTypeConfig

  constructor(
    protected restService: RestService,
    protected typeService: TypeService,
    protected serializationService: SerializationService,
    protected sentryService: SentryService,
    protected toastService: ToastService,
    protected entityTypeConfigService: EntityTypeConfigService,
    protected UserService: UserService,
    protected LoadingService: LoadingService
  ) {
    super(toastService)
  }

  public getById(id: number): Promise<any> {

    let entityTypeConfig = this.entityTypeConfigService.getConfigByName(this.entityTypeIdentifier)

    const data = { id }
    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post(entityTypeConfig.apiModelName + '/getById', data, imageDefinitions[entityTypeConfig.identifier].default_listview)
        .then((result: Object) => {
          // if the original post does not exist anymore
          if (typeof result['redirect'] != 'undefined') {
            if (result['redirect'] != null && this.typeService.isNumber(result['redirect'])) {
              resolve(result['redirect'])
            }

            reject()
          } else {
            // deserialize
            this.serializationService
              .deserialize(entityTypeConfig.modelclass, result)
              .then(deserialized => resolve(deserialized))
              .catch(err => {
                this.sentryService.silentCaptureException(err)
                reject(err)
              })
          }
        })
        .catch(err => reject(err))
    })
  }

  public uploadImage<T>(formData: FormData): Promise<T> {

    let entityTypeConfig = this.entityTypeConfigService.getConfigByName(this.entityTypeIdentifier)

    return new Promise((resolve, reject) => {
      // send
      this.restService
        .post(
          entityTypeConfig.uploadimageendpoint,
          formData,
          imageDefinitions[entityTypeConfig.identifier].default_listview,
          true,
        )
        .then((draft: Object) => {
          // deserialize
          this.serializationService
            .deserialize<T>(entityTypeConfig.modelclass, draft)
            .then((deserialized: T) => resolve(deserialized))
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public createDraftIfNotExists(): Promise<any> {
    return new Promise((resolve, reject) => {

      let entityTypeConfig: EntityTypeConfig = this.entityTypeConfigService.getConfigByName(this.entityTypeIdentifier)

      this.restService
        .post(entityTypeConfig.apiModelName + "/draft/create", {})
        .then((response: any) => {
          if (response == 'DRAFT_FOR_USER_ALREADY_EXISTS') {
            resolve('DRAFT_FOR_USER_ALREADY_EXISTS')
          } else {

            // deserialize
            this.serializationService.deserialize(entityTypeConfig.modelclass, response)
              .then((deserialized) => {
                resolve(deserialized)
              })
          }
        })
        .catch(err => reject(err))
    })
  }

  public discardDraft(): Promise<any> {
    return new Promise((resolve, reject) => {

      let entityTypeConfig: EntityTypeConfig = this.entityTypeConfigService.getConfigByName(this.entityTypeIdentifier)

      this.restService
        .post(
          entityTypeConfig.apiModelName + "/draft/discardDraftAndCreate",
          {},
          imageDefinitions[entityTypeConfig.identifier].default_listview
        )
        .then((response: any) => {

          // deserialize
          this.serializationService.deserialize(entityTypeConfig.modelclass, response)
            .then((deserialized) => {
              resolve(deserialized)
            })
        })
        .catch(err => reject(err))
    })
  }

  public publishDraft(draft: Entity): Promise<any> {
    return new Promise((resolve, reject) => {

      let entityTypeConfig = this.entityTypeConfigService.getConfigByName(this.entityTypeIdentifier)

      // send
      this.restService
        .post<number>(entityTypeConfig.apiModelName + '/draft/publish', {
          id: draft.id,
          url: draft.getOpgraphData().url,
        })
        .then((result: Object) => {
          // deserialize
          this.serializationService
            .deserialize(entityTypeConfig.modelclass, result)
            .then((serialized: Entity) => {
              this.UserService.refreshUser().then(() => {
                this.LoadingService.stopLoad()
                resolve(serialized)
              })
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public abstract getFav()
  public abstract getMy()
  public abstract delete(id: number): Promise<void>
  public abstract updateDraft(draft: Entity): Promise<Entity>
  public abstract editDraft(id: number): Promise<Entity>
  public abstract getDraft(): Promise<Entity>

  public abstract deleteImage(draftId: number, imageId: number): Promise<Entity>
}
