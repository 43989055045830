import { Injectable } from '@angular/core';
import { EntityTypeConfig } from '@model/entity-type-config';
import { Entity } from '@model/entities/entity';
import { Blog } from '@model/entities/blog';
import { Advert } from '@model/entities/advert';
import { Job } from '@model/entities/job';
import { Wiki } from '@model/entities/wiki';

@Injectable({
  providedIn: 'root'
})
export class EntityTypeConfigService {

  public entitytypes: {
    blog: EntityTypeConfig,
    job: EntityTypeConfig,
    advert: EntityTypeConfig,
    wiki: EntityTypeConfig
  } = {

      blog: new EntityTypeConfig({
        identifier: "blog",
        backendModelName: "Blog",
        apiModelName: "blog",

        liststate: '/blog',
        detailstate: '/blog/detail',
        editstate: '/blog/edit',
        createstate: '/blog/create',

        uploadimageendpoint: 'blog/draft/uploadImages',

        service: null, // filled at start of app because of instancing reasons
        modelclass: Blog,
        backendClassNamespace: 'App\\Models\\Blog',
      }
      ),

      job: new EntityTypeConfig({
        identifier: "job",
        backendModelName: "Job",
        apiModelName: "job",

        liststate: '/job',
        detailstate: '/job/detail',
        editstate: '/job/edit',
        createstate: '/job/create',

        uploadimageendpoint: 'job/draft/uploadImages',

        service: null, // filled at start of app because of instancing reasons
        modelclass: Job,
        backendClassNamespace: 'App\\Models\\Job',
      }),

      advert: new EntityTypeConfig({
        identifier: "advert",
        backendModelName: "Advert",
        apiModelName: "goods",

        liststate: '/market',
        detailstate: '/market/detail',
        editstate: '/market/edit',
        createstate: '/market/create',

        uploadimageendpoint: 'goods/draft/uploadImages',

        service: null, // filled at start of app because of instancing reasons
        modelclass: Advert,
        backendClassNamespace: 'App\\Models\\Advert',
      }),

      wiki: new EntityTypeConfig({
        identifier: "wiki",
        backendModelName: "Wiki",
        apiModelName: "wiki",

        liststate: '/wiki',
        detailstate: '/wiki/detail',
        editstate: '/wiki/edit',
        createstate: '/wiki/create',

        uploadimageendpoint: 'wiki/draft/uploadImages',

        service: null, // filled at start of app because of instancing reasons
        modelclass: Wiki,
        backendClassNamespace: 'App\\Models\\Wiki',
      }),
    }

  // dont want to search every time we access
  // built by constructor
  private byBackendModelNamespace_index = {}

  constructor() {

    // build indizes
    for (let key of Object.keys(this.entitytypes)) {
      this.byBackendModelNamespace_index[this.entitytypes[key].backendClassNamespace] = this.entitytypes[key]
    }

  }

  public getConfigByName(name): EntityTypeConfig {
    return this.entitytypes[name]
  }

  public getConfigByEntity(entity: Entity): EntityTypeConfig {
    return this.entitytypes[entity.entityTypeIdentifier]
  }

  public getConfigByBackendClassNamespace(s) {
    return this.byBackendModelNamespace_index[s]
  }
}