import { CompanyProfile } from '@model/company-profile/company-profile'
import { Serialize, InFormGroup, SerializationService } from 'aautil'
import { Validators } from '@angular/forms'
import { ComputedImage } from '@model/computed-image'
import { Profile } from '@interface/profile'
import { standardvalidators } from '@configuration/standard-validators'
import { StaticHelper } from '../../misc/static-helper';
import { GMapAddress } from '@model/gmap-address';
import { ProfileTypeIdentifier } from '@enum/profile-type-identifier'
import { AdvertPost, BlogPost, deserializePostMapper, JobPost, Post } from '@model/post'
import { Blog } from '@model/entities/blog'
import { Advert } from '@model/entities/advert'
import { Job } from '@model/entities/job'

export class User implements Profile {

  public profileType = ProfileTypeIdentifier.private

  @Serialize()
  public id: number

  @InFormGroup([Validators.required, Validators.minLength(2), Validators.maxLength(50)])
  @Serialize()
  public firstname: string

  @InFormGroup([Validators.required, Validators.minLength(2), Validators.maxLength(50)])
  @Serialize()
  public lastname: string

  @InFormGroup([Validators.minLength(2), Validators.maxLength(50)])
  @Serialize()
  public private_nickname: string

  @InFormGroup([Validators.required, standardvalidators.validEmail])
  @Serialize()
  public email: string

  @InFormGroup()
  @Serialize({
    from: {
      map: (raw: any, svc: SerializationService, self: User ) => {

        // deserialize images
        svc.deserializeMany(ComputedImage, raw)
        .then((dImages:ComputedImage[])=>{

          for(let i of dImages){
            if(i.purpose_key == 'PROFILE_IMAGE')
              self.profileImage = i
  
            else if(i.purpose_key == 'PROFILE_WALL_IMAGE')
              self.profileWallImage = i
  
            else if(i.purpose_key == 'PROFILE_CERTIFICATE')
              self.certificateImage = i
          }

          return dImages
        })
      },
      deps: [SerializationService]
    }
  })
  public cached_images: ComputedImage[]

  public profileImage: ComputedImage

  public profileWallImage: ComputedImage

  public certificateImage: ComputedImage

  @Serialize({ to: att => (att ? 1 : 0) })
  public ROLE_CUSTOMER: boolean = false

  @Serialize({ to: att => (att ? 1 : 0) })
  public ROLE_EDITOR: boolean = false

  @Serialize({ to: att => (att ? 1 : 0), from: att => att as boolean })
  public ROLE_SUPPLIER: boolean = false

  @Serialize({ to: att => (att ? 1 : 0), from: att => att as boolean })
  public ROLE_ZIMMERER: boolean = false

  @Serialize()
  public bonus_points: number = 0

  // STATISTICS
  ////////////////////////////////////////////////////////////

  @Serialize({ to: false })
  public statistics_count_adverts_asked: number = 0

  @Serialize({ to: false })
  public statistics_count_adverts_created: number = 0

  @Serialize({ to: false })
  public statistics_count_blogs_created: number = 0

  @Serialize({ to: false })
  public statistics_count_comments_created: number = 0

  @Serialize({ to: false })
  public statistics_count_jobs_asked: number = 0

  @Serialize({ to: false })
  public statistics_count_jobs_created: number = 0

  // @Serialize({ to: false })
  // public statistics_count_likes_placed: number = 0

  // @Serialize({ to: false })
  // public statistics_count_likes_received: number = 0

  @Serialize({ to: false })
  public statistics_count_videos_created: number = 0

  // @Serialize({ to: false })
  // public statistics_count_wikis_created: number = 0

  // PRIVACY
  ////////////////////////////////////////////////////////////

  @InFormGroup()
  @Serialize()
  public private_personal_information_privacy: boolean = true

  @InFormGroup()
  @Serialize()
  public private_address_privacy: boolean = false

  @InFormGroup()
  @Serialize()
  public private_hobbies_privacy: boolean = false

  @InFormGroup()
  @Serialize()
  public private_social_information_privacy: boolean = false

  @InFormGroup()
  @Serialize()
  public private_about_me: boolean = false

  @InFormGroup()
  @Serialize()
  public private_career_privacy: boolean = false

  @InFormGroup()
  @Serialize()
  public use_nickname: boolean = false

  @InFormGroup()
  @Serialize()
  public private_city: string

  @InFormGroup()
  @Serialize()
  public private_country: string

  @InFormGroup(standardvalidators.birthday)
  @Serialize({
    from: (datestring_us:string) => {

      if(!datestring_us || datestring_us=='')
        return undefined

      let parts = datestring_us.split('-')

      return parts[2]+"."+parts[1]+"."+parts[0]
    },
    to: (datestring_de:string) => {
      
      // dont return null here -> or it will be deleted when parts of the user without birthday get serialized

      // dont return undefined here or birthday cant be emptied

      if(datestring_de && datestring_de!=''){
        let parts = datestring_de.split('.')
        return parts[2]+"-"+parts[1]+"-"+parts[0]
      }
      
      return datestring_de
    }
  })
  public private_date_of_birth: string

  @InFormGroup()
  @Serialize()
  public private_employer: string

  @InFormGroup()
  @Serialize({
    from:{
      map:(website:string)=>{
        return StaticHelper.prepareWebsite(website)
      }
    }
  })
  public private_facebook: string

  @InFormGroup()
  @Serialize()
  public private_favorite_area_of_work: string

  @InFormGroup()
  @Serialize()
  public private_favorite_construction_area: string

  @InFormGroup([Validators.maxLength(255)])
  @Serialize()
  public private_former_employers: string

  @InFormGroup()
  @Serialize()
  public private_gender: string

  @InFormGroup()
  @Serialize()
  public private_job: string

  @InFormGroup()
  @Serialize()
  public private_job_position: string

  @InFormGroup()
  @Serialize()
  public private_school: string

  @InFormGroup()
  @Serialize()
  public private_self_description: string

  @InFormGroup()
  @Serialize()
  public private_skills: string

  @InFormGroup()
  @Serialize()
  public private_state: string

  @InFormGroup()
  @Serialize()
  public private_street: string

  @InFormGroup()
  @Serialize()
  public private_street_additional: string

  @InFormGroup(standardvalidators.tel)
  @Serialize({
    to: (tel:string)=>{
      if(tel!=null)
        return tel.trim()
      return tel
    }
  })
  public private_telephone: string

  @InFormGroup()
  @Serialize({
    from:{
      map:(website:string)=>{
        return StaticHelper.prepareWebsite(website)
      }
    }
  })
  public private_website: string

  @InFormGroup()
  @Serialize()
  public private_zip: string

  @InFormGroup()
  @Serialize({
    from: {
      map: (raw: any, svc: SerializationService) => (raw ? svc.deserialize(CompanyProfile, raw) : raw),
      deps: [SerializationService],
    },
    to: {
      map: (company_profile: CompanyProfile, svc: SerializationService) => (
        company_profile ? svc.serialize(company_profile, CompanyProfile) : company_profile
      ),
      deps: [SerializationService],
    },
  })
  public company_profile: CompanyProfile | null = null

  @Serialize({ to: false })
  public certificate_verified:boolean = false
  public isCertified(){
    return this.certificate_verified
  }

  @Serialize()
  public notification_deactivated_bonus:boolean = false
  @Serialize()
  public notification_deactivated_comment:boolean = false
  @Serialize()
  public notification_deactivated_email:boolean = false
  @Serialize()
  public notification_deactivated_general:boolean = false

  @Serialize({
    from: {
      deps: [SerializationService],
      map: async (raw: any[], svc: SerializationService) => {
        const promises = raw.map(entry => deserializePostMapper(entry, svc));
        return Promise.all(promises);
      },
    },
    to: false,
  })
  posts?: Post[];

  public getName(){

    if(this.use_nickname)
      return this.private_nickname

    let s=''
    if(this.firstname)
      s+=this.firstname
    if(this.lastname)
      s+=(' '+this.lastname)
    return s
  }

  public getGmapsAddress():GMapAddress|null{
    console.error("TODO")
    return null
  }

  // used to decide which tabs / blocks are not filled and should be hidden
  public transformToDisplayStructure(){
    return {

      name:{
        firstname: this.firstname,
        lastname: this.lastname,
        private_nickname: this.private_nickname,
      },

      personal:{
        private_gender: this.private_gender,
        private_date_of_birth: this.private_date_of_birth,
      },

      social:{
        private_telephone: this.private_telephone,
        email: this.email,
        private_website: this.private_website,
        private_facebook: this.private_facebook,
      },

      address:{
        private_street: this.private_street,
        private_street_additional: this.private_street_additional,
        private_zip: this.private_zip,
        private_city: this.private_city,
        private_state: this.private_state,
        private_country: this.private_country,
      },

      career:{
        private_job: this.private_job,
        private_job_position: this.private_job_position,
        private_school: this.private_school,
        private_employer: this.private_employer,
        private_former_employers: this.private_former_employers,
      },

      private_self_description: this.private_self_description,
    }
  }

}
