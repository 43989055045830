<!-- TODO use in header as well -->
<button class="orange-button create-btn mobileGrowBtn" (click)="opened = !opened"
    (clickOutside)="opened = false">
    <i *ngIf="hasPlusIcon" class="fal fa-plus"></i>
    Beitrag erstellen
</button>

<!-- TODO fix position on mobile -->
<!-- global create button tooltip -->
<div *ngIf="opened" class="createTooltip">
    <div class="entry" (appLoginClickGuard)="StateService.goto('/blog/create',{},true,true)">
        <i class="fas fa-list-alt"></i>
        <div>Blog-Beitrag erstellen</div>
    </div>

    <div class="entry" (appLoginClickGuard)="StateService.goto(
          '/blog/create',
          {preselected:{category:CategoriesService.eventcat}},
          true,
          true
        )">
        <i class="far fa-calendar-check"></i>
        <div>Veranstaltung erstellen</div>
    </div>

    <div class="entry" (appLoginClickGuard)="StateService.goto(
          '/blog/create',
          {preselected:{category:CategoriesService.videocat}},
          true,
          true
        )">
        <i class="fas fa-video"></i>
        <div>Video veröffentlichen</div>
    </div>

    <div class="entry" (appLoginClickGuard)="StateService.goto('/market/create',{},true,true)">
        <i class="fa fa-euro-sign"></i>
        <div>Zimmereibedarf verkaufen</div>
    </div>

    <div class="entry" (appLoginClickGuard)="StateService.goto(
          '/market/create',
          {preselected:{advertisement_type:'SEARCH'}},
          true,
          true
        )">
        <i class="fas fa-shopping-cart"></i>
        <div>Zimmereibedarf suchen</div>
    </div>

    <div class="entry" (appLoginClickGuard)="StateService.goto('/job/create',{},true,true)">
        <i class="fas fa-user-plus"></i>
        <div>Stellenanzeige posten</div>
    </div>

    <div class="entry" (appLoginClickGuard)="StateService.goto('/job/create',{},true,true)">
        <i class="fas fa-search-plus"></i>
        <div>Stellengesuch posten</div>
    </div>
</div>
