import { Serialize, InFormGroup } from 'aautil'
import { Validators, AbstractControlOptions, FormGroup, ValidationErrors } from '@angular/forms'
import { standardvalidators } from '@configuration/standard-validators'
import { TypeService } from '@service/type.service'

function password_confirmation(group: FormGroup): ValidationErrors | null {
  const password = group.value.password
  const password_confirmation = group.value.password_confirmation
  if (password != password_confirmation) {
    return { passwordsDontMatch: true }
  }
  return null
}

export class RegistrationUser {
  formOptions: AbstractControlOptions = {
    validators: [password_confirmation],
  }

  @InFormGroup([Validators.required,Validators.minLength(2)])
  @Serialize()
  firstname: string

  @InFormGroup([Validators.required,Validators.minLength(2)])
  @Serialize()
  lastname: string

  @InFormGroup([Validators.required, standardvalidators.validEmail])
  @Serialize({
    to:{
      map:(s:string, typeService: TypeService)=>{
        if(typeService.isString(s))
          return s.trim()

        return s
      },
      deps: [TypeService]
    }
  })
  email: string

  @InFormGroup([Validators.required, Validators.minLength(6)])
  @Serialize()
  password: string

  @InFormGroup([Validators.required, Validators.minLength(6)])
  @Serialize({ from: false, to: false })
  password_confirmation: string

  @InFormGroup([Validators.required])
  @Serialize()
  privacy: boolean

  @InFormGroup()
  @Serialize()
  newsletter: boolean = false

  @Serialize()
  captcha: string
}
