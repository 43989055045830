<div fxLayout="column"
  fxLayoutAlign="center end">

  <!-- not logged in -> login / register buttons -->
  <div *ngIf="!userService.isLoggedIn"
    fxLayout="row"
    fxLayoutGap="10px">
    <button class="loginbtn"
      (click)="openDialog('login')">Einloggen</button>
    <button class="blue-button"
      (click)="openDialog('registration')">Registrieren</button>
  </div>

  <!-- logged in -> user image and nav menu -->
  <div *ngIf="userService.isLoggedIn"
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="5px"
    fxLayout.lt-md="column">

    <!-- user image -->
    <a class="unselectable"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
      fxHide.lt-lg
      routerLink="/profile/about"
      style="cursor: pointer; text-decoration: none;">
      <user-image [profile]="userService.user"></user-image>
      <div class="username">{{ userService.user.use_nickname ? userService.user.private_nickname :
        userService.user.firstname }}</div>
    </a>

    <!-- only desktop -->
    <!-- tooltip with personal menus -->
    <div style="position: relative; cursor: pointer;"
      (click)="clickedOnMenu()"
      (clickOutside)="onClickedOutside()"
      fxHide.lt-lg="true">

      <div class="chevron">
        <i class="fas fa-chevron-down fa-stack-1x"></i>
      </div>

      <div class="tooltip"
        *ngIf="showMenu">
        <a *ngFor="let item of navpoints; let i = index"
          class="entry"
          (click)="select(i)"
          [routerLink]="item.target"
          routerLinkActive="lightgray"
          fxLayout="row"
          fxLayoutGap="15px"
          style="cursor: pointer;">
          <div fxLayoutAlign="start center">
            <i *ngIf="item.icon_type == 'FA'"
              [ngClass]="item.icon"></i>
            <img *ngIf="item.icon_type == 'IMG'"
              style="height: 20px;"
              [src]="item.icon" />
          </div>
          <div class="text">{{ item.caption }}</div>
        </a>
      </div>

    </div>
  </div>

</div>