import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ConfirmDialogComponent } from '@modal/confirm-dialog/confirm-dialog.component'
import { LoginRegistrationDialogComponent } from '@modal/login-registration-dialog/login-registration-dialog.component'
import { FacebookDialogComponent } from '@modal/login-registration-dialog/facebook-dialog/facebook-dialog.component'
import { AppNagDialogComponent } from '@modal/app-nag-dialog/app-nag-dialog.component'

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private dialogRef

  constructor(
    private matDialog: MatDialog,
  ) { }

  public ensureDialog(text: string = 'Bist du dir sicher?') {
    return new Promise<boolean>((resolve, reject) => {

      const dialogConfig = {
        text,
        buttons: [
          {
            text: 'Nein',
            value: 'no',
          },
          {
            text: 'Ja',
            value: 'yes',
          },
        ],
      }
      const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
        data: dialogConfig,
        disableClose: true,
        panelClass: ConfirmDialogComponent.cssClass,
        maxHeight: '90vh' // has to be set for the dialog to be scrollable
      })
      dialogRef.afterClosed().subscribe(result => {

        if (result == 'no')
          resolve(false)
        else
          resolve(true)
      })

    })
  }

  public informDialog(text: string) {
    return new Promise<void>((resolve, reject) => {
      const dialogConfig = {
        text,
        buttons: [
          {
            text: 'OK',
            value: 'ok',
          },
        ],
      }
      const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
        data: dialogConfig,
        disableClose: true,
        panelClass: ConfirmDialogComponent.cssClass,
        maxHeight: '90vh' // has to be set for the dialog to be scrollable
      })
      dialogRef.afterClosed().subscribe(result => {
        resolve()
      })

    })
  }

  public loginRegistrationDialog(dialog: string = "login", clickGuarded: boolean = false) {
    this.dialogRef = this.matDialog.open(LoginRegistrationDialogComponent, {
      data: { dialog, clickGuarded },
      autoFocus: false,
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh', // has to be set for the dialog to be scrollable
      panelClass: LoginRegistrationDialogComponent.cssClass
    })
  }

  public showLoginDialog() {
    this.loginRegistrationDialog()
  }

  public showFacebookDialog() {
    this.dialogRef = this.matDialog.open(FacebookDialogComponent, {
      panelClass: FacebookDialogComponent.cssClass,
      autoFocus: false,
      maxHeight: '90vh' // has to be set for the dialog to be scrollable
    })
  }

  public closeAllDialogs() {
    this.dialogRef.close()
  }

  public showAppNagDialog() {
    this.dialogRef = this.matDialog.open(AppNagDialogComponent, {
      panelClass: AppNagDialogComponent.cssClass,
      autoFocus: false,
      maxHeight: '100vh', // has to be set for the dialog to be scrollable
      disableClose: true,
      position: {
        bottom: "bottom"
      }
    })
  }
}