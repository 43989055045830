import { ElementRef, EventEmitter, Injectable } from '@angular/core'
import $ from 'jquery'
import { EntityTypeConfig } from '@model/entity-type-config'

@Injectable({
  providedIn: 'root',
})
export class ScrollService {

  // hooks and variables needed to scroll back to initial position when transitioning like:
  /*
    list -> detail -> list
    list -> profile/businessprofile external -> list
  */
  private liststate: string = null
  private scrollPositionBefore: number = null

  public commentsElementRefSet: EventEmitter<void> = new EventEmitter()
  public commentsElementRef: ElementRef

  public writeCommentSet: EventEmitter<void> = new EventEmitter()
  public writeComment: ElementRef

  public privateMessageComponentSet: EventEmitter<void> = new EventEmitter()
  public privateMessageComponent: ElementRef

  public registerScrollToPositionBeforeHook(liststate: string, scrollPositionBefore: number) {

    this.resetScrollPositionHook()

    this.liststate = liststate
    this.scrollPositionBefore = scrollPositionBefore
  }

  public resetScrollPositionHook() {
    this.liststate = null
    this.scrollPositionBefore = null
  }

  public restoreHookedScrollPosition() {
    this.scrollToPx(this.scrollPositionBefore)
    this.resetScrollPositionHook()
  }

  /*
    This function checks if we go back to a liststate.
    i.e. if list->detail->list is being done
  */
  public hookmatch(e: EntityTypeConfig): boolean {

    if (e.liststate == this.liststate && this.scrollPositionBefore !== null)
      return true

    return false
  }

  public scrollToTop(): void {

    // MATDIALOG prevents this!

    // normal:
    // document.documentElement -> html
    // document.body -> body

    // when matdialog opened:
    // document.documentElement -> html.cdk-global-scrollblock
    // document.body -> body

    $([document.documentElement, document.body]).animate({
      scrollTop: 0
    }, 0)
  }

  public smoothScrollToTop(ms: number = 100): void {
    $([document.documentElement, document.body]).animate({
      scrollTop: 0,
    }, ms,)
  }

  public scrollToSelector(selector: string, offset: number = 0, scrollToStart: boolean = false): void {

    // console.log("scroll to", selector, "length", $(selector).length)

    if ($(selector).length) {

      // scroll to top of object
      if (scrollToStart) {

        setTimeout(() => {
          $([document.documentElement, document.body]).animate(
            {
              scrollTop: $(selector).offset().top + offset,
            },
            1,
          )
        }, 10);

      }

      // try to center object - standard case
      else {

        let screenheight = $(window).height()
        let element_height = $(selector).outerHeight()

        let diff = element_height - screenheight

        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $(selector).offset().top + diff / 2 + offset,
          },
          1,
        )
      }
    } else {
      console.error('Tried scrolling to an element that does not exist. JQuery Selector: ' + selector)
    }

  }

  public scrollToComments() {
    if (!this.writeComment) {
      console.error("tried scrollToComments but this.writeComment not yet ready")
      return
    }

    this.commentsElementRef.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  public scrollToWriteComment() {

    if (!this.writeComment) {
      console.error("tried scrollToWriteComment but this.writeComment not yet ready")
      return
    }

    this.writeComment.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  public scrollToWritePrivateMessage() {
    if (!this.privateMessageComponent) {
      console.error("tried scrollToWritePrivateMessage but this.privateMessageComponent not yet ready")
      return
    }
    this.privateMessageComponent.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  public scrollToPx(px: number, smooth: boolean = false) {

    let time = 1
    if (smooth)
      time = 300

    $([document.documentElement, document.body]).animate(
      { scrollTop: px }, time,
    )
  }

  public getCurrentScroll(): number {
    const scrollTop = $(document).scrollTop()
    if (scrollTop !== undefined) {
      return scrollTop
    }
    return 0
  }

}
