import { Injectable } from '@angular/core'
import { DomSanitizer, SafeStyle } from '@angular/platform-browser'
import { Url } from '@model/url'

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    private domSanitizer: DomSanitizer,
  ) { }

  public trustStyleURL(url: string): SafeStyle {
    return this.domSanitizer.bypassSecurityTrustStyle(`url("${url}")`)
  }

  public trustUrl(url: string) {

    if (url == null)
      return ''

    return this.domSanitizer.bypassSecurityTrustResourceUrl(url)
  }

  public static definedAndSet(a) {
    return typeof a != "undefined" && a != null && a != ""
  }

  /*
    goal:
    url.startsWith('https://www.youtube.com/embed/'
  */
  public transformVideoUrl(url: string): string {

    if (url == null || url == '')
      return null

    let urlO = new Url(url)

    /*
    https://www.youtube.com/watch?v=j-Y1Ot9z770
    to
    https://www.youtube.com/embed/j-Y1Ot9z770
    */
    if (url.startsWith('https://www.youtube.com/watch?v=')) {

      let newParams = urlO.getParams
      let ytid = newParams["v"]
      delete newParams["v"]

      urlO.getParams = newParams
      urlO.path = "/embed/" + ytid
    }

    /*
    https://m.youtube.com/watch?v=j-Y1Ot9z770
    to
    https://www.youtube.com/embed/j-Y1Ot9z770
    */
    else if (url.startsWith('https://m.youtube.com/watch?v=')) {

      let newParams = urlO.getParams
      let ytid = newParams["v"]
      delete newParams["v"]

      urlO.host = "www.youtube.com"
      urlO.getParams = newParams
      urlO.path = "/embed/" + ytid
    }

    /*
    https://youtu.be/za6HOKZakNU
    to
    https://www.youtube.com/embed/za6HOKZakNU
    */
    else if (url.startsWith('https://youtu.be/')) {

      let ytid = urlO.path.substring(1)

      urlO.host = "www.youtube.com"
      urlO.path = "/embed/" + ytid
    }

    // its a vimeo video
    else if (url.startsWith('https://vimeo.com/') ||
      url.startsWith('https://player.vimeo.com/video/')) {

      /*
      https://vimeo.com/197762063
      to
      https://player.vimeo.com/video/197762063
      */
      if (url.startsWith('https://vimeo.com/')) {
        urlO.host = "player.vimeo.com"
        urlO.path = "/video" + urlO.path
      }

      let params = urlO.getParams
      params["transparent"] = "0"
      urlO.getParams = params
    }

    return urlO.toString()
  }

}