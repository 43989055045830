import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { DialogType } from '@type/dialog-type'
import { NotificationService } from '@service/notification.service'

@Component({
  selector: 'notifications-dialog',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss'],
})
export class NotificationsDialogComponent {
  public static cssClass: DialogType = "black-fullscreen-dialog"

  constructor(
    public dialogRef: MatDialogRef<NotificationsDialogComponent>,
    public notificationService: NotificationService,
  ) {
  }

  public close() {
    this.dialogRef.close()
  }
}


