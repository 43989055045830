import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { Injectable } from '@angular/core'
import { catchError } from 'rxjs/operators'
import { UserService } from '@service/user/user.service'
import { ScrollService } from '@service/scroll.service'
import { StateService } from '@service/state.service'
import { DialogService } from '@service/dialog.service'

@Injectable()
export class MainHttpInterceptor implements HttpInterceptor {

  constructor(
    private userService: UserService,
    private scrollService: ScrollService,
    private StateService: StateService,
    private DialogService: DialogService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            this.handleServersideErr(error)
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
          console.error(errorMessage)
          return throwError(errorMessage);
        })
      )
  }

  private handleServersideErr(response: HttpErrorResponse) {
    if (response.status == 403)
      this.handle403(response)
  }

  // for us this means probably user token expired
  private handle403(response: HttpErrorResponse) {
    this.userService.deleteLocalUser()
    this.StateService.goto('/news')
    this.DialogService.showLoginDialog()
    this.scrollService.scrollToTop()
  }

}