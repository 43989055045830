import { FormGroup } from '@angular/forms'
import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'agb-newsletter',
  templateUrl: './agb-newsletter.component.html',
  styleUrls: ['./agb-newsletter.component.scss'],
})
export class AgbNewsletterComponent {

  @Input()
  public darklinks: boolean = false

  @Input() form: FormGroup

  @Input() submitted: boolean

  update(formGroup: FormGroup) {
    this.form = formGroup
    this.formChange.emit(this.form)
  }

  @Output()
  formChange = new EventEmitter()
}