import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private cache = {}

  constructor() { }

  public getByIndex(index){
    if(typeof this.cache[index] != "undefined")
      return this.cache[index]
    
    return null
  }

  public setIndex(index,value){
    this.cache[index] = value
  }

}