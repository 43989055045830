<form [formGroup]="form"
  (change)="update(form)"
  style="margin-top: 10px;">

  <div fxLayout="row"
    fxLayoutGap="10px"
    style="padding-bottom: 10px;">
    <div>
      <mat-checkbox color="primary"
        formControlName="privacy">
      </mat-checkbox>
    </div>
    <div fxFlex="grow"
      [class.darklinks]="darklinks">
      Ja, ich stimme den <a href="/peripherals/agb"
        target="_blank">AGB´s</a> und <a href="/peripherals/privacy"
        target="_blank">Datenschutzbestimmungen</a> zu.
    </div>
  </div>

  <mat-error *ngIf="(form.controls.privacy.touched || submitted) && form.controls.privacy.errors">
    Bitte stimme unseren AGBs und Datenschutzbestimmungen zu
  </mat-error>

  <div fxLayout="row"
    fxLayoutGap="10px"
    style="padding-bottom: 10px;">
    <div>
      <mat-checkbox color="primary"
        formControlName="newsletter">
      </mat-checkbox>
    </div>
    <div fxFlex="grow">
      Ja, ich möchte per Email neue Zimmerer-Themen vom Zimmerer-Treffpunkt. Ich kann diese jederzeit wieder
      abbestellen.
    </div>
  </div>

</form>