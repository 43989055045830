import { Component, OnInit } from '@angular/core'
import { ScrollService } from '@service/scroll.service'
import { StateService } from '@service/state.service'

@Component({
    selector: 'image-slider-bar',
    templateUrl: './image-slider-bar.component.html',
    styleUrls: ['./image-slider-bar.component.scss']
})

export class ImageSliderBarComponent implements OnInit {
    constructor(
        public StateService: StateService,
        public ScrollService: ScrollService,
    ) { }

    ngOnInit(): void { }

    public goto(state) {
        this.StateService.goto(state)
            .then(() => {
                this.ScrollService.scrollToTop()
            })
    }
}
