import { Injectable } from '@angular/core'
import { AtomarDisplayUnit } from '@model/atomar-display-unit'
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TypeService {

  constructor() { }

  public isUndefined (value) {
    return typeof value === 'undefined'
  }

  public isNull (value) {
    return value === null
  }

  public isString (value) {
    return typeof value === 'string' || value instanceof String
  }

  public isNumber (value) {
    return typeof value === 'number' && isFinite(value)
  }

  public isArray (value) {
    return value && typeof value === 'object' && value.constructor === Array
  }

  public isFunction (value) {
    return typeof value === 'function'
  }

  public isObject (value) {
    return value && typeof value === 'object' && value.constructor === Object
  }

  public isBoolean (value) {
    return typeof value === 'boolean'
  }

  public isDate (value) {
    return value instanceof Date || moment.isMoment(value)
  }

  /*
    this cryptic method determines if a sub part of a profile
    be it a user or a company profile
    is filled and should be displayed in the external profile view
  */
  public profileObjectFilled(obj):boolean{

    if(this.isUndefined(obj) || this.isNull(obj))
      return false

    if(obj instanceof AtomarDisplayUnit)
      return true

    if(this.isString(obj) && obj!="")
      return true

    if(this.isNumber(obj))
      return true

    if(this.isBoolean(obj))
      return true

    if(this.isDate(obj))
      return true

    if(this.isArray(obj)){

      if(obj.length==0)
        return false

      for(let i of obj){
        if(this.profileObjectFilled(i))
          return true
      }
    }

    if(this.isObject(obj)){
      let result=false
      for(var key in obj) {
        if (obj.hasOwnProperty(key)) {
          if(this.profileObjectFilled(obj[key]))
            result=true
        }
      }
      return result
    }

    if(this.isFunction(obj))
      return true

    // only for the compiler - should theoretically never be reached
    console.error("THIS SHOULD NEVER BE REACHED")
    return false
  }
}
