<div class="first-row">



  <!-- footer logo column -->


  <div class="footer-logo">
    <img src="/build/assets/images/logo.svg" alt="Zimmerer Logo">
  </div>



  <!-- footer text column -->

  <div class="text-container">

    <p>
      {{footerFirstText}}
    </p>

    <button class="blue-button mobileGrowBtn" appLoginClickGuard *ngIf="!userService.isLoggedIn; else createPanel">
      Jetzt Mitglied werden
    </button>

    <p>
      {{footerSecondText}}
    </p>



    <!-- TODO reuse the create-panel or create-entry-button component if possible -->
    <!-- TODO move to own reusable component / then use in header as well -->

    <ng-template #createPanel>

      <div style="position: relative;">

        <button class="orange-button create-btn mobileGrowBtn"
          (click)="isCreateTooltipVisible ? FooterService.hideCreateTooltipNow() : FooterService.showCreateTooltipNow()"
          (clickOutside)="FooterService.hideCreateTooltipNow()">
          Beitrag erstellen
        </button>

        <!-- global create button tooltip -->
        <div *ngIf="isCreateTooltipVisible" class="createTooltip">

          <div class="entry" (appLoginClickGuard)="StateService.goto('/blog/create',{},true,true)">
            <i class="fas fa-list-alt"></i>
            <div>Blog-Beitrag erstellen</div>
          </div>

          <div class="entry" (appLoginClickGuard)="StateService.goto(
                '/blog/create',
                {preselected:{category:CategoriesService.eventcat}},
                true,
                true
              )">
            <i class="far fa-calendar-check"></i>
            <div>Veranstaltung erstellen</div>
          </div>

          <div class="entry" (appLoginClickGuard)="StateService.goto(
                '/blog/create',
                {preselected:{category:CategoriesService.videocat}},
                true,
                true
              )">
            <i class="fas fa-video"></i>
            <div>Video veröffentlichen</div>
          </div>

          <div class="entry" (appLoginClickGuard)="StateService.goto('/market/create',{},true,true)">
            <i class="fa fa-euro-sign"></i>
            <div>Zimmereibedarf verkaufen</div>
          </div>

          <div class="entry" (appLoginClickGuard)="StateService.goto(
                '/market/create',
                {preselected:{advertisement_type:'SEARCH'}},
                true,
                true
              )">
            <i class="fas fa-shopping-cart"></i>
            <div>Zimmereibedarf suchen</div>
          </div>

          <div class="entry" (appLoginClickGuard)="StateService.goto('/job/create',{},true,true)">
            <i class="fas fa-user-plus"></i>
            <div>Stellenanzeige posten</div>
          </div>

          <div class="entry" (appLoginClickGuard)="StateService.goto('/job/create',{},true,true)">
            <i class="fas fa-search-plus"></i>
            <div>Stellengesuch posten</div>
          </div>

        </div>

      </div>

    </ng-template>




    <div class="bonus-system-link" (click)="goto('/boni/faq')">
      Mehr zum Bonussystem
    </div>


  </div>







  <!-- useful link column -->

  <ul class="footer-links-container">
    <li (click)="goto('/home')">Deine Vorteile</li>
    <li *ngIf="userService.isLoggedIn" (click)="goto('/profile/business')">Unternehmen</li>

    <li *ngIf="!userService.isLoggedIn" (click)="goto('/business')">Unternehmen</li>

    <li (click)="goto('/peripherals/about')">Über uns</li>
    <li (click)="goto('/peripherals/career')">Karriere</li>
    <li (click)="goto('/peripherals/contact')">Kontakt</li>
  </ul>



  <!-- social media column -->
  <div class="social-media-icons-container">
    <h4>Folge uns!</h4>


    <a [href]="socialMediaNumber.link" class="social-media-icons-item"
      *ngFor="let socialMediaNumber of socialMediaNumbers">

      <i *ngIf="socialMediaNumber.icon" [class]="socialMediaNumber.icon"></i>
      <img *ngIf="socialMediaNumber.image" [src]="socialMediaNumber.image" />

      <span class="social-media-icons-item-text">
        {{socialMediaNumber.number}} {{socialMediaNumber.who}}
      </span>
    </a>
  </div>



</div>



<div class="second-row">



  <ul>
    <li (click)="goto('/peripherals/agb')">AGB</li>
    <li (click)="goto('/peripherals/privacy')">Datenschutz</li>
    <li (click)="goto('/peripherals/impressum')">Impressum</li>
  </ul>

  <div class="copyright">&copy; {{getCurrentYear()}} Zimmerer-Treffpunkt</div>

</div>