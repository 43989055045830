import { Injectable } from '@angular/core'
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { ScrollService } from './scroll.service'
import { UserService } from './user/user.service'
import { Title } from '@angular/platform-browser'
import { DialogService } from './dialog.service'

@Injectable({
  providedIn: 'root'
})
export class StateService {

  public stateDataChanged: BehaviorSubject<Data> = new BehaviorSubject<Data>(null)
  public currentRouteData: Data

  /*
    these are params like /detail/:id/
    they have to be set by the route root component in the constructor (not ngoninit) like that:
    
    this.ActivatedRoute.params.subscribe(params => {
      this.StateService.params = params
    })

    found no way yet to do that generic
  */
  public params

  /*
    these are params like ?filter=true&test=test
    they have to be set by the route root component in the constructor (not ngoninit) like that:
    
    this.ActivatedRoute.queryParams.subscribe(queryParams => {
      this.StateService.queryParams = queryParams
    })

    found no way yet to do that generic
  */
  public queryParams

  constructor(
    private Router: Router,
    private UserService: UserService,
    private DialogService: DialogService,
    private ScrollService: ScrollService,
    public ActivatedRoute: ActivatedRoute,
    public Title: Title
  ) {

    this.Router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {

        let route: ActivatedRoute = this.Router.routerState.root
        while (route!.firstChild) {
          route = route.firstChild
        }

        if (route.snapshot.data) {
          if (route.snapshot.data.title)
            this.Title.setTitle(route.snapshot.data.title + ' | Zimmerer-Treffpunkt')

          this.currentRouteData = route.snapshot.data
        }
        else
          this.currentRouteData = null


        this.stateDataChanged.next(route.snapshot.data!)
      }
    })
  }

  /*
    extras is data that can be passed to a route that will not appear in the url
  */
  public goto(url: string, extras = {}, scrollToTop: boolean = true, authguarded: boolean = false) {
    return new Promise((resolve, reject) => {

      if (authguarded && !this.UserService.isLoggedIn) {
        this.DialogService.loginRegistrationDialog()
        resolve(null)
        return
      }

      if (scrollToTop) {
        setTimeout(() => {
          //console.log("SCROLLING TO TOP")
          this.ScrollService.scrollToTop()
        }, 100)
      }

      this.Router.navigateByUrl(url, { state: extras })
        .then(() => {
          resolve(null)
          return
        })
    })
  }

  public reload() {
    this.Router.navigateByUrl(window.location.pathname)
  }
}