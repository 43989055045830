<div class="dialog"
  fxLayout="column"
  fxLayoutGap="24px"
  style="font-weight: bold;">

  <div>
    Zimmerer-Treffpunkt benutzen in...
  </div>

  <div fxLayout="column"
    fxLayoutGap="10px">

    <a [href]='MobileService.getStoreLink()'
      style="text-decoration: none; color: white;">
      <div fxLayout="row"
        fxLayoutAlign="start center">
        <div fxFlex="70"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="5px">
          <div><img src="/build/assets/images/logo.svg"
              alt=""
              style="height: 30px;"></div>
          <div>Zimmerer-Treffpunkt App</div>
        </div>
        <div fxFlex="30"
          fxLayout="row"
          fxLayoutAlign="center center">
          <button class="orange-button">öffnen</button>
        </div>
      </div>
    </a>

    <div fxLayout="row"
      fxLayoutAlign="start center"
      (click)="close()">
      <div fxFlex="70"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="5px">
        <div><img src="/build/assets/icons/FirefoxLogo.svg"
            alt=""
            style="height: 30px;">
        </div>
        <div>Browser</div>
      </div>
      <div fxFlex="30"
        fxLayout="row"
        fxLayoutAlign="center center">
        <button>fortfahren</button>
      </div>
    </div>

  </div>

</div>