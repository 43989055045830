<mat-dialog-content>
  <div class="dialog"
    fxLayout="column"
    fxLayoutAlign="start center"
    fxFlex="grow">

    <!-- close x -->
    <i (click)="close()"
      class="closex fas fa-times"></i>

    <div class="dialog-content"
      fxLayout="column"
      fxFlex="grow"
      fxLayoutAlign="space-between">

      <div class="logo"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxFlex="grow">
        <img [src]="environment.publicFolder+'assets/images/logo.svg'"
          alt="Zimmerer-Treffpunkt Logo">
      </div>

      <app-sso-login-buttons></app-sso-login-buttons>

      <lines-word [word]="'oder'"
        [lines_color]="'white'"
        [text_color]="'white'"></lines-word>

      <form [formGroup]="loginForm"
        (ngSubmit)="onSubmit()"
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="10px">

        <div fxLayout="column"
          fxLayoutGap="10px"
          fxLayout.gt-md="row">
          <input fxFlex.gt-md="grow"
            matInput
            formControlName="email"
            type="email"
            required
            placeholder="E-Mail-Adresse" />
          <input fxFlex.gt-md="grow"
            matInput
            formControlName="password"
            type="password"
            required
            placeholder="Passwort" />
        </div>

        <!-- login button -->
        <button class="desktopGrowBtn mobileGrowBtn orange-button"
          [disabled]="loading || loginForm.invalid">
          Anmelden
        </button>

        <button class="desktopGrowBtn mobileGrowBtn transparent-orange-button"
          type="button"
          (click)="forgotPw()">
          Passwort zurücksetzen
        </button>
      </form>



      <div fxLayout="column"
        fxLayoutAlign="center center"
        fxFlex="grow">

        <button class="mobileGrowBtn registerBtn"
          type="button"
          (click)="switch('registration')">
          Neues Konto erstellen
        </button>

        <button class="mobileGrowBtn white-button-mini-font"
          type="button"
          (click)="switch('fb')">
          Bereits über Facebook registriert?
        </button>
      </div>

      <button class="desktopGrowBtn mobileGrowBtn"
        style="font-weight: 100; font-size: 10px; color: #777; margin: 0px; padding: 0px; min-height: unset;"
        type="button"
        (click)="switch('agb')">
        Nutzungsbedingungen und Impressum
      </button>

    </div>

  </div>
</mat-dialog-content>