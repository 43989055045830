<zimmerer-route-ion-wrapper class="ion-page">

  <div class="threecol"
    fxLayout="column"
    fxLayoutAlign.gt-md="center center">
    <div class="main-col noSlider"
      fxLayout="column"
      fxLayoutGap="20px">

      <h2>Nutzungsbedingungen</h2>

      <ol>

        <li>
          <b>Geltungsbereich und Begriffsbestimmungen</b>
          <ol>
            <li>
              Für die Nutzung des Angebots www.zimmerer-treff.com (im Folgenden kurz "Webseite"), welches durch die
              Zimmerer-Treffpunkt GmbH mit Sitz Ziegelweg 5 94405 Landau an der Isar (im Folgenden kurz "Anbieter"
              genannt)
              betrieben wird, durch den Nutzer (im Folgenden kurz "Nutzer" genannt) gelten diese Nutzungsbedingungen.
            </li>
            <li>
              Widersprechende, abweichende oder ergänzende Nutzungsbedingungen des Nutzers werden nicht
              Vertragsbestandteil,
              es sei denn der Anbieter stimmt deren Geltung ausdrücklich zu. Diese Nutzungsbedingungen gelten auch dann,
              wenn der Anbieter in Kenntnis entgegenstehender oder von diesen Nutzungsbedingungen abweichenden Bedingungen
              des Nutzers Leistungen vorbehaltlos ausführt.
            </li>
          </ol>
        </li>

        <li>
          <b>Webseite</b>
          <ol>
            <li>
              Der Anbieter betreibt die Webseite, eine Online-Community für Zimmerer (Bezeichnung bezieht sich auf
              Personen
              beiderlei Geschlechts) und bietet auf der Webseite unter anderem einen Blog, einen Wissensbereich, eine
              Jobbörse, eine (Marktplatz-)Plattform, Videos sowie ein Forum an. Der vorstehende Umfang der Webseite ist
              nicht abschließend und kann jederzeit durch den Anbieter geändert werden.
            </li>
            <li>Die Webseite ist in begrenztem Umfang ohne Registrierung nutzbar; umfangreichere Funktionen und Inhalte
              der
              Webseite sind nur nach erfolgreich durchgeführter Registrierung nutzbar.</li>
            <li>Der Anbieter stellt Teile der Webseite unter anderem als Plattform zur Verfügung und hat insoweit auf die
              erstellten Inhalte keinen Einfluss. Der Anbieter übernimmt insoweit auch keine Haftung und/oder Prüfung der
              hochgeladenen bzw. durch Nutzer erstellten Inhalte.</li>
            <li>Der Anbieter behält sich jedoch das Recht vor, die Inhalte des Nutzers vor Veröffentlichung auf der
              Webseite
              – insbesondere auf etwaige Rechtsverletzungen hin – zu prüfen und eine Veröffentlichung im Einzelfall ohne
              Angabe von Gründen zu versagen. Der Nutzer hat insoweit ausdrücklich kein Recht auf Veröffentlichung seines
              Profils und seiner Beiträge auf der Webseite.</li>
            <li>Die Nutzung der Webseite ist für Zimmerer kostenlos. Damit diese auch weiterhin kostenlos angeboten werden
              kann, geht der Anbieter u.a. Kooperationen und/oder Werbevereinbarungen mit Zulieferern der Branche ein.
              Soweit erforderlich, stimmt der Nutzer der Einbindung von Werbeanzeigen mit Nutzung der Webseite zu.</li>
          </ol>
        </li>

        <li>
          <b>Nutzung und Registrierung</b>
          <ol>
            <li>
              Der Nutzer hat die Möglichkeit, sich zu registrieren. Die Registrierung erfolgt unter Zustimmung zu diesen
              Nutzungsbedingungen sowie der Datenschutzerklärung und erfordert die wahrheitsgemäße Angabe der geforderten
              Daten. Eine Anmeldung im Namen eines Dritten – unabhängig von dessen Einverständnis – sowie die
              Mehrfachanmeldung eines Nutzers unter verschiedenen Namen sind nicht zulässig. Der Anbieter behält sich die
              Überprüfung der bei der Registrierung angegebenen Daten durch geeignete Maßnahmen ausdrücklich vor. Mit
              Bestätigung der Registrierung durch den Anbieter kommt zwischen Anbieter und dem Nutzer ein Vertrag über die
              Nutzung der Webseite (nachfolgend "Nutzungsvertrag" genannt) zustande. Die Registrierung erfordert das
              Mindestalter des Nutzers von 14 Jahren. Ein Anspruch auf Abschluss eines Nutzungsvertrags besteht nicht.
            </li>
            <li>Der Nutzer muss seine Zugangsdaten, insbesondere das von ihm gewählte Passwort geheim halten und jeglichen
              Zugang zur Webseite durch Dritte durch geeignete Maßnahmen verhindern. Der Nutzer ist verpflichtet, den
              Anbieter umgehend zu informieren, wenn Anhaltspunkte dafür ersichtlich sind, dass die Zugangsdaten
              unberechtigt verwendet werden können. Der Nutzer haftet für etwaigen Missbrauch.</li>
          </ol>
        </li>

        <li>
          <b>Verhalten des Nutzers</b>
          <ol>
            <li>
              Der Nutzer ist dafür verantwortlich, dass die von ihm veröffentlichten Inhalte keine Rechte Dritter
              verletzen
              sowie nicht gegen gesetzliche Vorschriften (z.B. Strafgesetzbuch) und/oder die guten Sitten verstoßen.
              Unzulässig sind insbesondere – jedoch nicht abschließend – verfassungswidrige, volksverhetzende,
              verleumderische, rassistische und/oder kinderpornografische Inhalte und/oder Äußerungen. Anbieter behält
              sich
              – unbeschadet der Rechte nach Ziff. 6 – ein strafrechtliches Vorgehen gegen den Nutzer in diesen Fällen
              ausdrücklich vor.
            </li>
            <li>
              Insbesondere folgendes Verhalten durch den Nutzer ist untersagt:
              <ul>
                <li>Werbekommunikationen (u.a. durch Verbreitung von Telefonnummern, Mailadressen, Links, SMS-Nummern oder
                  sonstigen Kontaktmöglichkeiten zwecks kommerziellen Kontakts zu anderen Mitgliedern), die über das
                  übliche
                  Maß in Form und/oder Inhalt hinausgeht;</li>
                <li>die Versendung von Spam;</li>
                <li>Abwerbeversuche, mit denen dritte Nutzer auf andere Portale/Websites gelockt werden sollen;</li>
                <li>Inhalte oder Informationen von Nutzern mit Hilfe automatischer Mechanismen (wie Bots, Roboter, Spider
                  oder Scraper) zu erfassen;</li>
                <li>einen Code hochzuladen, der dem Anbieter oder der Webseite schadet oder in der Funktionalität
                  einschränkt oder in sonstiger Weise beeinflusst;</li>
                <li>fremde Anmeldeinformationen einzuholen oder auf ein Konto zuzugreifen, das einem anderen Nutzer
                  gehört;
                </li>
                <li>andere Nutzer und/oder Dritte zu beleidigen, verleumden, tyrannisieren, einzuschüchtern oder zu
                  schikanieren;</li>
                <li>Inhalte zu veröffentlichen, die gegen geltendes Recht verstoßen, verabscheuungswürdig oder bedrohlich
                  sind oder zu Gewalt auffordern oder Gewalt enthalten;</li>
                <li>die Webseite zu verwenden, um rechtswidrige, irreführende, bösartige oder diskriminierende Handlungen
                  durchzuführen;</li>
                <li>Handlungen durchzuführen, die das einwandfreie Funktionieren der Webseite blockieren, überlasten oder
                  beeinträchtigen könnten;</li>
                <li>sonstigen Missbrauch der Dienste von Anbieter.</li>
              </ul>
            </li>
            <li>Bei Verstoß gegen die Regelungen dieser Nutzungsbedingungen, insbesondere die vorstehenden Verpflichtungen
              ist der Anbieter ohne weitere Benachrichtigung insbesondere zur sofortigen vorübergehenden oder dauerhaften
              Sperrung des Zugangs, Löschung der jeweiligen Inhalte und Ausschluss des Nutzers von der Webseite durch
              außerordentliche Kündigung des Nutzungsvertrags berechtigt. Die Geltendmachung weiterer Ansprüche,
              insbesondere eines Schadensersatzanspruchs behält sich Anbieter ausdrücklich vor.</li>
            <li>Verstöße gegen diese Bedingungen (z.B: Diskriminierung, Urheberrechtsverletzungen etc.) können jederzeit
              per
              Email info@zimmerer-treff.com gemeldet werden.</li>
          </ol>
        </li>

        <li>
          <b>Nutzungsrechte</b>
          <ol>
            <li>Der Nutzer räumt dem Anbieter an den hochgeladenen Inhalten und zur Verfügung gestellten Informationen,
              Texten, Bildern etc. sämtliche nicht-exklusiven, örtlich, zeitlich und inhaltlich unbeschränkten Rechte zur
              Nutzung ein. Die Rechteübertragung ist unabhängig von dem Bestehen oder Nicht-Bestehen des Nutzungsvertrags
              und gilt insbesondere auch nach Ablauf dessen fort. Der Anbieter ist insbesondere zur Vervielfältigung,
              Verbreitung, Bearbeitung, Bewerbung und öffentlichen Zugänglichmachung der Inhalte berechtigt. Der Anbieter
              hat das Recht, Inhalte innerhalb seiner Webseite zu verschieben, mit anderen Inhalten zu verbinden und die
              Rechte hieran Dritten zu übertragen.</li>
            <li>Der Nutzer garantiert, dass die hochgeladenen Inhalte und zur Verfügung gestellten Informationen, Texte,
              Bilder etc. keine Rechte Dritter verletzen und stellt den Anbieter von jedweden diesbezüglichen Ansprüchen
              auf
              erste Anforderung frei, (einschließlich etwaiger Rechtsanwaltskosten bei Rechteverteidigung durch den
              Anbieter). Sonstige Ansprüche des Anbieters bleiben unberührt.</li>
          </ol>
        </li>

        <li>
          <b>Sperrung</b>
          <ol>
            <li>
              Soweit ein Nutzer gegen die ihm obliegenden Pflichten gem. Ziff. 4 verstößt, hat Anbieter insbesondere
              folgende Rechte:
              <ul>
                <li>Löschung der jeweiligen Inhalte,</li>
                <li>Sperrung des Nutzers.</li>
              </ul>
              Das Recht zur Änderung von Inhalten bleibt unberührt.
            </li>
          </ol>
        </li>

        <li>
          <b>Vertragslaufzeit, Kündigung</b>
          <ol>
            <li>Die Vertragslaufzeit des Nutzungsvertrages ist unbegrenzt.</li>
            <li>Der Nutzungsvertrag kann von beiden Parteien jederzeit gekündigt werden.</li>
          </ol>
        </li>

        <li>
          <b>Jobbörse</b>
          <ol>
            <li>Der Anbieter bietet auf seiner Webseite eine Jobbörse an, über die der Nutzer als Job-Angebote und
              -Gesuche
              erstellen und veröffentlichen sowie veröffentlichte Angebote anderer Nutzer einsehen kann.</li>
            <li>Der Anbieter behält sich vor, Stellengebote und Stellenanzeigen offline zu stellen oder abzulehnen,
              insbesondere wenn sie die unter Ziff. 8.2. definierten Aufnahmerichtlinien verletzen oder rechtswidrig sind
              (z.B. aufgrund Verstoßes gegen das Allgemeine Gleichbehandlungsgesetz (AGG)).</li>
            <li>
              Unbeschadet von Ziff. 8.1 sind folgende Inhalte untersagt:
              <ul>
                <li>Stellenanzeigen, die nicht in deutscher Sprache verfasst sind.</li>
                <li>Einbindung von Stellenanzeigen, die auf dritten Jobbörsen gehostet sind.</li>
              </ul>
            </li>
          </ol>
        </li>

        <li>
          <b>Plattform</b>
          <ol>
            <li>Der Anbieter bietet auf seiner Webseite eine Plattform an, über die der Nutzer als Inserent Angebote und
              Gesuche, die Bilder und/oder Texte beinhalten, erstellen und veröffentlichen sowie veröffentlichte Anzeigen
              anderer Nutzer einsehen kann. Darüber hinaus ermöglichen die Anbieter-Dienste auch den Austausch
              elektronischer Nachrichten zwischen Inserenten und Interessenten.</li>
            <li>Der Nutzer ist ferner verpflichtet, bei der Aufgabe einer Anzeige anzugeben, ob er die Anzeige privat oder
              in Ausübung einer gewerblichen oder selbständigen beruflichen Tätigkeit veröffentlicht.</li>
            <li>Der Marktplatz dient dem Veröffentlichen von Anzeigen und dem Zusammenführen von Anbietern und
              Interessenten
              der jeweiligen, eingestellten Waren und Dienstleistungen. Der Anbieter ist selbst jedoch nicht Anbieter der
              jeweils mit den Anzeigen beworbenen Produkte.</li>
            <li>Nutzer, die den Marktplatz als gewerblicher Anbieter oder sonst geschäftsmäßig nutzen, unterliegen
              besonderen gesetzlichen Regelungen. Sie sind u. a. dazu verpflichtet, die gesetzlichen Informationspflichten
              zu erfüllen und dazu insbesondere eine vollständige, den gesetzlichen Anforderungen genügende
              Anbieterkennzeichnung anzugeben.</li>
            <li>Unter Umständen hat der Anbieter aufgrund gesetzlicher Vorgaben bestimmte Daten aufzuzeichnen, dies können
              etwa sein: Name, Anschrift, Steuernummer, Umsatzsteuer-ID, Höhe des Umsatzes, Versendungsort,
              Bestimmungsort,
              etc.</li>
            <li>Die auf dem Marktplatz veröffentlichten Anzeigen und sonstigen Inhalte von Nutzern geben nicht die Meinung
              des Anbieters wieder und werden von dem Anbieter nicht auf ihre Rechtmäßigkeit, Richtigkeit und
              Vollständigkeit überprüft. Der Anbieter übernimmt keine Gewähr für die Richtigkeit und Vollständigkeit der
              in
              den Anzeigen enthaltenen Angaben und auch keine Gewähr für die Qualität, Sicherheit oder Rechtmäßigkeit der
              von Nutzern angebotenen Waren oder Dienstleistungen.</li>
          </ol>
        </li>

        <li>
          <b>Verfügbarkeit des Angebots</b>
          <ol>
            <li>Der Anbieter strebt eine hohe Verfügbarkeit der Webseite an. Der Nutzer ist sich jedoch darüber im Klaren,
              dass er keinen Anspruch auf die Verfügbarkeit der Seite hat und insbesondere folgende Zeiten die
              Verfügbarkeit
              der Datenbank einschränken können:
              <ul>
                <li>Zeiten der Nichtverfügbarkeit, die auf von vom Anbieter nicht beeinflussbaren Störungen des Internet
                  oder auf sonstigen von dem Anbieter nicht zu vertretenden Umständen, insbesondere auf höherer Gewalt
                  beruhen;</li>
                <li>Zeiten der Nichtverfügbarkeit wegen geplanter Wartungsarbeiten an der Webseite.</li>
              </ul>
            </li>
          </ol>
        </li>

        <li>
          <b>Haftung</b>
          <ol>
            <li>Der Anbieter haftet, gleich aus welchem Rechtsgrund, im Rahmen der gesetzlichen Bestimmungen nur nach
              Maßgabe der folgenden Ziffern 11.2 bis 11.4. </li>
            <li>Der Anbieter haftet unbeschränkt für Schäden aus der Verletzung des Lebens, des Körpers oder der
              Gesundheit
              sowie für Schäden, die auf Vorsatz oder grober Fahrlässigkeit von Anbieter oder eines seiner gesetzlichen
              Vertreter oder Erfüllungsgehilfen beruhen, sowie für Schäden wegen der Nichteinhaltung einer von Anbieter
              gegebenen Garantie oder zugesicherten Eigenschaft oder wegen arglistig verschwiegener Mängel.</li>
            <li>Anbieter haftet unter Begrenzung auf Ersatz des vertragstypischen vorhersehbaren Schadens für solche
              Schäden, die auf einer leicht fahrlässigen Verletzung wesentlicher Vertragspflichten durch ihn oder einen
              seiner gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen. Vertragswesentliche Pflichten sind Pflichten,
              deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglichen und auf deren
              Einhaltung der Vertragspartner regelmäßig vertrauen darf.</li>
            <li>Jegliche Haftung für Inhalte Dritter, unabhängig davon, in welchem Teil der Webseite diese veröffentlicht
              werden, bleibt ausgeschlossen.</li>
            <li>Eine Haftung nach Maßgabe des Produkthaftungsgesetzes bleibt unberührt. Dies gilt jedoch nicht für
              Produkte
              und/oder Dienstleistungen, die beispielsweise über die Funktionen der Webseite unter den Nutzern übertragen
              werden. Die Webseite stellt ausschließlich die Plattform zur Verfügung.</li>
          </ol>
        </li>

        <li>
          <b>Freistellung</b>
          Der Nutzer stellt den Anbieter von sämtlichen Ansprüchen frei, die andere Nutzer oder Dritte gegenüber dem
          Anbieter wegen Verletzung ihrer Rechte geltend machen. Dies umfasst insbesondere auch die Kosten der notwendigen
          Rechtsverteidigung von Anbieter einschließlich sämtlicher Gerichts- und Anwaltskosten in gesetzlicher Höhe.
          Vorstehendes gilt nicht, wenn die Rechtsverletzung von dem Nutzer nicht zu vertreten ist. Der Nutzer ist in
          jedem
          Fall verpflichtet, Anbieter im Falle einer Inanspruchnahme durch Dritte unverzüglich, wahrheitsgemäß und
          vollumfänglich zu informieren, um eine vollständige Prüfung der Ansprüche zu ermöglichen.
        </li>

        <li>
          <b>Datenschutz, Geheimhaltung</b>
          <ol>
            <li>Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der Anschrift, E-Mail-Adresse oder
              Telefonnummer des Nutzers, erfolgt stets im Einklang mit der Datenschutz-Grundverordnung und in
              Übereinstimmung mit den für den Anbieter geltenden landesspezifischen Datenschutzbestimmungen. Es wird auf
              die
              Datenschutzbestimmungen verwiesen.</li>
            <li>Alle personenbezogenen Daten werden vertraulich behandelt.</li>
            <li>Die Vertragsparteien verpflichten sich, die ihnen im Rahmen der Vertragsdurchführung bekannt werdenden
              Informationen aus dem Bereich der anderen Partei vertraulich zu behandeln.</li>
          </ol>
        </li>

        <li>
          <b>Allgemeine Bestimmungen</b>
          <ol>
            <li>Der Nutzer kann diese AGB jederzeit einsehen.</li>
            <li>Auf die vertraglichen Beziehungen zwischen dem Anbieter und dem Nutzer findet das Recht der Bundesrepublik
              Deutschland Anwendung. Von dieser Rechtswahl ausgenommen sind die zwingenden Verbraucherschutzvorschriften
              des
              Landes, in dem der Nutzer seinen gewöhnlichen Aufenthalt hat.</li>
            <li>Gerichtsstand für alle Streitigkeiten aus dem Vertragsverhältnis zwischen dem Nutzer und dem Anbieter ist
              der Sitz des Anbieters, sofern es sich bei dem Nutzer um einen Kaufmann, eine juristische Person des
              öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen handelt.</li>
            <li>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die der Nutzer
              unter dem Link zur Plattform der Europäischen Kommission (https://ec.europa.eu/consumers/odr) finden kann.
              Zur
              Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle ist der Anbieter nicht
              verpflichtet und nicht bereit.</li>
          </ol>
        </li>

      </ol>

      Stand: Januar 2020

    </div>
  </div>

  <app-footer></app-footer>

</zimmerer-route-ion-wrapper>
