import { NgModule } from "@angular/core";
import { ImageSliderBarComponent } from "./image-slider-bar.component";
import { SharedModule } from "src/app/shared.module";
import { CreateEntryButtonComponentModule } from "../create-entry-button/create-entry-button.module";

@NgModule({
    declarations: [
        ImageSliderBarComponent
    ],
    imports: [
        SharedModule,
        CreateEntryButtonComponentModule
    ],
    exports: [
        ImageSliderBarComponent,
        CreateEntryButtonComponentModule
    ]
})
export class ImageSliderBarComponentModule { }