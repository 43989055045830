import { Component, Input } from '@angular/core'
import { NavigationItem } from '@model/navigation-item/navigation-item'
import { DrawerService } from '@service/drawer.service'
import { UserService } from '@service/user/user.service'
import { ScrollService } from '@service/scroll.service'

@Component({
  selector: 'app-navigation-button',
  templateUrl: './navigation-button.component.html',
  styleUrls: ['./navigation-button.component.scss'],
})
export class NavigationButtonComponent {

  @Input() navigationItem: NavigationItem
  @Input() noText: boolean = false
  @Input() centerContent: boolean = false

  @Input() smallicon: boolean = false

  public isCurrentState: boolean = false

  constructor(
    public DrawerService: DrawerService,
    public userService: UserService,
    public ScrollService: ScrollService,
  ) { }

  public doAlways() {
    this.DrawerService.close()

    // hacky - timeout is always a white flag but it would be complex to be sure when the whole page is rendered
    // sure ngafterinit
    // but then it would have to be boilerplate in every route component and also only sometimes - there might be cases where we don't want to scroll 
    setTimeout(() => {
      //console.log("doalways scroll to top")
      this.ScrollService.scrollToTop()
    }, 300)
  }

  public openExternalCustom() {

    this.doAlways()

    // if it is a custom action -> execute it 
    if (typeof this.navigationItem.action != "undefined") {
      this.navigationItem.action()
      return
    }

    // if its a external url -> open it
    if (typeof this.navigationItem.externalTarget != "undefined") {
      window.open(this.navigationItem.externalTarget, '_blank')
      return
    }

  }
}
