import { Serialize } from 'aautil'
import { User } from '@model/user/user'

export class Action {
  public action: string /*"goto" | "gotobonus" | "gotocomments"*/
  public modelName: string /*"blog" | "job" | "advert" | "wiki"*/
  public id: number /*model id*/
}

export class UserNotification {
  @Serialize()
  id: number

  @Serialize({ to: false })
  public sender_frontend_user: User

  @Serialize()
  created_at: string

  @Serialize()
  headline: string

  @Serialize()
  description: string

  @Serialize()
  action: string

  @Serialize()
  icon: string

  @Serialize()
  type: string
}
