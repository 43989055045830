import { environment } from '@environments/environment'
import { Serialize } from 'aautil'

/*
  these are only read from the api
  and are also only used in css (no need for safestyle)
  so no real serialization / ComputedImage necessary
*/
export class Sliderimage{

  @Serialize()
  public id: number

  @Serialize()
  public page: string

  @Serialize()
  public headline: string

  @Serialize({
    from:{
      map: ( image: string ) => {
        return environment.backendUrl+'/storage/'+image
      },
    }
  })
  public image: string = null

  @Serialize()
  public link: string
}
