import { Injectable } from '@angular/core'

import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private toastrService: ToastrService
  ) { }

  public showSuccess(message: string): void {
    this.toastrService.success(message);
  }

  public showWarning(message: string): void {
    this.toastrService.warning(message, 'Warnung')
  }

  public showError(message: string): void {
    this.toastrService.error(message, 'Fehler')
  }
}
