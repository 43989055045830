import { Component, Input } from '@angular/core'
import { environment } from '@environments/environment'
import { CategoriesService } from '@service/categories.service'
import { StateService } from '@service/state.service'

@Component({
    selector: 'create-entry-button',
    templateUrl: './create-entry-button.component.html',
    styleUrls: ['./create-entry-button.component.scss']
})
export class CreateEntryButtonComponent {

    public environment = environment

    @Input()
    public hasPlusIcon: boolean = false

    public opened: boolean = false;

    constructor(
        public StateService: StateService,
        public CategoriesService: CategoriesService,
    ) {
        this.StateService.stateDataChanged.subscribe(() => {
            this.opened = false;
        });
    }

}
