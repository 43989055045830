import { InFormGroup, Serialize, SerializationService } from 'aautil';
import { User } from '@model/user/user';
import { AbstractControlOptions, FormGroup, Validators } from '@angular/forms';
import { Category } from '@model/category/category';
import { ComputedImage } from '@model/computed-image';
import { Bookmark } from '@model/bookmark';
import { Like } from '@model/like';
import { UrlPrettifierService } from '@service/urlPrettifier/url-prettifier.service';
import { OgraphData } from '@model/ograph-data';
import { Entity } from '@model/entities/entity';
import { OgraphEntity } from '@interface/ograph-entity';
import { Likeable } from '@interface/likeable';
import { EntityTypeIdentifier } from '@type/entity-type-identifier';
import { LikeableTypeIdentifier } from '@type/likeable-type-identifier';
import { NgxGalleryImage } from 'ngx-gallery-9';
import { ProfileTypeIdentifier } from '@enum/profile-type-identifier';
import { environment } from '@environments/environment';

export class Job extends Entity implements OgraphEntity, Likeable {

  public likeable = true
  public likeableTypeIdentifier: LikeableTypeIdentifier = "job"

  public entityTypeIdentifier: EntityTypeIdentifier = "job"

  @InFormGroup()
  @Serialize()
  public id: number

  @Serialize()
  public userId?: number = -1

  @Serialize({
    from: {
      map: (frontend_user, svc: SerializationService) => svc.deserialize(User, frontend_user),
      deps: [SerializationService],
    },
    to: false
  })
  public frontend_user: User

  @Serialize({ to: false, from: 'frontend_user_id' })
  public frontend_user_id: number = -1

  @InFormGroup([Validators.required])
  @Serialize()
  public profile_type = ProfileTypeIdentifier.private

  @InFormGroup([Validators.required])
  @Serialize({
    from: 'job_category',
    to: 'job_category',
  })
  public category: Category

  @InFormGroup([Validators.required, Validators.minLength(3), Validators.maxLength(254)])
  @Serialize()
  public title: string = ''

  /*
    THIS IS CURRENTLY BEING USED FOR PLZ / CITY
    This decision was bad from a software view.
    But as so often was made not by us for time / budget reasons.
  */
  @InFormGroup([Validators.required, Validators.minLength(3), Validators.maxLength(254)])
  @Serialize()
  public subtitle: string = ''

  @InFormGroup([Validators.required, Validators.minLength(3), Validators.maxLength(25000)])
  @Serialize()
  public teaser: string = ''

  @InFormGroup([Validators.required, Validators.minLength(3), Validators.maxLength(25000)])
  @Serialize()
  public content: string = ''

  @Serialize({
    from: {
      key: 'cached_images',
      map: (raw: any, svc: SerializationService, self: Job) => {

        // deserialize images
        return svc.deserializeMany(ComputedImage, raw)
          .then((images: ComputedImage[]) => {

            /*
              this should some day be separated in three arrays
              cached_images = all images in one array
              main_image = main image
              slide_images = all slide images
            */

            /*
              loop fusion
              + find main image
              + sort into ngxgallery
            */
            for (let c of images) {

              const imageDef = {
                small: c.fullUrl.cached,
                medium: c.fullUrl.cached,
                big: c.fullUrl.cached,
                description: self.title,
              }

              // find main image
              if (c.purpose_key == 'MAIN') {
                self.mainImage = c
              }

              // sort into ngx gallery
              if (c.purpose_key == "SLIDE") {
                if (c.image.purpose_key == "MAIN,SLIDE")
                  self.galleryImages.unshift(imageDef)
                else if (c.image.purpose_key == "SLIDE")
                  self.galleryImages.push(imageDef)
              }

            }

            return images
          })

      },
      deps: [SerializationService],
    },
    to: 'cached_images'

  })
  public images: ComputedImage[] = []

  @InFormGroup([Validators.required])
  public mainImage: ComputedImage

  public galleryImages: NgxGalleryImage[] = []

  @Serialize()
  public count_comments: number

  @Serialize()
  public count_likes: number

  @Serialize()
  public bookmarks: Bookmark[]

  @Serialize()
  public likes: Like[]

  @Serialize()
  public is_edit: boolean

  public getOpgraphData() {

    let data = new OgraphData()

    data.url = environment.backendUrl + "/job/detail/" + this.id
    if (this.title)
      data.url += "/" + UrlPrettifierService.prettyString(this.title)

    return data
  }

}
