<zimmerer-route-ion-wrapper class="ion-page">

  <div class="threecol"
    fxLayout="column"
    fxLayoutAlign.gt-md="center center">
    <div class="main-col noSlider"
      fxLayout="column"
      fxLayoutGap="20px">

      <p>
        <b>Zimmerer-Treffpunkt GmbH</b><br>
        Ziegelweg 5<br>
        94405 Landau/Isar
      </p>

      <p>
        Tel. <a href="tel:+49099519574700">+49 (0) 9951 9574700</a><br>
        Mail: <a href="mailto:info@zimmerer-treff.com">info@zimmerer-treff.com</a>
      </p>

      <p>
        Sitz der Gesellschaft ist Landau<br>
        Amtsgericht Landshut<br>
        HRB-Nr. 11536<br>
        Umsatzsteuer-ID: DE322914671<br>
        Geschäftsführer: Andreas Winkler
      </p>

      <p>
        Bildquellen – Urheberverzeichnis der genutzten Bilder:<br>
        Zimmerer Fuchs<br>
        Hannes Höchsmann<br>
        Przemek
      </p>

    </div>
  </div>

  <app-footer></app-footer>

</zimmerer-route-ion-wrapper>
